import Playback from '../model/playback/playback';
import PlayerModel from '../model/player-model';
import Auth from '../modules/auth/auth';
import playerStorage from '../modules/storage/player-storage';
import DRM from '../utils/drm';
import axios from 'axios';
import * as err from '../utils/err';

export default class PlayerController
{
    #root;
    #eventBus;
    #playerState;
    #logger;
    #auth;
    #viewersWsClient;

    options;
    video;
    playback;
    playerModel;
    #isTextTracksInitialized     = false;
    #isDefaultQualityInitialized = false;

    constructor(root, options, eventBus, playerState, logger, viewersWsClient)
    {
        this.#root            = document.getElementById(root);
        this.options          = options;
        this.#eventBus        = eventBus;
        this.#playerState     = playerState;
        this.#logger          = logger;
        this.#viewersWsClient = viewersWsClient;

        this.#eventBus.on(`playback.ended`, () => {
            if (!this.#playerState.get(`adPlaying`)) {
                this.#viewersWsClient.stopWatching();
            }
        });
    }

    async init()
    {
        let drm = new DRM();

        if (drm.needToInit(this.options)) {
            await drm.init();
            this.#logger.info('[Playback] DRM PlayReady supported: ' + drm.isPlayReadySupported());
            this.#logger.info('[Playback] DRM Widevine supported: ' + drm.isWidevineSupported());
            this.#logger.info('[Playback] DRM FairPlay supported: ' + drm.isFairPlaySupported());

            if (!drm.isPlayReadySupported() && !drm.isWidevineSupported() && !drm.isFairPlaySupported()) {
                this.#playerState.add(`errors`, {type: err.list.drmNotSupported});
                return;
            }
        }

        this.#auth = new Auth(this.options.auth);
        if (this.#auth.isEnabled()) {
            this.#logger.info('[Playback] Auth enabled');
            this.#playerState.set(`authEnabled`, true);
            this.#playerState.set(`authAuthenticated`, false);
            this.#playerState.set(`authFestivalName`, this.options.auth.festivalName);

            let response = await this.#auth.checkIsAuthenticated();
            if (response.status === 200) {
                this.#playerState.set(`authAuthenticated`, true);
                this.#playerState.set(`authEmail`, response.data.email);
            }

            this.#logger.info('[Playback] Auth User authenticated: ' + (this.#playerState.get(`authAuthenticated`) ? 'yes' : 'no'));
        }

        this.video       = this.#root.querySelector(`.playernow-video-el`);
        this.playback    = new Playback(this.video, this.options, this.#eventBus, this.#playerState, this.#logger, drm);
        this.playerModel = new PlayerModel(this.#root, this.video, this.#eventBus, this.#playerState, this.#logger);

        // default values
        let volume = playerStorage.get(`volume`) || 1.0;
        this.setVolume(volume);

        if (this.playbackInfo.protocol) {
            if (playerStorage.get(`src`) === this.playbackInfo.protocol.options.url) {
                let currentTime = playerStorage.get(`currentTime`) || 0;
                this.seek(currentTime);
            }

            playerStorage.set(`src`, this.playbackInfo.protocol.options.url);
        }

        this.setMute(false);

        this.#playerState.set(`textTracks`, this.options.textTracks);

        let textTracks = this.options.textTracks.filter(track => track.id !== -1);
        if (textTracks.length > 0) {

            let removeTextTrack = function(track) {
                let textTracks = this.#playerState.get(`textTracks`) ?? [];
                textTracks     = textTracks.filter(textTrack => {
                    return track.id !== textTrack.id;
                });
                this.#playerState.set(`textTracks`, textTracks);
            }.bind(this);

            this.setTextTrackId(-1);
            if (textTracks.length === 1 && this.options.textTracks[0]) {
                this.setTextTrackId(textTracks[0].id);
            }

            textTracks.forEach(textTrack => {

                axios.request({
                    url:            textTrack.src,
                    method:         'head',
                    timeout:        5000,
                    validateStatus: function(status) {
                        return status >= 0;
                    },
                }).then(response => {
                    if (response.status === 0 || response.status >= 400) {
                        this.#playerState.add(`warnings`, {type: err.list.failedLoadSubtitles, httpStatus: response.status, url: textTrack.src});
                        removeTextTrack(textTrack);
                    }
                }).catch(error => {
                    this.#playerState.add(`warnings`, {type: err.list.failedLoadSubtitles, httpStatus: 0, url: textTrack.src, error: error});
                    removeTextTrack(textTrack);
                });
            });
        }

        if (this.options.poster) {
            axios.request({
                url:            this.options.poster,
                method:         'head',
                timeout:        5000,
                validateStatus: function(status) {
                    return status >= 0;
                },
            }).then(response => {
                if (response.status >= 400) {
                    this.#playerState.add(`warnings`, {type: err.list.failedLoadPoster, httpStatus: response.status, url: this.options.poster});
                }
            }).catch(() => {
                this.#logger.info('[Playback] Poster request error');
            });
        }

        return Promise.resolve();
    }

    get playbackInfo()
    {
        return this.playback && this.playback.info;
    }

    play()
    {
        let volume            = this.#playerState.get(`volume`);
        let activeTextTrackId = this.#playerState.get(`activeTextTrackId`);

        this.playback.play().then(result => {
            this.#playerState.set(`poster`, null);
            this.#viewersWsClient.startWatching();

            if (result.isAdv) {
                return;
            }

            if (!this.#isTextTracksInitialized) {
                let textTracks = this.options.textTracks.filter(track => track.id !== -1);

                if (textTracks.length === 1 && textTracks[0]) {
                    this.setTextTrackId(this.options.textTracks[0].id);
                }

                this.#isTextTracksInitialized = true;
            }

            if (!this.#isDefaultQualityInitialized && this.options.initMaxQuality) {
                let maxQuality = null;
                this.#playerState.get(`videoTracks`).map(quality => {
                    if (maxQuality === null) {
                        maxQuality = quality;
                        return;
                    }

                    if (parseInt(quality.title) > parseInt(maxQuality.title)) {
                        maxQuality = quality;
                    }
                });

                if (maxQuality !== null) {
                    this.setVideoTrackId(maxQuality.id);
                }

                this.#isDefaultQualityInitialized = true;
            }

            this.setVolume(volume);
            this.setTextTrackId(activeTextTrackId);
        });
    }

    pause()
    {
        this.playback.pause();
        this.#viewersWsClient.stopWatching();
    }

    setFullscreen(enter)
    {
        this.playerModel.setFullscreen(enter);
    }

    setVolume(value)
    {
        playerStorage.set(`volume`, value);
        this.#playerState.set(`volume`, value);
        this.playback.setVolume(value);
    }

    setMute(value)
    {
        this.playerModel.setMute(value);
        this.playback.setMute(value);
    }

    async setVideoTrackId(trackId)
    {
        let track = this.#playerState.get(`videoTracks`).find(track => track.id === trackId);

        if (!track) {
            this.#logger.error('Invalid video track id: ' + trackId);
            return true;
        }

        let activeTextTrackId = this.#playerState.props().activeTextTrackId;
        this.setTextTrackId(-1);

        await this.playback.setVideoTrack(track);
        this.playerModel.setVideoTrack(track);

        this.setTextTrackId(activeTextTrackId);//set subs on new video.src

        return true;
    }

    async setAudioTrackId(trackId)
    {
        // auto track
        if (trackId === -1) {
            return true;
        }

        let track = this.#playerState.get(`audioTracks`).find(track => track.id === trackId);

        if (!track) {
            this.#logger.error('Invalid audio track id: ' + trackId);
            return true;
        }

        let activeTextTrackId = this.#playerState.props().activeTextTrackId;
        this.setTextTrackId(-1);

        await this.playback.setAudioTrack(track);
        this.playerModel.setAudioTrack(track);

        this.setTextTrackId(activeTextTrackId);//set subs on new video.src

        return true;
    }

    setTextTrackId(trackId)
    {
        let track = this.#playerState.get(`textTracks`).find(track => track.id === trackId);

        if (!track) {
            this.#logger.error('Invalid text track id: ' + trackId);
            return true;
        }

        this.playback.setTextTrack(track);
        this.playerModel.setTextTrack(track);
    }

    seek(pos)
    {
        this.playback.seek(pos);
    }

    getBufferedTo()
    {
        return this.playerModel.getBuffered().to;
    }

    width()
    {
        return getComputedStyle(this.video).width;
    }

    height()
    {
        return getComputedStyle(this.video).height;
    }

    async login(email, password)
    {
        return this.#auth.login(email, password).then(response => {
            let success = response.status === 200;
            let errors  = [];

            if (response.status === 200) {
                this.#playerState.set(`authAuthenticated`, true);
                this.#playerState.set(`authEmail`, response.data.email);
            } else {
                this.#playerState.set(`authAuthenticated`, false);
                if (response.data && response.data.params) {
                    response.data.params.map(error => {
                        errors.push(error.message);
                    });
                }
            }

            this.playback.initializeIfPossible();

            return {success, errors};
        }).catch(error => {
            this.#playerState.set(`authAuthenticated`, false);
            this.#logger.error('Auth login error: ' + error);

            return {success: false, errors: ['Internal server error']};
        });
    }

    async logout()
    {
        return this.#auth.logout().then(response => {
            this.#playerState.set('authEmail', '');
            this.#playerState.set('authAuthenticated', false);

            let success = response.status === 200;
            let errors  = [];

            if (response.status !== 200 && response.data && response.data.params) {
                response.data.params.map(error => {
                    errors.push(error.message);
                });
            }

            return {success, errors};

        }).catch(error => {
            this.#logger.error('Auth logout error: ' + error);
            return {success: false, errors: ['Internal server error']};
        });
    }

    async registration(email, password)
    {
        return this.#auth.registration(email, password).then(response => {
            let success = response.status === 200;
            let errors  = [];

            if (response.status !== 200 && response.data && response.data.params) {
                response.data.params.map(error => {
                    errors.push(error.message);
                });
            }

            return {success, errors};
        }).catch(error => {
            this.#logger.error('Auth registration error: ' + error);
            return {success: false, errors: ['Internal server error']};
        });
    }

    async passwordRecovery(email)
    {
        return this.#auth.passwordRecovery(email).then(response => {
            let success = response.status === 200;
            let errors  = [];

            if (response.status !== 200 && response.data && response.data.params) {
                response.data.params.map(error => {
                    errors.push(error.message);
                });
            }

            return {success, errors};
        }).catch(error => {
            this.#logger.error('Auth passwordRecovery error: ' + error);
            return {success: false, errors: ['Internal server error']};
        });
    }

    async destroy()
    {
        this.#logger.log(`[PlayerController] destroy`);
        this.#viewersWsClient.destroy();
        await this.playback.destroy();
        this.playerModel.destroy();
    }
}
