import React from 'react';

class PlayButton extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {player: props.playerState.props()};
        props.playerState.on('change', () => {
            this.setState({player: props.playerState.props()});
        });

        this.handleClick = this.handleClick.bind(this);
    }

    isPlaying()
    {
        return this.state.player.playing || (!this.state.player.playing && this.state.player.adLoading);
    }

    handleClick()
    {
        if (this.isPlaying()) {
            this.props.controller.pause();
        } else {
            this.props.controller.play();
        }
    }

    onFocus(event)
    {
        event.preventDefault();
        this.props.playerState.set(`focusElement`, `PlayButton`);
    }

    onKeyDown(e)
    {
        if (!e.keyCode) {
            return;
        }

        if (this.props.playerState.get(`focusElement`) !== 'PlayButton') {
            return;
        }

        switch (e.keyCode) {
            case 38:
            case 39:
            case 37:
            case 40:
                e.preventDefault();
                break;
            case 13:
                e.preventDefault();
                this.handleClick();
                break;
        }
    }

    render()
    {
        if (!this.props.controller.options.forcePlayButton && (this.state.player.adPlaying && this.state.player.snapshotIsStream)) {
            return null;
        }

        let btnClass = 'tab-button playernow-play-button';
        if (this.isPlaying()) {
            btnClass = 'tab-button playernow-pause-button';
        }

        return (
            <div className="playernow-action-block playernow-play-button-wrap" onKeyDown={e => this.onKeyDown(e)}>
                <span
                    tabIndex="0"
                    rel="play"
                    className={btnClass}
                    onClick={this.handleClick}
                    onFocus={e => this.onFocus(e)}
                />
            </div>
        );
    }
}

export default PlayButton;
