import Protocol from './protocol';

export default class Mpeg extends Protocol
{
    static type = `Mpeg`;

    constructor(video, options, eventBus, logger)
    {
        super(Mpeg.type, video, options, eventBus, logger);

        this.eventBus.emit(`playback.videoTracks`, {videoTracks: []});
    }

    async initialize()
    {
        this.logger.debug(`[Playback][MP4] Initializing...`);
        this.video.src = this.options.url;
        this.#bindEvents();

        this.initialized = true;

        this.logger.debug(`[Playback][MP4] Initialized`);

        return true;
    }

    #bindEvents()
    {
        this.onLoadedMetadata = this.onLoadedMetadata.bind(this);
        this.video.addEventListener(`loadedmetadata`, this.onLoadedMetadata);
    }

    #unbindEvents()
    {
        this.video.removeEventListener(`loadedmetadata`, this.onLoadedMetadata);
    }

    onLoadedMetadata()
    {
        this.eventBus.emit(`playback.videoTracks`, {videoTracks: [{title: `auto`, name: `auto`}]});
    }

    setVideoTrack()
    {
    }

    async destroy()
    {
        this.video.src = ``;
        this.#unbindEvents();

        return true;
    }
}
