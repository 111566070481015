import Creative from './Creative';
import * as xml from '../utils/xml';

function parseImpressions(impressions)
{
    if (impressions) {
        impressions = Array.isArray(impressions) ? impressions : [impressions];
        return impressions.filter(i => xml.value(i))
            .map(i => xml.value(i));
    }
    return [];
}

function parseExtensions(extensions)
{
    if (extensions && extensions[`extension`]) {
        return extensions[`extension`];
    }
    return [];
}

function trueIfNotDefined(val)
{
    return val === `undefined` || val;
}

export default class Wrapper
{
    constructor(obj)
    {
        //Required elements
        this.adSystem     = xml.value(obj.adSystem);
        this.impressions  = parseImpressions(obj.impression);
        this.extensions   = parseExtensions(obj.extensions);
        this.VASTAdTagURI = xml.value(obj.vASTAdTagURI);

        //Optional elements
        this.creatives = Creative.parseCreatives(obj.creatives);
        this.error     = xml.value(obj.error);

        //Optional attributes
        this.followAdditionalWrappers = trueIfNotDefined(obj.followAdditionalWrappers);
        this.allowMultipleAds         = trueIfNotDefined(obj.allowMultipleAds);
        this.fallbackOnNoAd           = trueIfNotDefined(obj.fallbackOnNoAd);
    }
}