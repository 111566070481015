import * as xml from '../utils/xml';

const attributesList = [
    //Required attributes
    `delivery`,
    `type`,
    //Optional attributes
    `codec`,
    `id`,
    `bitrate`,
    `minBitrate`,
    `maxBitrate`,
    `scalable`,
    `maintainAspectRatio`,
    `apiFramework`,
];

export default class MediaFile
{
    constructor(obj)
    {
        this.src = xml.value(obj);

        this.width  = parseInt(obj.width);
        this.height = parseInt(obj.height);

        attributesList.forEach(attr => this[attr] = obj[attr]);
    }
}