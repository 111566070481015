import React from 'react';
import * as utils from '../../../utils/auth';

class ResetPasswordModal extends React.Component
{
    constructor(props)
    {
        super(props);

        props.playerState.on('change', () => {
            this.setState({player: props.playerState.props()});
        });

        this.state = {
            player:   props.playerState.props(),
            finished: false,
            disabled: false,
            email:    '',
            errors:   [],
        };
    }

    handleFormSubmit()
    {
        const email = this.state.email;
        let errors  = [];

        if (!utils.isValidEmailFormat(email)) {
            errors.push('Неверный формат электронной почты');
        }

        if (errors.length > 0) {
            this.setState({errors: errors});
            return;
        }

        this.setState({disabled: true});
        this.props.controller.passwordRecovery(email).then(({success, errors}) => {
            if (success) {
                this.setState({finished: true});
                this.setState({email: ''});
            }

            let errorMessages = [];
            errors.map(error => {
                if (error === 'Invalid email format') {
                    errorMessages.push('Неверный формат электронной почты');
                } else {
                    errorMessages.push('Произошла ошибка. Попробуйте восстановить пароль позже');
                }
            });

            this.setState({disabled: false, errors: utils.filterUnique(errorMessages)});
        });
    }

    handleEmailChange(event)
    {
        this.setState({email: event.target.value, errors: []});
    }

    renderResetPasswordModal()
    {
        return (
            <div className="modal fade playernow-modal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-card card">
                            <div className="card-header">
                                <h4 className="card-header-title">{this.state.player.authFestivalName}</h4>
                                <button type="button" className="close" onClick={this.props.onCloseBtnClick}>×</button>
                            </div>
                            <div className="card-body">
                                <div className="row d-flex justify-content-center text-center">
                                    <div className="col-12 col-md-8 col-xl-7 my-3">
                                        <h1>Восстановить пароль</h1>
                                        <p className="text-muted text-center mb-5">
                                            Введите ваш электронный адрес, <br/>если вы зарегистрированы.
                                        </p>
                                        <div className="form-group">
                                            <label>Ваша почта</label>
                                            <input type="email" className="form-control" placeholder="name@address.com" value={this.state.email}
                                                   onChange={e => this.handleEmailChange(e)} disabled={this.state.disabled}/>
                                        </div>
                                        <button className="btn btn-lg btn-block btn-primary mb-3" onClick={e => this.handleFormSubmit(e)}
                                                disabled={this.state.disabled}>
                                            Восстановить
                                        </button>
                                        <div className="text-error">
                                            {
                                                this.state.errors.map((error, index) => (
                                                        <div key={index}>{error}</div>
                                                    ),
                                                )
                                            }
                                        </div>
                                        <div className="text-center">
                                            <p className="text-muted text-center">
                                                Нет аккаунта? <a href="#" onClick={this.props.onRegistrationBtnClick}>Зарегистрируйтесь</a>.
                                            </p>
                                            <p className="text-muted text-center">
                                                Вернуться в форму <a href="#" onClick={this.props.onLoginBtnClick}>авторизации</a>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderResetPasswordFinishedModal()
    {
        return (
            <div className="modal fade playernow-modal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-card card">
                            <div className="card-header">
                                <h4 className="card-header-title">{this.state.player.authFestivalName}</h4>
                                <button type="button" className="close" onClick={this.props.onCloseBtnClick}>×</button>
                            </div>
                            <div className="card-body">
                                <div className="row d-flex justify-content-center text-center">
                                    <div className="col-12 col-md-8 col-xl-7 my-3">
                                        <div>
                                            Вам на почту выслано письмо для восстановления пароля.
                                        </div>
                                        <div className="text-center">
                                            <p className="text-muted text-center">
                                                Вернуться в форму <a href="#" onClick={this.props.onLoginBtnClick}>авторизации</a>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render()
    {
        if (!this.state.player.authEnabled) {
            return '';
        }

        if (!this.props.visible) {
            return '';
        }

        if (this.state.finished) {
            return this.renderResetPasswordFinishedModal();
        } else {
            return this.renderResetPasswordModal();
        }
    }
}

export default ResetPasswordModal;
