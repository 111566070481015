const playerstatStatic = `//playerstat.ru/api/`;
const protocol         = window.location.protocol || ``;

class playerStat
{
    constructor(options = {}, controller, logger)
    {
        this.default = {
            tickTimeout:      60000,
            lowBufferTimeout: 5000,
            lowBufferAt:      0.5,
        };

        this.logger = logger;

        this.options    = Object.assign(this.default, options);
        this.controller = controller;

        // this.tick();
        this.checkLowBuffer();
    }

    checkLowBuffer()
    {
        this.bufferTimer = setInterval(() => {

            let VTS = Math.floor(Date.now() / 1000);
            if (this.controller.getBufferedTo() - this.controller.video.currentTime <= this.options.lowBufferAt && !this.controller.video.seeking
                && this.controller.video.currentTime > 0 && !this.controller.video.ended) {
                this.logger.log(`[Statistics] Low buffer! buffered:`, this.controller.getBufferedTo(), `currentTime:`, this.controller.video.currentTime);

                let lowBufferUrl = `${protocol}${playerstatStatic}${VTS}/lowBuffer/`;
                // new Image().src = lowBufferUrl;
            }

        }, this.options.lowBufferTimeout);
    }

    tick()
    {
        this.tickTimer = setInterval(() => {

            let VTS     = Math.floor(Date.now() / 1000);
            let tickUrl = `${protocol}${playerstatStatic}${VTS}/heartbeat/`;

            new Image().src = tickUrl;
        }, this.options.tickTimeout);
    }

    destroy()
    {
        clearInterval(this.tickTimer);
        clearInterval(this.bufferTimer);
    }
}

export default playerStat;