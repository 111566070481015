function decapitalize(s)
{
    return s.charAt(0).toLowerCase() + s.slice(1);
}

function parseNode(xmlNode, result, arrayTags)
{
    if (xmlNode.nodeName === `#text` || xmlNode.nodeName === `#cdata-section`) {
        if (xmlNode.nodeValue.trim() !== ``) {
            result.text = xmlNode.nodeValue;
        }
        return;
    }

    let jsonNode = {};
    let nodeName = xmlNode.nodeName === `VAST` ? `VAST` : decapitalize(xmlNode.nodeName);

    let existing = result[nodeName];
    if (existing) {
        if (!Array.isArray(existing)) {
            result[nodeName] = [existing, jsonNode];
        } else {
            result[nodeName].push(jsonNode);
        }
    } else if (arrayTags.indexOf(xmlNode.nodeName) !== -1) {
        result[nodeName] = [jsonNode];
    } else {
        result[nodeName] = jsonNode;
    }

    let length;
    if (xmlNode.attributes) {
        length = xmlNode.attributes.length;
        for (let i = 0; i < length; i++) {
            let attribute                = xmlNode.attributes[i];
            jsonNode[attribute.nodeName] = attribute.nodeValue;
        }
    }

    length = xmlNode.childNodes.length;
    for (let i = 0; i < length; i++) {
        parseNode(xmlNode.childNodes[i], jsonNode, arrayTags);
    }
}

function isValidDocument(dom)
{
    return !dom.querySelector(`parsererror`);
}

export const parseXml = (xmlString, arrayTags = []) => {
    let dom = (new DOMParser()).parseFromString(xmlString, `text/xml`);
    if (!isValidDocument(dom)) {
        return null;
    }

    let result = {};
    if (dom.childNodes.length) {
        parseNode(dom.childNodes[0], result, arrayTags);
    }

    return result;
};

export const value = (xmlObj) => {
    return xmlObj ? xmlObj.text : null;
};

export const attr = (xmlObj, attr) => {
    return xmlObj ? xmlObj[attr] : null;
};

export const decode = (str) => {
    if (typeof str === `string`) {
        return null;
    }

    return str.replace(/&apos;/g, `'`)
        .replace(/&quot;/g, `"`)
        .replace(/&gt;/g, `>`)
        .replace(/&lt;/g, `<`)
        .replace(/&amp;/g, `&`);
};