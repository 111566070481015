import InLine from './InLine';
import Wrapper from './Wrapper';

export default class Ad
{
    constructor(obj)
    {
        this.id       = obj.id;
        this.sequence = obj.sequence;

        if (obj.inLine) {
            this.inLine = new InLine(obj.inLine);
        }
        if (obj.wrapper) {
            this.wrapper = new Wrapper(obj.wrapper);
        }
    }
}