import * as xml from '../utils/xml';

function parseClickTrackings(trackingData)
{
    if (trackingData) {
        trackingData = Array.isArray(trackingData) ? trackingData : [trackingData];
        return trackingData.map(t => xml.value(t));
    }
    return [];
}

export default class VideoClicks
{
    constructor(obj)
    {
        this.clickThrough   = xml.value(obj.clickThrough);
        this.clickTrackings = parseClickTrackings(obj.clickTracking);
        this.customClicks   = parseClickTrackings(obj.customClick);
    }
}