const list = {
    drmNotSupported:        'drmNotSupported',
    failedLoadSubtitles:    'failedLoadSubtitles',
    failedLoadPoster:       'failedLoadPoster',
    failedLoadPlaylist:     'failedLoadPlaylist',
    failedLoadFairplayCert: 'failedLoadFairplayCert',
    emptySource:            'emptySource',
    protocolError:          'protocolError',
    hlsLibraryError:        'hlsLibraryError',
    dashLibraryError:       'dashLibraryError',
    videoElementError:      'videoElementError',
};

export {list};