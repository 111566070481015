import axios from 'axios';
import * as xml from '../utils/xml';
import Ad from './Ad';
import VastResponse from './VastResponse';
import VastError from './VastError';

const supportedVersions = [`2.0`, `3.0`];
const defaultOptions    = {
    wrapperLimit: 5,
    vastTimeout:  3000,
};

export default class Vast
{
    constructor(vastUrl, options = {})
    {
        this.vastUrl  = vastUrl;
        this.options  = Object.assign(defaultOptions, options);
        this.response = new VastResponse(this.vastUrl);
    }

    getResponse()
    {
        return this.loadVast(this.vastUrl)
            .then(this.parseVast.bind(this));
    }

    loadVast(url)
    {
        return axios.get(url, {timeout: this.options.vastTimeout, 'Content-type': `text/xml`})
            .then(res => res.data);
    }

    parseVast(xmlString)
    {
        let xmlObj = xml.parseXml(xmlString);
        if (!xmlObj) {
            throw new VastError(`XML parsing error`, 100, this.response);
        }

        let vastObj = xmlObj.VAST;
        this.check(vastObj);

        let prevAdInChain = this.response.ads[this.response.ads.length - 1];
        let ads           = vastObj.ad;
        // ad Pod
        if (Array.isArray(ads)) {
            let adPods = vastObj.ad.filter(ad => ad.sequence).sort((a, b) => a.sequence - b.sequence);
            if (!prevAdInChain || prevAdInChain.wrapper.allowMultipleAds) {
                return this.createAdPodsVastResponse(adPods);
            }

            let standaloneAds = vastObj.ad.filter(ad => !ad.sequence);
            if (standaloneAds.length === 0) {
                throw new VastError(`No VAST response`, 303, this.response);
            }
            ads = standaloneAds[0];
        }

        // single ad
        let ad = new Ad(ads);
        this.response.addAd(ad);
        if (ad.wrapper) {
            if (this.hasCycle(this.response)) {
                throw new VastError(`Wrappers cycle detected`, 900, this.response);
            }

            if (this.response.ads.length > this.options.wrapperLimit) {
                throw new VastError(`Too much Wrapper responses: ${this.response.ads.length}`, 302, this.response);
            }
            if (prevAdInChain && !prevAdInChain.wrapper.followAdditionalWrappers) {
                throw new VastError(`Reject wrapper reason: followAdditionalWrappers`, 303, this.response);
            }

            return this.loadVast(ad.wrapper.VASTAdTagURI)
                .catch(() => {
                    throw new VastError(`Request Error`, 301, this.response);
                })
                .then(res => this.parseVast(res));
        }
        return this.response;
    }

    createAdPodsVastResponse(ads)
    {
        return ads.reduce((res, ad) => {
            let resCopy = new VastResponse();
            this.response.ads.forEach(ad => resCopy.addAd(ad));
            resCopy.addAd(new Ad(ad));

            res.push(resCopy);
            return res;
        }, []);
    }

    check(vastObj)
    {
        if (!this.isValid(vastObj)) {
            throw new VastError(`VAST is not valid`, 101, this.response);
        }
        if (!this.isSupportedVersion(vastObj)) {
            throw new VastError(`VAST version is not supported`, 102, this.response);
        }
        if (!vastObj.ad) {
            throw new VastError(`No VAST response`, 303, this.response);
        }
    }

    isValid()
    {
        return true;
    }

    isSupportedVersion(vast)
    {
        return supportedVersions.indexOf(vast.version) > -1;
    }

    hasCycle(response)
    {
        let urls = new Set([response.vastUrl]);
        response.ads.forEach(ad => {
            urls.add(ad.wrapper.VASTAdTagURI);
        });
        return urls.size <= response.ads.size;
    }
}