import Factory from './factory';
import axios from 'axios';
import Bowser from 'bowser';
import * as err from '../../utils/err';
import Mpeg from './protocol/mpeg';
import HlsShaka from './protocol/hls/shaka';

const events = [
    `abort`,
    `canplay`,
    `canplaythrough`,
    `durationchange`,
    `emptied`,
    `encrypted`,
    `ended`,
    `error`,
    `loadeddata`,
    `loadedmetadata`,
    `loadstart`,
    `pause`,
    `play`,
    `playing`,
    `seeking`,
    `seeked`,
    `stalled`,
    `suspend`,
    `progress`,
    `timeupdate`,
    `volumechange`,
    `waiting`,
    `waitingforkey`,
    `webkitneedkey`,
    `webkitkeymessage`,
    `webkitkeyadded`,
    `webkitkeyerror`,
    `webkitbeginfullscreen`,
    `webkitendfullscreen`,
];

export default class Playback
{
    #video;
    #options;
    #eventBus;
    #playerState;
    #videoCurrentTime = 0;
    #timers           = [];
    #encrypted        = false;
    #muted;
    #logger;
    #protocol;
    #drm;
    #playedAdPreRoll  = false;

    constructor(video, options, eventBus, playerState, logger, drm)
    {
        this.#video           = video;
        this.#options         = options;
        this.#eventBus        = eventBus;
        this.#playerState     = playerState;
        this.handleVideoEvent = this.handleVideoEvent.bind(this);
        this.#muted           = this.#video.muted;
        this.#logger          = logger;
        this.#drm             = drm;

        this.bindEvents();
        this.createProtocol();
    }

    bindEvents()
    {
        events.forEach(event => this.#video.addEventListener(event, this.handleVideoEvent));

        let prevProgressValue = null;
        this.#timers.push(setInterval(() => {
            if (prevProgressValue !== this.#video.currentTime) {
                prevProgressValue = this.#video.currentTime;
                // timeupdate analog
                if (prevProgressValue !== this.#video.duration) {
                    this.#eventBus.emit(`playback.currentTimeChange`, {});
                }
            }
        }, 50));
    }

    unbindEvents()
    {
        events.forEach(event => this.#video.removeEventListener(event, this.handleVideoEvent));
        this.#timers.forEach(timer => clearInterval(timer));
        this.#timers.length = 0;
    }

    handleVideoEvent(event)
    {
        switch (event.type) {
            case `error`:
                let payload = {type: err.list.videoElementError};
                if (this.#video.error) {
                    payload.code = this.#video.error.code;
                }
                this.#eventBus.emit(`playback.error`, payload);
                break;
            case `encrypted`:
                this.#encrypted = true;
                break;
            case `webkitbeginfullscreen`:
                this.#eventBus.emit(`playback.webkitbeginfullscreen`);
                this.#eventBus.emit(`playback.beginfullscreen`);
                break;
            case `webkitendfullscreen`:
                this.#eventBus.emit(`playback.webkitendfullscreen`);
                this.#eventBus.emit(`playback.endfullscreen`);
                break;
            case `volumechange`:
                if (this.#muted !== this.#video.muted) {
                    this.#muted = this.#video.muted;

                    if (this.#muted) {
                        this.#eventBus.emit(`playback.muted`);
                        this.#logger.log(`[Playback] Event muted`);
                    } else {
                        this.#eventBus.emit(`playback.unmuted`);
                        this.#logger.log(`[Playback] Event unmuted`);
                    }
                    return;
                }
                break;
            case `timeupdate`:
                if (!this.#video.seeking) {
                    this.#videoCurrentTime = this.#video.currentTime;
                }
                break;
            case `seeking`:
                if (!this.#playerState.get(`adPlaying`)) {
                    return;
                }

                if (Math.abs(this.#video.currentTime - this.#videoCurrentTime) > 0.01) {
                    this.#video.currentTime = this.#videoCurrentTime;
                }
                break;
            case `ended`:
                this.#videoCurrentTime = 0;
                break;
            case `loadedmetadata`:
            case `durationchange`:
                let duration = parseInt(this.#video.duration) < 86400 ? this.#video.duration : Infinity;
                this.#eventBus.emit(`playback.metadata`, {duration});
                break;
        }

        if (event.type) {
            this.#logger.log(`[Playback] Event`, event.type);
        }
        this.#eventBus.emit(`playback.${event.type}`, event);
    }

    async createProtocol()
    {
        if (!this.hasSource(this.#options)) {
            this.#playerState.add(`errors`, {type: err.list.emptySource});

            return true;
        }

        let protocolData = Factory.createProtocol(this.#video, this.#options, this.#eventBus, this.#logger, this.#drm);

        if (!protocolData.protocol) {
            this.#playerState.add(`errors`, {type: err.list.protocolError});

            return true;
        }

        this.#protocol = protocolData.protocol;

        if (!protocolData.url) {
            this.#playerState.add(`errors`, {type: err.list.emptySource});

            return true;
        }

        this.#logger.info('[Playback] Play url: ' + protocolData.url);

        if (this.#isAdv()) {
            this.#logger.info('[Playback] Adv');
            return true;
        }

        if (this.#options.skipInitChecks) {
            this.#logger.info('[Playback] Skip init checks');
            return true;
        }

        this.#logger.info('[Playback] Check url: ' + protocolData.url);

        if (this.#protocol.type === Mpeg.type) {
            this.initializeIfPossible();
            return true;
        }

        axios.request({
            url:            protocolData.url,
            method:         'get',
            timeout:        5000,
            validateStatus: function(status) {
                return status >= 0;
            },
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    if (this.#protocol.type === HlsShaka.type && !HlsShaka.isMasterPlaylist(response.data)) {
                        this.#logger.info('[Playback] HlsShaka buildMasterPlaylistWithMedia', protocolData.url);
                        let playlist = window.btoa(HlsShaka.buildMasterPlaylistWithMedia(protocolData.url));
                        this.#protocol.setPlaylistUrl('data:application/vnd.apple.mpegurl;base64,' + playlist);
                    }

                    this.initializeIfPossible();
                }

                if (response.status === 0 || response.status >= 400) {
                    this.#playerState.add(`errors`, {type: err.list.failedLoadPlaylist, httpStatus: response.status, url: protocolData.url, error: ''});
                }
            })
            .catch(error => {
                this.#playerState.add(`errors`, {type: err.list.failedLoadPlaylist, httpStatus: 0, url: protocolData.url, error: error});
            });

        return true;
    }

    initializeIfPossible()
    {
        if (this.#playerState.get(`authEnabled`) && !this.#playerState.get(`authAuthenticated`)) {
            return;
        }

        const browser                  = Bowser.getParser(window.navigator.userAgent);
        const isBrowserSupportedPoster = !browser.satisfies({
            opera:  '>=0',
            edge:   '>=0',
            chrome: '<86',
            safari: '>=13',
        });

        if ((!this.#options.poster || isBrowserSupportedPoster) && !this.#hasAdvPreRoll()) {
            this.#protocol.initialize();
        }
    }

    hasSource(options)
    {
        return (options.playback.dash && options.playback.dash.url) ||
            (options.playback.hls && options.playback.hls.url) ||
            (options.playback.mp4 && options.playback.mp4.url);
    }

    async initialize()
    {
        this.bindEvents();
        await this.#protocol.initialize();
    }

    #hasAdvPreRoll()
    {
        return this.#options.adv && this.#options.adv['pre-roll'];
    }

    #isAdv()
    {
        return !!this.#options.isAdv;
    }

    async play()
    {
        if (!this.#protocol) {
            this.#logger.log(`[Playback] Protocol is not initialized`);
            return Promise.reject();
        }

        if (this.#playerState.get(`authEnabled`) && !this.#playerState.get(`authAuthenticated`)) {
            this.#logger.log(`[Playback] Auth is enabled and the user is not authenticated`);
            return Promise.reject();
        }

        if (!this.#playedAdPreRoll && !this.#isAdv() && this.#hasAdvPreRoll()) {
            this.#playedAdPreRoll = true;
            this.#playerState.set(`playing`, true);
            this.#eventBus.emit(`adv.tryPreRoll`);

            return Promise.resolve({isAdv: true});
        }

        this.#playedAdPreRoll = true;

        try {

            if (!this.#protocol.isInitialized()) {
                await this.#protocol.initialize();
            }

            await this.#protocol.play();
        } catch (error) {
            this.#logger.error(error);
            this.#playerState.add(`errors`, {type: err.list.protocolError, error: error});

            return Promise.reject();
        }

        return Promise.resolve({isAdv: this.#isAdv()});
    }

    pause()
    {
        if (!this.#protocol) {
            return;
        }
        if (!this.#protocol.isInitialized()) {
            return;
        }
        this.#protocol.pause();
        this.#eventBus.emit(`playback.prePause`);

        this.#eventBus.once(`playback.play`, () => {
            this.#eventBus.emit(`playback.playAfterPause`);
        });
    }

    setVolume(value)
    {
        if (!this.#protocol) {
            return;
        }
        if (!this.#protocol.isInitialized()) {
            return;
        }
        this.#protocol.setVolume(value);
    }

    setMute(value)
    {
        if (!this.#protocol) {
            return;
        }
        if (!this.#protocol.isInitialized()) {
            return;
        }
        this.#protocol.setMute(value);
    }

    async setVideoTrack(value)
    {
        if (!this.#protocol) {
            return;
        }
        if (!this.#protocol.isInitialized()) {
            return;
        }

        await this.#protocol.setVideoTrack(value);

        return true;
    }

    async setAudioTrack(track)
    {
        if (!this.#protocol) {
            return;
        }

        if (!this.#protocol.isInitialized()) {
            return;
        }

        await this.#protocol.setAudioTrack(track);

        return true;
    }

    setTextTrack(track)
    {
        if (!this.#protocol) {
            return;
        }
        if (!this.#protocol.isInitialized()) {
            return;
        }

        for (let i = 0; i < this.#video.textTracks.length; i++) {

            if (track.id === -1) {
                this.#video.textTracks[i].mode = 'disabled';
                continue;
            }

            if (this.#video.textTracks[i].language === track.label) {
                this.#video.textTracks[i].mode = 'showing';
            } else {
                this.#video.textTracks[i].mode = 'hidden';
            }
        }
    }

    seek(pos)
    {
        if (!this.#protocol) {
            return;
        }
        if (!this.#protocol.isInitialized()) {
            return;
        }
        if (!isNaN(this.#video.duration)) {
            this.#protocol.seek(pos);
        }
    }

    get info()
    {
        return {
            encrypted: this.#encrypted,
            protocol:  this.#protocol,
        };
    }

    async destroy()
    {
        if (!this.#protocol) {
            return;
        }
        if (!this.#protocol.isInitialized()) {
            return;
        }

        await this.#protocol.destroy();
        this.unbindEvents();
    }
}
