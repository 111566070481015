import axios from 'axios';
import playerStorage from '../storage/player-storage';

export default class Auth
{
    #festivalId;
    #enabled = false;
    #client;

    constructor(options)
    {
        if (!options) {
            return;
        }

        if (!options.enabled) {
            return;
        }

        this.#enabled    = true;
        this.#festivalId = options.festivalId;

        this.#client = axios.create({
            baseURL:         options.serverURL,
            timeout:         5000,
            validateStatus:  function(status) {
                return status > 0;
            },
        });
    }

    isEnabled()
    {
        return this.#enabled;
    }

    async checkIsAuthenticated()
    {
        return this.#client.request({
            url:     'api/viewer/security/authenticated',
            method:  'GET',
            headers: {
                'X-AUTH-TOKEN': playerStorage.get('x-auth-token'),
            },
        })
    }

    async login(email, password)
    {
        return this.#client.request({
            url:    'api/viewer/security/login',
            method: 'POST',
            data:   {
                username:   email,
                password:   password,
                festivalId: this.#festivalId,
            },
        }).then(response => {
            playerStorage.set('x-auth-token', response.data.token);
            return response;
        });
    }

    async logout()
    {
        playerStorage.set('x-auth-token', '');

        return this.#client.request({
            url:    'api/viewer/security/logout',
            method: 'POST',
        });
    }

    async registration(email, password)
    {
        return this.#client.request({
            url:    'api/viewer/security/registration',
            method: 'POST',
            data:   {
                username:   email,
                password:   password,
                festivalId: this.#festivalId,
            },
        });
    }

    async passwordRecovery(email)
    {
        return this.#client.request({
            url:    'api/viewer/security/password-recovery',
            method: 'POST',
            data:   {
                username:   email,
                festivalId: this.#festivalId,
            },
        });
    }
}
