import React from 'react';
import * as Bowser from 'bowser';

class ContextMenu extends React.Component
{
    #browser;

    constructor(props)
    {
        super(props);
        this.props     = props;
        this.clipboard = null;
        this.#browser  = Bowser.getParser(window.navigator.userAgent);

        this.state = {showDebugPanel: this.props.playerState.showDebugPanel};
    }

    getContext()
    {
        let debugObject = {};

        if (this.props.controller.video) {
            let bufferHealth     = (this.props.playerState.props().bufferedTo - this.props.controller.video.currentTime).toFixed(2);
            let host             = `N/A`;
            let playbackProtocol = this.props.controller.playbackInfo ? this.props.controller.playbackInfo.protocol : null;
            let playbackType     = playbackProtocol ? playbackProtocol.type : `N/A`;

            if (playbackProtocol && playbackProtocol.options) {
                let playbackUrl  = document.createElement(`a`);
                playbackUrl.href = playbackProtocol.options.url;
                host             = playbackUrl.host;
            }

            debugObject = {
                version:            playernow.version,
                userAgent:          window.navigator.userAgent,
                browser:            this.#browser.getBrowserName() + ' ' + this.#browser.getBrowserVersion(),
                os:                 this.#browser.getOSName() + ' ' + this.#browser.getOSVersion(),
                platform:           this.#browser.getPlatformType(),
                engine:             this.#browser.getEngine().name + ' ' + this.#browser.getEngine().version,
                host:               host,
                volume:             (this.props.playerState.props().volume * 100).toFixed() + `%`,
                playbackType:       playbackType,
                bufferedTo:         this.props.playerState.props().bufferedTo.toFixed(2) + 's',
                bufferHealth:       bufferHealth > 0 ? bufferHealth + 's' : '0.00s',
                dimensions:         `${this.props.controller.width().replace('px', '')}x${this.props.controller.height().replace('px', '')}`,
                resolution:         `${this.props.controller.video.videoWidth}x${this.props.controller.video.videoHeight}`,
                bitrate:            this.props.playerState.props().bitrate,
                activeVideoTrackId: this.props.playerState.props().activeVideoTrackId,
                activeTextTrackId:  this.props.playerState.props().activeTextTrackId ?? '',
                activeAudioTrackId: this.props.playerState.props().activeAudioTrackId ?? '',
            };
        }

        return debugObject;
    }

    copyContext(e)
    {
        e.preventDefault();
        let clipboard   = this.clipboard;
        clipboard.value = JSON.stringify(this.getContext());
        clipboard.select();
        document.execCommand(`copy`);
        clipboard.blur();

        this.props.trigger(false);
    }

    handleClick()
    {
        this.setState({showDebugPanel: !this.state.showDebugPanel});
    }

    renderShowContextMenuButton()
    {
        if (this.state.showDebugPanel) {
            return <button onClick={(e) => this.handleClick(e)}>Hide debug info</button>;
        }

        return <button onClick={(e) => this.handleClick(e)}>Show debug info</button>;
    }

    render()
    {
        let contextMenuDisplay = `none`,
            debugPanelDisplay  = `none`,
            left               = 0,
            top                = 0,
            debugObject        = this.getContext();

        if (this.props.visible) {
            contextMenuDisplay = `block`;
            // TODO: add max left and top position
            left               = this.props.points.clientX - this.props.playerElement.getBoundingClientRect().left;
            top                = this.props.points.clientY - this.props.playerElement.getBoundingClientRect().top;
        }

        if (this.state.showDebugPanel) {
            debugPanelDisplay = `block`;
        }

        return (
            <div className="context-wrapper">
                <div className='playernow-contextmenu'
                     style={{left: left, top: top, display: contextMenuDisplay}}>
                    <ul>
                        <li>
                            {this.renderShowContextMenuButton()}
                        </li>
                        <li>
                            <button onClick={(e) => this.copyContext(e)}>Copy player statistics</button>
                        </li>
                        <li>
                            <span>playernow! {playernow.version}</span>
                        </li>
                    </ul>
                    <textarea className="playernow-clipboard"
                              ref={(clipboard) => this.clipboard = clipboard}/>
                </div>
                <div className="playernow-debug" style={{display: debugPanelDisplay}}>
                    <button className="playernow-debug-close" onClick={e => this.handleClick(e)}></button>
                    {Object.keys(debugObject).map(e =>
                        <div key={e}>{e}: {debugObject[e].toString()}</div>)}
                </div>
            </div>
        );
    }
}

export default ContextMenu;
