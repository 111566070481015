function isAbsolute(url)
{
    return url.indexOf(`http:`) === 0 || url.indexOf(`https:`) === 0;
}

function join(main, relative)
{
    let parts    = main.split(`://`);
    let protocol = parts[0];
    let url      = parts[1];

    parts      = url.split(`/`);
    let domain = parts[0];
    let tail   = parts.slice(1);
    if (tail.length === 0 || relative[0] === `/`) {
        return `${protocol}://${domain}${relative}`;
    }

    let basePath = main.split(`/`).slice(0, -1).join(`/`);
    return `${basePath}/${relative}`;
}

function isSameSchemaAndHostAndPort(url)
{
    let urlObject = new URL(url);
    let location  = window.location;
    return location.schema === urlObject.schema &&
        location.hostname === urlObject.hostname &&
        location.port === urlObject.port;
}

export {isAbsolute, join, isSameSchemaAndHostAndPort};