import {parsers} from './parsers';
import TrackingEvent from './TrackingEvent';
import MediaFile from './MediaFile';
import VideoClicks from './VideoClicks';
import * as xml from '../utils/xml';
import Icon from './Icon';

function parseTrackingEvents(trackingEvents, duration)
{
    if (trackingEvents) {
        trackingEvents = Array.isArray(trackingEvents) ? trackingEvents : [trackingEvents];
        return trackingEvents.map(t => new TrackingEvent(t, duration));
    }
    return [];
}

function parseMediaFiles(mediaFiles)
{
    if (mediaFiles) {
        let mediaFilesList = Array.isArray(mediaFiles) ? mediaFiles : [mediaFiles];
        return mediaFilesList.map(m => new MediaFile(m));
    }
    return [];
}

function parseIcons(icons)
{
    if (icons) {
        let iconList = Array.isArray(icons) ? icons : [icons];
        return iconList.map(i => new Icon(i));
    }
    return [];
}

export default class Linear
{
    constructor(obj)
    {
        //Required Elements
        this.duration   = parsers.duration(xml.value(obj.duration));
        this.mediaFiles = parseMediaFiles(obj.mediaFiles && obj.mediaFiles.mediaFile);

        //Optional fields
        this.trackingEvents = parseTrackingEvents(obj.trackingEvents && obj.trackingEvents.tracking, this.duration);
        this.skipoffset     = parsers.offset(obj.skipoffset, this.duration);

        if (obj.videoClicks) {
            this.videoClicks = new VideoClicks(obj.videoClicks);
        }

        if (obj.icons) {
            this.icons = parseIcons(obj.icons && obj.icons.icon);
        }

        if (obj.adParameters) {
            this.adParameters = xml.value(obj.adParameters);

            if (xml.attr(obj.adParameters, `xmlEncoded`)) {
                this.adParameters = xml.decode(this.adParameters);
            }
        }
    }
}