import Linear from './Linear';
import NonLinearAds from './NonLinearAds';
import Companion from './Companion';
import * as xml from '../utils/xml';

export default class Creative
{
    static parseCreatives(obj)
    {
        if (obj && obj.creative) {
            let creativesData = Array.isArray(obj.creative) ? obj.creative : [obj.creative];
            return creativesData.map(c => new Creative(c));
        }
        return [];
    }

    constructor(obj)
    {
        this.id           = obj.id;
        this.sequence     = parseInt(obj.sequence || 0);
        this.adId         = obj.adId;
        this.apiFramework = obj.apiFramework;

        if (obj.linear) {
            this.linear = new Linear(obj.linear);
        }

        if (obj.nonLinearAds) {
            this.nonLinearAds = new NonLinearAds(obj.nonLinearAds);
        }

        if (obj.companionAds) {
            this.companionAds = [];
            let companionAds  = obj.companionAds && obj.companionAds.companion;
            if (companionAds) {
                companionAds      = Array.isArray(companionAds) ? companionAds : [companionAds];
                this.companionAds = companionAds.map(c => new Companion(c));
            }
            this.companionAdsRequired = xml.attr(`required`, obj.companionAds);
        }
    }
}