import Playlist from './playlist';
import Fairplay from './fairplay';
import Events from './events';
import Protocol from '../protocol';
import VideoTrack from '../../track/video-track';
import AudioTrack from '../../track/audio-track';

export default class NativeHls extends Protocol
{
    static type         = `NativeHls`;
    #eventsInterval;
    #playlist;
    #events;
    #drm;
    #activeVideoTrackId = -1;

    constructor(video, options, eventBus, logger)
    {
        super(NativeHls.type, video, options, eventBus, logger);

        this.#eventsInterval = null;
    }

    static isSupported()
    {
        let video = document.createElement('video');
        return Boolean(video.canPlayType('application/vnd.apple.mpegURL') || video.canPlayType('audio/mpegurl'));
    }

    #bindEvents()
    {
        this.onLoadedMetadata       = this.onLoadedMetadata.bind(this);
        this.updateActiveAudioTrack = this.updateActiveAudioTrack.bind(this);
        this.video.addEventListener(`loadedmetadata`, this.onLoadedMetadata);
        this.video.addEventListener('canplay', this.updateActiveAudioTrack);
    }

    #unbindEvents()
    {
        this.video.removeEventListener(`loadedmetadata`, this.onLoadedMetadata);
        this.video.removeEventListener('canplay', this.updateActiveAudioTrack);
    }

    onLoadedMetadata()
    {
        this.eventBus.emit(`playback.metadata`, {duration: this.video.duration});
    }

    updateVideoTracks()
    {
        const bitrateList = this.#playlist.videoTracks;

        let heights = bitrateList.map((level) => {
            return level.height;
        });

        let hasDuplicateHeights = bitrateList.length !== (new Set(heights)).size;

        const videoTracks = bitrateList.map(level => {

            let label = level.height;
            if (hasDuplicateHeights && level.bandwidth) {
                label += ' (' + level.bandwidth + ')';
            }

            return new VideoTrack(level.id, level.height, label);
        });

        videoTracks.sort(this.sortVideoTracks);
        videoTracks.push(new VideoTrack(-1, 0, `auto`));
        this.eventBus.emit(`playback.videoTracks`, {videoTracks});
    }

    updateAudioTracks()
    {
        const bitrateList = this.#playlist.audioTracks;

        let audioTracks = bitrateList.map((level) => {
            let label = level.name ?? level.language;
            return new AudioTrack(level.id, level.language, label);
        });

        this.eventBus.emit(`playback.audioTracks`, {audioTracks});
    }

    updateActiveAudioTrack()
    {
        let track = this.#getActiveAudioTrack();
        if (track) {
            this.eventBus.emit(`playback.activeAudioTrackId`, {activeAudioTrackId: track.id});
        }
    }

    #getActiveAudioTrack()
    {
        for (let i = 0; i < this.video.audioTracks.length; i++) {
            let nativeTrack = this.video.audioTracks[i];

            if (nativeTrack.kind !== 'main') {
                continue;
            }

            if (!nativeTrack.enabled) {
                continue;
            }

            let track = this.#playlist.audioTracks.find(track => track.language === nativeTrack.language);
            if (!track) {
                continue;
            }

            return track;
        }

        return null;
    }

    async setVideoTrack(track)
    {
        if (track.id === this.#activeVideoTrackId) {
            return;
        }

        let currentTime      = this.video.currentTime;
        let playing          = !this.video.paused;
        let activeAudioTrack = this.#getActiveAudioTrack();

        this.#setVideoTrackId(track.id);

        let afterLoad = () => {
            this.video.removeEventListener(`canplay`, afterLoad);
            this.seek(currentTime);
            if (activeAudioTrack) {
                this.eventBus.emit(`playback.activeAudioTrackId`, {activeAudioTrackId: activeAudioTrack.id});
                this.setAudioTrack(activeAudioTrack);
            }
        };

        this.video.addEventListener(`canplay`, afterLoad);

        if (playing) {
            await this.play();
        }

        return true;
    }

    #setVideoTrackId(trackId)
    {
        if (trackId === -1) {
            this.video.src = this.options.url;
        } else {
            let playlist   = window.btoa(this.#playlist.buildMasterPlaylistWithMedia(trackId));
            this.video.src = 'data:application/vnd.apple.mpegurl;base64,' + playlist;
        }

        this.#activeVideoTrackId = trackId;
    }

    async setAudioTrack(track)
    {
        for (let i = 0; i < this.video.audioTracks.length; i++) {
            let nativeTrack = this.video.audioTracks[i];

            if (nativeTrack.kind !== 'main') {
                continue;
            }

            nativeTrack.enabled = nativeTrack.language === track.language;
        }

        return true;
    }

    async initialize(activeVideoTrackId = -1)
    {
        return new Promise((resolve, reject) => {
            this.logger.log(`[Playback][HLS Native] Initializing...`);

            this.#bindEvents();

            this.#playlist = new Playlist(this.options.url);
            this.#playlist.parse()
                .then(() => {
                    this.updateVideoTracks();
                    this.updateAudioTracks();
                    this.#events = new Events(this.#playlist.events, this.video, this.eventBus);

                    if (this.options.scteEnabled && !this.#eventsInterval) {
                        this.#eventsInterval = setInterval(() => {
                            if (this.video.currentTime + 1 > this.#playlist.timeOffset + this.#playlist.duration) {
                                this.#playlist.updateTimes();
                                this.#events.events = this.#playlist.events;
                            }
                        }, 1000);
                    }

                    if (this.options.drm) {
                        this.logger.log(`[Playback][HLS Native] DRM detected`);

                        if (!this.#drm) {
                            this.#drm = new Fairplay(this.video, this.options.drm, this.options.contentId, this.logger, this.eventBus);
                            this.#drm.init().then(() => {
                                this.initialized = true;
                                this.#setVideoTrackId(activeVideoTrackId);
                                this.logger.log(`[Playback][HLS Native] Initialized`);
                                resolve();
                            });
                            return;
                        }
                    }

                    this.initialized = true;
                    this.#setVideoTrackId(activeVideoTrackId);
                    this.logger.log(`[Playback][HLS Native] Initialized`);
                    resolve();
                }).catch(err => {
                reject(err);
            });
        });
    }

    async destroy()
    {
        this.#unbindEvents();

        if (this.video.src) {
            this.video.src = '';
        }

        if (typeof this.video.setMediaKeys === `function`) {
            this.video.setMediaKeys(null);
        }

        if (this.#events) {
            this.#events.destroy();
        }
        if (this.#eventsInterval) {
            clearInterval(this.#eventsInterval);
        }
    }
}
