function _toFixedDigits(num, digits)
{
    let formattedNum = num + ``;
    digits           = typeof digits === `number` ? digits : 0;
    num              = typeof num === `number` ? num : parseInt(num, 10);
    if (typeof num === `number` && !isNaN(num)) {
        formattedNum = num + ``;
        while (formattedNum.length < digits) {
            formattedNum = `0` + formattedNum;
        }
        return formattedNum;
    }
    return NaN + ``;
}

function _parseURLMacro(URLMacro, variables = {})
{
    Object.keys(variables).forEach((key) => {
        let value = variables[key];
        URLMacro  = URLMacro.replace(new RegExp(`\\[` + key + `\\\]`, `gm`), value);
    });
    return URLMacro;
}

export const formatProgress = (progress) => {
    let hours        = progress / (60 * 60 * 1000);
    hours            = Math.floor(hours);
    let minutes      = (progress / (60 * 1000)) % 60;
    minutes          = Math.floor(minutes);
    let seconds      = (progress / 1000) % 60;
    seconds          = Math.floor(seconds);
    let milliseconds = progress % 1000;
    return `${_toFixedDigits(hours, 2)}:${_toFixedDigits(minutes, 2)}:${_toFixedDigits(seconds, 2)}.${_toFixedDigits(milliseconds, 3)}`;
};

export const track = (URLMacros, variables) => {
    return parseURLMacros(URLMacros, variables)
        .map((src) => {
            let img = new Image();
            img.src = src;
            return img;
        });
};

export const parseURLMacros = (URLMacros, variables = {}) => {
    if (!(variables.CACHEBUSTING)) {
        variables.CACHEBUSTING = Math.round(Math.random() * 1.0e+10);
    }
    return URLMacros.map((URLMacro) => _parseURLMacro(URLMacro, variables));
};

export const parseURLMacro = (URLMacro, variables = {}) => {
    if (!(variables.CACHEBUSTING)) {
        variables.CACHEBUSTING = Math.round(Math.random() * 1.0e+10);
    }
    return _parseURLMacro(URLMacro, variables);
};

export const isVPAID = (source) => {
    return source.apiFramework === `VPAID`;
};