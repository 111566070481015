import VastTracker from '../vast/VastTracker';

export default class NonLinearHandler
{
    static selectAdSource(sources, player)
    {
        let sorted = sources.sort((a, b) => {
            let deltaA = Math.abs(player.width() - a.width);
            let deltaB = Math.abs(player.width() - b.width);
            return deltaA - deltaB;
        });
        return sorted.length ? sorted[0] : null;
    }

    constructor(eventBus, playerState, playerController, source, logger)
    {
        this.eventBus         = eventBus;
        this.playerState      = playerState;
        this.playerController = playerController;
        this.source           = source;
        this.logger           = logger;
        this.timeOnStart      = this.playerState.get(`currentTime`) * 1000;
    }

    start(response)
    {
        this.logger.log(`[NonLinearHandler] Start Ad`);
        try {
            this.tracker = this.createTracker(response);
            this.createController();
            this.setupEvents();
            return this.startAd();
        } catch (err) {
            this.handleError(err);
        }
    }

    createTracker(response)
    {
        let trackingInfo = {
            src:            this.source.src,
            errorURLMacros: response.errorURLMacros,
            trackingEvents: response.nonLinear.trackingEvents,
            clickTrackings: this.source.clickTracking ? [this.source.clickTracking] : [],
            duration:       this.source.duration,
        };
        return new VastTracker(trackingInfo, this.logger);
    }

    createController()
    {
        let tracker               = this.tracker;
        this.playerController.adv = this.playerController.adv || {};
        this.controllerSnapshot   = this.playerController.adv;
        Object.assign(this.playerController.adv, {
            nonLinearClick()
            {
                tracker.trackClick();
            },
        });
    }

    revertController()
    {
        this.playerController.adv = this.controllerSnapshot;
    }

    setupEvents()
    {
        let eventBus    = this.eventBus;
        let playerState = this.playerState;
        let source      = this.source;
        let tracker     = this.tracker;
        let previouslyMuted;

        this.eventBus.on(`player.fullscreenchange`, trackFullscreenChange);
        this.eventBus.on(`playback.play`, trackStart);
        this.eventBus.on(`playback.pause`, trackPause);
        this.eventBus.on(`playback.currentTimeChange`, trackProgress);
        this.eventBus.on(`playback.volumechange`, trackVolumeChange);
        this.eventBus.onceOf([`vast.adEnd`, `vast.adCancel`], (e) => {
            if (e.event === `vast.adEnd`) {
                this.tracker.trackComplete();
            }
            unbindEvents();
        });

        let that = this;

        function trackImpression()
        {
            tracker.trackCreativeView();
        }

        function trackFullscreenChange(e)
        {
            if (e.fullscreen) {
                tracker.trackFullscreen();
            } else {
                tracker.trackExitFullscreen();
            }
        }

        function trackStart()
        {
            tracker.trackStart();
        }

        function trackPause()
        {
            //NOTE: whenever a video ends the video Element triggers a 'pause' event before the 'ended' event.
            //      We should not track this pause event because it makes the VAST tracking confusing again we use a
            //      Threshold of 2 seconds to prevent false positives on IOS.
            if (Math.abs(playerState.get(`duration`) - playerState.get(`currentTime`)) < 2) {
                return;
            }

            tracker.trackPause();
            that.logger.log(`track pause`);
            eventBus.once(`playback.play`, () => {
                tracker.trackResume();
            });
        }

        function trackProgress()
        {
            let currentTimeInMs = playerState.get(`currentTime`) * 1000 - that.timeOnStart;
            tracker.trackProgress(currentTimeInMs);
            if (currentTimeInMs > source.duration) {
                that.eventBus.emit(`vast.nonLinear.adEnd`);
            }
        }

        function trackVolumeChange()
        {
            let muted = playerState.get(`isMuted`);
            if (muted) {
                tracker.trackMute();
            } else if (previouslyMuted) {
                tracker.trackUnmute();
            }
            previouslyMuted = muted;
        }

        function unbindEvents()
        {
            eventBus.off(`vast.adStart`, trackImpression);
            eventBus.off(`player.fullscreenchange`, trackFullscreenChange);
            eventBus.off(`playback.play`, trackStart);
            eventBus.off(`playback.pause`, trackPause);
            eventBus.off(`playback.currentTimeChange`, trackProgress);
            eventBus.off(`playback.volumechange`, trackVolumeChange);
        }
    }

    startAd()
    {
        this.playerState.set(`adNonLinearSource`, this.source);

        return new Promise((resolve) => {
            this.eventBus.onceOf([`vast.nonLinear.adEnd`, `playback.ended`, `vast.adsCancel`], () => {
                this.eventBus.emit(`vast.adEnd`);

                this.playerState.set(`adNonLinearSource`, null);
                this.revertController();
                this.logger.log(`[NonLinearHandler] End Ad`);
                resolve();
            });

            this.eventBus.emit(`vast.adStart`);
        });
    }

    handleError(err)
    {
        if (this.tracker) {
            this.tracker.trackGeneralVastError(500);
        }
        this.logger.error(err);
    }
}
