export default class VastError extends Error
{
    constructor(message = ``, code = 900, data = {})
    {
        super(message);
        this.name = `VastError`;
        this.code = code;
        this.data = data;
    }
}



