import React from 'react';
import TextTrack from './TextTrack';

class TextTrackControl extends React.Component
{
    constructor(props)
    {
        super(props);

        props.playerState.on('change', () => {
            this.setState({player: props.playerState.props()});
        });

        this.state = {
            player:              props.playerState.props(),
            showList:            false,
            selectedTextTrackId: props.playerState.activeTextTrackId,
        };

        this.handleClick      = this.handleClick.bind(this);
        this.handleTrackClick = this.handleTrackClick.bind(this);
    }

    handleClick(event)
    {
        this.setState({
            showList:            !this.state.showList,
            selectedTextTrackId: this.state.player.activeTextTrackId,
        });
    }

    handleTrackClick(track, event)
    {
        this.props.controller.setTextTrackId(track.id);
        this.setState({showList: false});
    }

    className(track)
    {
        return 'playernow-subtitle' +
            (track.id === this.state.player.activeTextTrackId ? ' active' : '') +
            (track.id === this.state.selectedTextTrackId ? ' selected' : '');
    }

    renderTracks(textTracks)
    {
        if (textTracks.length === 1) {
            return;
        }

        return <ul className="playernow-subtitle-list">
            {
                textTracks.map(track => (
                    <li onMouseDown={e => this.handleTrackClick(track, e)} className={this.className(track)}
                        key={track.id}>{track.label}</li>
                ))
            }
        </ul>;
    }

    onFocus(event)
    {
        event.preventDefault();
        this.setState({
            showList:            true,
            selectedTextTrackId: this.state.player.activeTextTrackId,
        });
        this.props.playerState.set(`focusElement`, `TextTrackControl`);
    }

    onBlur(event)
    {
        event.preventDefault();
        this.setState({showList: false});
    }

    onKeyDown(e)
    {
        if (!e.keyCode) {
            return;
        }

        if (this.props.playerState.get(`focusElement`) !== 'TextTrackControl') {
            return;
        }

        let textTracks = this.state.player.textTracks.slice();

        let selectedIndex = textTracks.findIndex((val) => {
            return val.id === this.state.selectedTextTrackId;
        });

        if (selectedIndex === -1) {
            return;
        }

        switch (e.keyCode) {
            case 38:
            case 39:
                e.preventDefault();

                if (selectedIndex === 0) {
                    return;
                }

                this.setState({selectedTextTrackId: textTracks[selectedIndex - 1].id});

                break;
            case 37:
            case 40:
                e.preventDefault();

                if (selectedIndex === textTracks.length - 1) {
                    return;
                }

                this.setState({selectedTextTrackId: textTracks[selectedIndex + 1].id});

                break;
            case 13:
                e.preventDefault();
                this.props.controller.setTextTrackId(this.state.selectedTextTrackId);
                break;
        }
    }

    render()
    {
        if (this.state.player.adPlaying === 'linear') {
            return <div/>;
        }

        if (this.state.player.textTracks.length === 1) {
            return <div/>;
        }

        const textTracksWrapClass = this.state.showList
            ? 'playernow-action-block playernow-subtitle-wrap active'
            : 'playernow-action-block playernow-subtitle-wrap';

        const iconClass = this.state.player.activeTextTrackId === -1
            ? 'tab-button playernow-subtitle-icon'
            : 'tab-button playernow-subtitle-icon active';

        let textTracks = this.state.player.textTracks.slice();

        return (
            <div className={textTracksWrapClass} onKeyDown={e => this.onKeyDown(e)}>
                <span
                    tabIndex="0"
                    className={iconClass}
                    onMouseDown={this.handleClick}
                    onFocus={e => this.onFocus(e)}
                    onBlur={e => this.onBlur(e)}
                >
                </span>
                {this.renderTracks(textTracks)}
            </div>
        );
    }
}

export default TextTrackControl;
