import React from 'react';

class VideoTrackControl extends React.Component
{
    constructor(props)
    {
        super(props);

        props.playerState.on('change', () => {
            this.setState({player: props.playerState.props()});
        });

        this.state = {
            player:               props.playerState.props(),
            showList:             false,
            selectedVideoTrackId: props.playerState.activeVideoTrackId,
        };

        this.handleClick      = this.handleClick.bind(this);
        this.handleTrackClick = this.handleTrackClick.bind(this);
    }

    handleClick(event)
    {
        this.setState({
            showList:             !this.state.showList,
            selectedVideoTrackId: this.state.player.activeVideoTrackId,
        });
    }

    handleTrackClick(track, event)
    {
        this.props.controller.setVideoTrackId(track.id);
        this.setState({showList: !this.state.showList});
    }

    qualityClassName(quality)
    {
        return 'playernow-quality' +
            (quality.id === this.state.player.activeVideoTrackId ? ' active' : '') +
            (quality.id === this.state.selectedVideoTrackId ? ' selected' : '');
    }

    renderTracks(videoTracks)
    {
        if (videoTracks.length === 2) {
            videoTracks = videoTracks.filter(quality => {
                return quality.id === -1;
            });
        }

        return <ul className="playernow-quality-list">
            {
                videoTracks.map(track => (
                    <li onMouseDown={e => this.handleTrackClick(track, e)} className={this.qualityClassName(track)}
                        key={track.id}>{track.label}</li>
                ))
            }
        </ul>;
    }

    onFocus(event)
    {
        event.preventDefault();
        this.setState({
            showList:             true,
            selectedVideoTrackId: this.state.player.activeVideoTrackId,
        });
        this.props.playerState.set(`focusElement`, `VideoTrackControl`);
    }

    onBlur(event)
    {
        event.preventDefault();
        this.setState({showList: false});
    }

    onKeyDown(e)
    {
        if (!e.keyCode) {
            return;
        }

        if (this.props.playerState.get(`focusElement`) !== 'VideoTrackControl') {
            return;
        }

        if (this.state.player.videoTracks.length <= 1) {
            return;
        }

        let selectedIndex = this.state.player.videoTracks.findIndex(track => {
            return track.id === this.state.selectedVideoTrackId;
        });

        if (selectedIndex === -1) {
            return;
        }

        switch (e.keyCode) {
            case 38:
            case 39:
                e.preventDefault();

                if (selectedIndex === 0) {
                    return;
                }

                this.setState({selectedVideoTrackId: this.state.player.videoTracks[selectedIndex - 1].id});

                break;
            case 37:
            case 40:
                e.preventDefault();

                if (selectedIndex === this.state.player.videoTracks.length - 1) {
                    return;
                }

                this.setState({selectedVideoTrackId: this.state.player.videoTracks[selectedIndex + 1].id});

                break;
            case 13:
                e.preventDefault();
                this.props.controller.setVideoTrackId(this.state.selectedVideoTrackId);
                break;
        }
    }

    render()
    {
        if (this.state.player.adPlaying === 'linear') {
            return <div/>;
        }

        if (this.state.player.videoTracks.length <= 2) {
            return <div/>;
        }

        const qualityWrapClass = this.state.showList
            ? 'playernow-action-block playernow-quality-wrap active'
            : 'playernow-action-block playernow-quality-wrap';

        return (
            <div className={qualityWrapClass} onKeyDown={e => this.onKeyDown(e)}>
                <span
                    tabIndex="0"
                    className="tab-button playernow-quality-icon"
                    onFocus={(e) => this.onFocus(e)}
                    onBlur={(e) => this.onBlur(e)}
                    onMouseDown={this.handleClick}
                />
                {this.renderTracks(this.state.player.videoTracks)}
            </div>
        );
    }
}

export default VideoTrackControl;
