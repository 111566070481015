import './styles/player.scss';

import Translate from './modules/translate/translate';
import PlayerController from './controller/player-controller';
import PlayerState from './model/player-state';
import View from './view/view';
import Adv from './modules/adv/adv';
import Stat from './modules/statistics/stat';
import EventEmitter from './event/event-emitter';
import Logger from './modules/logger/logger';
import shaka from 'shaka-player';
import TextTrack from './model/playback/track/text-track';
import ViewersWsClient from './modules/viewers/ws-client';

const defaultSkinName = `cdnnow-video-skin`;

class playernow
{
    static version = __VERSION__;

    #options;
    #translate;

    #logger;
    #eventBus;
    #playerState;
    #playerController;
    #view;
    #adv;
    #stat;

    constructor(root, options)
    {
        this.#options = options;

        this.#options.textTracks = [];
        if (this.#options.subtitles) {
            let textTracks           = this.#options.subtitles.filter(subtitle => {
                const regex = /^https?:\/\//i;
                return regex.test(subtitle.src);
            });
            this.#options.textTracks = textTracks.map((subtitle, index) => {
                return new TextTrack(index, subtitle.src, subtitle.lang);
            });
        }
        this.#options.textTracks.push(new TextTrack(-1, '', 'disabled'));

        shaka.polyfill.installAll();

        this.initLogger();
        this.logger.info('[Player] version ' + playernow.version);

        this.#translate = new Translate(this.#options);

        if (!this.#options.errorMessages) {
            this.#options.errorMessages = {};
        }

        this.#options.messages = {
            ignore:                 this.#options.errorMessages.ignore || '',
            videoUnavailable:       this.#options.errorMessages.media || this.#translate.L(`VIDEO_UNAVAILABLE`),
            videoUnsupported:       this.#options.errorMessages.playback || this.#translate.L(`VIDEO_UNSUPPORTED`),
            errEmptySource:         this.#options.errorMessages.emptySource || this.#translate.L(`SPECIFY_VIDEO_SOURCE`),
            errDrmNotSupported:     this.#options.errorMessages.errDrmNotSupported || this.#translate.L(`DRM_NOT_SUPPORTED`),
            skipAd:                 this.#translate.L(`SKIP_AD`),
            skipIn:                 this.#translate.L(`SKIP_IN`),
            failedLoadPlaylist:     this.#translate.L(`FAILED_LOAD_PLAYLIST`),
            failedLoadSubtitles:    this.#translate.L(`FAILED_LOAD_SUBTITLES`),
            failedLoadPoster:       this.#translate.L(`FAILED_LOAD_POSTER`),
            failedLoadFairplayCert: this.#translate.L(`FAILED_LOAD_FAIRPLAY_CERT`),
        };

        if (this.#options.skin) {
            document.getElementById(root).classList.add(this.#options.skin);
        } else {
            document.getElementById(root).classList.add(defaultSkinName);
        }

        this.#eventBus      = new EventEmitter(this);
        this.#playerState   = new PlayerState();
        let viewersOptions  = this.#options.viewers || {};
        let viewersWsClient = new ViewersWsClient({
            logger:       this.#logger,
            playerState:  this.#playerState,
            enabled:      !!viewersOptions.enabled,
            url:          viewersOptions.serverUrl || 'ws://127.0.0.1:8081',
            pingInterval: viewersOptions.pingInterval || 2,
            videoId:      viewersOptions.videoId,
        });

        this.#playerState.set(`showViewersCounter`, !!viewersOptions.showCounter);
        this.#playerController = new PlayerController(root, this.#options, this.#eventBus, this.#playerState, this.#logger, viewersWsClient);
        this.#view             = new View(root, this.#playerController, this.#playerState, this.#options.messages, this.#translate);

        this.#adv = new Adv(this.#playerController, this.#options.adv, this.#eventBus, this.#playerState, this.#logger);
        this.#playerController.init().then(() => {
            this.#eventBus.emit(`player.initialized`);
        });
        this.#stat = new Stat(this.#playerController, this.#options.stat, this.#eventBus, this.#playerState, this.#logger);

        this.#playerState.set(`showDebugPanel`, !!this.#options.showDebugPanel);
        this.#playerState.set('poster', this.#options.poster);
        if (this.#options.autoplay) {
            this.#eventBus.once(`playback.canplay`, () => {
                this.#playerController.setMute(true);
                this.play();
            });
        }
    }

    get logger()
    {
        return this.#logger;
    }

    initLogger()
    {
        let logConfig = this.#options.logger || {};
        this.#logger  = new Logger({
            sentryDSN:          logConfig.sentryDSN,
            sentryIgnoreErrors: logConfig.sentryIgnoreErrors || [],
            debug:              logConfig.debug,
            version:            playernow.version,
        });

        if (this.#options.logInElement) {
            this.#logger.setLogElement(this.#options.logInElement);
        }
    }

    setOptions(root, options)
    {
        this.destroy().then(() => {
            this.constructor(root, options);
        });
    }

    play()
    {
        this.#playerController.play();
    }

    pause()
    {
        this.#playerController.pause();
    }

    on(event, callback, context = null)
    {
        this.#eventBus.on(event, callback, context);
    }

    emit(event, data)
    {
        this.#eventBus.emit(event, data);
    }

    async destroy()
    {
        this.#logger.log(`[Player] destroy`);
        await this.#playerController.destroy();
        this.#adv.destroy();
        this.#stat.destroy();
        this.#eventBus.destroy();
        this.#playerState.destroy();
    }
}

window.playernow = playernow;
