import * as utils from './utils/vastUtils';
import VastClient from './vast/VastClient';
import LinearHandler from './handler/LinearHandler';
import NonLinearHandler from './handler/NonLinearHandler';
import CompanionHandler from './handler/CompanionHandler';
import VPAIDIntegrator from './vpaid/VPAIDIntegrator';
import * as async from './utils/async';
import VastTracker from './vast/VastTracker';
import VastError from './vast/VastError';

class Adv
{
    constructor(controller, options = {}, eventBus, playerState, logger)
    {
        this.controller  = controller;
        this.options     = options;
        this.eventBus    = eventBus;
        this.playerState = playerState;
        this.logger      = logger;
        this.snapshot    = null;

        this.rolls = {
            pre:  {played: false},
            mid:  {},
            post: {played: false},
        };

        this.bindEvents();
    }

    bindEvents()
    {
        this.eventBus.on(`playback.play`, this.tryPreRoll, this);
        this.eventBus.on(`adv.tryPreRoll`, this.tryPreRoll, this);
        this.eventBus.once(`playback.play`, () => {this.playStartedAt = Date.now();}, this);
        this.eventBus.on(`playback.currentTimeChange`, this.tryMidRoll, this);
        this.eventBus.on(`playback.ended`, this.tryPostRoll, this);
        this.eventBus.on(`playback.prePause`, this.tryPrePause, this);
        this.eventBus.on(`playback.playAfterPause`, this.tryPostPause, this);
        this.eventBus.on(`playback.SCTE35`, this.trySCTE35, this);
    }

    unbindEvents()
    {
        this.eventBus.off(`playback.play`, this.tryPreRoll, this);
        this.eventBus.off(`adv.tryPreRoll`, this.tryPreRoll, this);
        this.eventBus.off(`playback.currentTimeChange`, this.tryMidRoll, this);
        this.eventBus.off(`playback.ended`, this.tryPostRoll, this);
        this.eventBus.off(`playback.prePause`, this.tryPrePause(), this);
        this.eventBus.off(`playback.playAfterPause`, this.tryPostPause(), this);
        this.eventBus.off(`playback.SCTE35`, this.trySCTE35, this);
    }

    trySCTE35(e)
    {
        if (!this.options[`scte`]) {
            return;
        }
        let timescale = 1;
        let duration  = e.options.duration || null;
        const url     = `${this.options[`scte`].url}?duration=${duration ? duration / timescale : 0}`;

        // dash based parameter
        if (e.options.eventStream) {
            timescale = e.options.eventStream.timescale;
        }

        if (duration) {
            setTimeout(() => {
                if (!this.snapshot) {
                    return;
                }
                if (this.snapshot.playerState) {
                    this.snapshot.playerState.currentTime += duration / timescale;
                }
                this.eventBus.emit(`vast.adsCancel`);
            }, (duration / timescale) * 1000);
        }

        this.runRoll({url});
    }

    tryPreRoll()
    {
        if (!this.options[`pre-roll`] || this.rolls.pre.played || this.playerState.get(`adPlaying`)) {
            return;
        }
        this.rolls.pre.played = true;
        this.runRoll(this.options[`pre-roll`]);
    }

    tryMidRoll()
    {
        if (!this.options[`mid-roll`] || this.playerState.get(`adPlaying`)) {
            return;
        }

        if (!Array.isArray(this.options[`mid-roll`])) {
            this.options[`mid-roll`] = [this.options[`mid-roll`]];
        }

        let time;
        if (this.playerState.get(`isStream`)) {
            time = (Date.now() - this.playStartedAt) / 1000;
        } else {
            time = this.playerState.get(`currentTime`);
        }

        time = Math.floor(time);

        this.options[`mid-roll`].forEach((roll, index) => {

            let offset = parseInt(roll.offset);
            if (offset && !this.playerState.get(`isStream`)) {
                if (time === offset && !this.rolls.mid[roll.offset]) {
                    this.rolls.mid[offset] = true;
                    this.runRoll(this.options[`mid-roll`][index]);
                }
            }

            let interval = parseInt(roll.interval);
            if (interval && time >= 1) {
                if (time % interval === 0 && !this.rolls.mid[time]) {
                    this.rolls.mid[time] = true;
                    this.runRoll(this.options[`mid-roll`][index]);
                }
            }
        });
    }

    tryPostRoll()
    {
        if (!this.options[`post-roll`] || this.rolls.post.played || this.playerState.get(`adPlaying`)) {
            return;
        }
        this.rolls.post.played = true;
        this.runRoll(this.options[`post-roll`]);
    }

    tryPrePause()
    {
        if (!this.options[`pre-pause-roll`] || this.playerState.get(`adPlaying`)) {
            return;
        }
        this.runRoll(this.options[`pre-pause-roll`]);
    }

    tryPostPause()
    {
        if (!this.options[`post-pause-roll`] || this.playerState.get(`adPlaying`)) {
            return;
        }
        this.runRoll(this.options[`post-pause-roll`]);
    }

    runRoll(settings)
    {
        let vast = new VastClient(settings.url);

        vast.getResponse()
            .then(this.playAd.bind(this))
            .catch((e) => {
                if (e.name === `VastError`) {
                    VastTracker.trackGeneralVastError(e);
                }
                this.logger.error(e);
                this.eventBus.emit(`vast.adCancel`, e);
            })
            .then(() => this.playerState.set(`adPlaying`, null));
    }

    playAd(vastResponse)
    {
        if (Array.isArray(vastResponse)) {
            return this.playAdPods(vastResponse)
                .then(() => {
                    this.logger.log(`[Adv] end AdPod`);
                });
        }

        return async.each(vastResponse.sequence, (creativeTypes) => {
            this.getSnapshot(creativeTypes);
            return this.runSequence(vastResponse, creativeTypes)
                .catch(() => this.returnPlayback(creativeTypes))
                .then((results) => {
                    let playbackSettings = {};
                    if (results.length) {
                        results.forEach(res => {
                            if (res && res.pausePlayback) {
                                playbackSettings.pausePlayback = res.pausePlayback;
                            }
                        });
                    }
                    this.returnPlayback(creativeTypes, playbackSettings);
                });
        });
    }

    playAdPods(responses)
    {
        this.logger.log('[Adv] playAdPods');

        this.getSnapshot();
        this.playerState.set(`adPlaying`, `linear`);
        let adsCanceled = false;

        return async.each(responses, (response, i) => {
            let isLast = (i === responses.length - 1);

            return async.each(response.sequence, (creativeTypes) => {
                if (adsCanceled) {
                    return;
                }
                if (isLast && creativeTypes.indexOf(`linear`) === -1) {
                    this.returnPlayback();
                }
                return this.runSequence(response, creativeTypes)
                    .then((results) => {
                        let playbackSettings = {};
                        if (results.length) {
                            results.forEach(res => {
                                if (res && res.pausePlayback) {
                                    playbackSettings.pausePlayback = res.pausePlayback;
                                }
                                adsCanceled = res && res.event === `vast.adsCancel`;
                            });
                        }
                    });
            });
        }).catch(() => this.returnPlayback())
            .then(() => this.returnPlayback());
    }

    getSnapshot(creativeTypes)
    {
        if (creativeTypes && creativeTypes.indexOf(`linear`) === -1) {
            return;
        }
        this.snapshot = {
            playback:    this.controller.playback,
            playerState: this.playerState.props(),
            playing:     this.playerState.props().playing,
        };

        this.playerState.set(`snapshotCurrentTime`, this.snapshot.playerState.currentTime);
        if (this.snapshot.playerState.isStream) {
            this.playerState.set(`snapshotCurrentTime`, this.controller.video.currentTime);
            this.playerState.set(`snapshotIsStream`, true);
        }

        this.controller.playerModel.video = this.controller.video;
    }

    runSequence(vastResponse, creativeTypes)
    {
        this.eventBus.onceOf([`vast.adStart`, `vast.adCancel`, `vast.adEnd`], (e) => {
            if (e.event === `vast.adStart`) {
                VastTracker.trackImpressions(vastResponse);
            }
        });

        let promises = [];
        if (creativeTypes.indexOf(`linear`) !== -1) {
            promises.push(this.startLinearAd(vastResponse));
        } else if (creativeTypes.indexOf(`nonLinear`) !== -1) {
            promises.push(this.startNonLinearAd(vastResponse));
        }
        if (creativeTypes.indexOf(`companion`) !== -1) {
            let handler = new CompanionHandler(this.eventBus, this.playerState, this.controller);
            promises.push(handler.start(vastResponse));
        }

        return Promise.all(promises);
    }

    startLinearAd(vastResponse)
    {
        this.logger.log(`[Adv] startLinearAd`);

        this.playerState.set(`adPlaying`, `linear`);
        this.playerState.set(`adLoading`, true);
        this.eventBus.onceOf([`playback.play`, `vast.adCancel`, `vast.adEnd`], () => {
            this.playerState.set(`adLoading`, false);
        });

        let handler;
        try {
            handler = this.getLinearHandler(vastResponse);
        } catch (e) {
            return Promise.reject(e);
        }
        return handler.start(vastResponse);
    }

    startNonLinearAd(vastResponse)
    {
        this.playerState.set(`adPlaying`, `nonLinear`);
        let handler = this.getNonLinearHandler(vastResponse);
        return handler.start(vastResponse);
    }

    getLinearHandler(vastResponse)
    {
        let source = LinearHandler.selectAdSource(vastResponse.linear.elements, this.controller);
        if (!source) {
            throw new VastError(`Could not find media files for this ad`, 403, vastResponse);
        }
        if (utils.isVPAID(source)) {
            return new VPAIDIntegrator(this.eventBus, this.playerState, this.controller, `Linear`, this.logger);
        }
        return new LinearHandler(this.eventBus, this.playerState, this.controller, source, this.logger);
    }

    getNonLinearHandler(vastResponse)
    {
        let source = NonLinearHandler.selectAdSource(vastResponse.nonLinear.elements, this.controller);
        if (!source) {
            throw new VastError(`Could not find media files for this ad`, 503, vastResponse);
        }

        if (utils.isVPAID(source)) {
            return new VPAIDIntegrator(this.eventBus, this.playerState, this.controller, `NonLinear`, this.logger);
        }
        return new NonLinearHandler(this.eventBus, this.playerState, this.controller, source, this.logger);
    }

    returnPlayback(creativeTypes, playbackSettings)
    {
        if (!this.snapshot) {
            return;
        }
        if (creativeTypes && creativeTypes.indexOf(`linear`) === -1) {
            return;
        }

        this.controller.playback = this.snapshot.playback;
        let stateSnapshot        = this.snapshot.playerState;
        this.snapshot            = null;

        this.controller.playerModel.video = this.controller.video;
        this.controller.playback.initialize(stateSnapshot.activeVideoTrackId).then(() => {
            // this.controller.playback.play();

            this.logger.debug('[Adv] snapshot', stateSnapshot);

            // removing VPAID styles
            this.controller.video.styles = ``;

            this.playerState.mset({
                currentTime: stateSnapshot.currentTime,
                playing:     stateSnapshot.playing,
            });

            //@todo https://rm.docker.ru/issues/21401
            this.eventBus.onceOf([`playback.loadedmetadata`], () => {

                if (stateSnapshot.playing) {
                    this.controller.play();
                } else {
                    if (playbackSettings.pausePlayback) {
                        this.controller.pause();
                    }

                    this.eventBus.once(`playback.play`, () => {
                        this.eventBus.emit(`playback.playAfterPause`);
                    });
                }
            });

            this.eventBus.onceOf([`playback.canplay`], () => {

                if (!stateSnapshot.isStream) {
                    this.controller.seek(stateSnapshot.currentTime);
                } else if (stateSnapshot.currentTime !== this.playerState.get(`snapshotCurrentTime`)) {
                    this.controller.seek(stateSnapshot.currentTime);
                }

                this.controller.setVideoTrackId(stateSnapshot.activeVideoTrackId).then(() => {
                    this.controller.setAudioTrackId(stateSnapshot.activeAudioTrackId);
                    this.controller.setTextTrackId(stateSnapshot.activeTextTrackId);
                });
            });

            this.controller.setVolume(stateSnapshot.volume);
            this.controller.setMute(stateSnapshot.isMuted);
            this.playerState.set(`snapshotCurrentTime`, null);
            this.playerState.set(`snapshotIsStream`, null);
        });
    }

    destroy()
    {
        this.unbindEvents();
    }
}

export default Adv;
