export default class Events
{
    #events;
    #video;
    #eventBus;

    constructor(events = [], video, eventBus)
    {
        this.#events   = events;
        this.#video    = video;
        this.#eventBus = eventBus;

        this.#bindEvents();
    }

    #bindEvents()
    {
        this.#eventBus.on(`playback.timeupdate`, this.checkEvents, this);
    }

    #unbindEvents()
    {
        this.#eventBus.off(`playback.timeupdate`, this.checkEvents, this);
    }

    checkEvents()
    {
        this.#events.forEach((event, index) => {
            if (event[`SCTE35out`] && Math.abs(this.#video.currentTime - event.time) < 0.5) {
                this.#eventBus.emit(`playback.SCTE35`, {options: event});
                this.#events.splice(index, 1);
            }
        });
    }

    destroy()
    {
        this.#unbindEvents();
    }
}
