import Bowser from 'bowser';

export const isMobileDevice = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return [Bowser.PLATFORMS_MAP.mobile, Bowser.PLATFORMS_MAP.tabled].includes(browser.getPlatform());
};

export const isChrome = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserName() === Bowser.BROWSER_MAP.chrome;
};

export const isAppleDevice = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return [Bowser.OS_MAP.iOS, Bowser.OS_MAP.MacOS].includes(browser.getOSName());
};