import React from 'react';
import PropTypes from 'prop-types';
import {isMobileDevice} from '../../utils/client';

class VolumeControl extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            player: props.playerState.props(),
            show:   false,
        };

        props.playerState.on(`change`, () => {
            this.setState({player: props.playerState.props()});
        });

        this.prevVolume = 0;

        this.handleMuteClick       = this.handleMuteClick.bind(this);
        this.handleUnmuteClick     = this.handleUnmuteClick.bind(this);
        this.handleSliderMouseDown = this.handleSliderMouseDown.bind(this);
        this.sliderElement         = null;
    }

    handleMuteClick(event)
    {
        event.preventDefault();
        this.prevVolume = this.state.player.volume;
        this.props.controller.setVolume(0);
        this.props.controller.setMute(true);
    }

    handleUnmuteClick(event)
    {
        event.preventDefault();
        this.props.controller.setVolume(this.prevVolume);
        this.props.controller.setMute(false);
    }

    handleSliderMouseDown(e)
    {
        e.preventDefault();
        const handle     = this.sliderElement;
        const controller = this.props.controller;
        this.prevVolume  = this.state.player.volume;

        function moveAt(e)
        {
            const offsetLeft = handle.getBoundingClientRect().left;
            let width        = handle.getBoundingClientRect().right - handle.getBoundingClientRect().left;
            let left         = e.clientX - offsetLeft;
            let current      = Math.min(100, Math.max(0, (left / width) * 100));

            if (current / 100 === 0) {
                controller.setMute(true);
            } else {
                controller.setMute(false);
                controller.setVolume(current / 100);
            }
        }

        function onMouseMove(e)
        {
            moveAt(e);
        }

        document.addEventListener(`mousemove`, onMouseMove);
        document.addEventListener(`mouseup`, (e) => {
            document.removeEventListener(`mousemove`, onMouseMove);
        });
        moveAt(e);
    }

    onFocus(event)
    {
        event.preventDefault();
        this.setState({show: true});
        this.props.playerState.set(`focusElement`, `VolumeControl`);
    }

    onBlur(event)
    {
        event.preventDefault();
        this.setState({show: false});
    }

    onKeyDown(e)
    {
        if (!e.keyCode) {
            return;
        }

        if (this.props.playerState.get(`focusElement`) !== 'VolumeControl') {
            return;
        }

        switch (e.keyCode) {
            case 38:
            case 39:
                e.preventDefault();
                this.props.changeVolume(0.1);
                break;
            case 40:
            case 37:
                e.preventDefault();
                this.props.changeVolume(-0.1);
                break;
        }
    }

    render()
    {
        let sliderWidth = this.state.player.isMuted ? 0 : this.state.player.volume * 100;

        let btn = <span
            tabIndex="0"
            className="tab-button playernow-volume-button playernow-volume-mute"
            onMouseDown={this.handleMuteClick}
            onFocus={(e) => this.onFocus(e)}
            onBlur={(e) => this.onBlur(e)}
        />;

        if (this.state.player.isMuted) {
            btn = <span
                tabIndex="0"
                className="tab-button playernow-volume-button playernow-volume-unmute"
                onMouseDown={this.handleUnmuteClick}
                onFocus={(e) => this.onFocus(e)}
                onBlur={(e) => this.onBlur(e)}
            />;
        }

        const wrapClass = this.state.show
            ? 'playernow-action-block playernow-sound-control-wrap active'
            : 'playernow-action-block playernow-sound-control-wrap';

        return (
            <div
                className={wrapClass}
                onKeyDown={(e) => this.onKeyDown(e)}
            >
                {btn}
                {!isMobileDevice()
                    ? <div className="playernow-slider-wrap"
                           ref={(sliderElement) => this.sliderElement = sliderElement}
                           onMouseDown={this.handleSliderMouseDown}>
                        <div className="playernow-slider-range">
                            <div className="playernow-slider-range-value" style={{width: `${sliderWidth}%`}}/>
                        </div>
                    </div>
                    : ''}
            </div>
        );
    }
}

VolumeControl.propTypes = {
    controller:  PropTypes.object.isRequired,
    playerState: PropTypes.object.isRequired,
};

export default VolumeControl;
