import React from 'react';

class AdWrapper extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state       = {
            player: props.playerState.props(),
        };
        this.updateState = this.updateState.bind(this);
    }

    componentDidMount()
    {
        this.props.playerState.on(`change`, this.updateState);
    }

    componentWillUnmount()
    {
        this.props.playerState.off(`change`, this.updateState);
    }

    updateState()
    {
        this.setState({player: this.props.playerState.props()});
    }

    handleClick(e)
    {
        if (!this.state.player.playing ||
            (typeof this.state.player.adExtensions.isClickable !== `undefined` && parseInt(this.state.player.adExtensions.isClickable) === 0)) {
            e.preventDefault();
        }
        this.props.controller.wrapperClick(this.state.player.adExtensions.closeAct);
    }

    handleInvitationClick()
    {
        this.props.controller.acceptInvitation(this.state.player.adExtensions.closeAct);

        if (this.state.player.adPlaying.toLowerCase() === `linear`) {
            this.props.controller.acceptInvitationLinear();
        }
    }

    handleSkipButton(e)
    {
        e.preventDefault();
        this.props.controller.skip();
    }

    handleCloseButton(e)
    {
        e.preventDefault();
        this.props.controller.close();

        if (this.state.player.adPlaying.toLowerCase() === `linear`) {
            this.props.controller.closeLinear();
        }
    }

    handleNonLinearClick()
    {
        let link = this.state.player.adNonLinearSource && this.state.player.adNonLinearSource.clickThrough;
        if (link) {
            window.open(link, `_blank`);
        }
        this.props.controller.nonLinearClick();
    }

    handleIconLoad(iconId)
    {
        this.props.controller.iconLoad(iconId);
    }

    handleIconClick(iconId)
    {
        this.props.controller.iconClick(iconId);
    }

    renderInvitation()
    {
        let isClickable = true;
        if (typeof this.state.player.adExtensions.isClickable !== `undefined` && parseInt(this.state.player.adExtensions.isClickable) === 0) {
            isClickable = false;
        }

        if (typeof this.state.player.adExtensions.startTime === `undefined` || !isClickable) {
            return null;
        }

        let invitationStartTime                = this.state.player.adExtensions.startTime / 1000;
        this.state.player.adExtensions.linkTxt = this.state.player.adExtensions.linkTxt || ``;

        if (invitationStartTime <= this.state.player.currentTime) {
            return (
                <div className="playernow-adv-invitation">
                    <a target='_blank' href={this.state.player.adWrapperLink}
                       dangerouslySetInnerHTML={{__html: this.state.player.adExtensions.linkTxt}}
                       onClick={(e) => this.handleInvitationClick(e)}/>
                </div>
            );
        }
        return null;
    }

    renderClose()
    {
        let controls = 1;
        if (this.state.player.adExtensions.controls) {
            controls = parseInt(this.state.player.adExtensions.controls);
        }

        if (!this.state.player.adExtensions.skipTime2 || !controls) {
            return null;
        }

        let skipTime = this.state.player.adExtensions.skipTime2 / 1000;

        if (skipTime <= this.state.player.currentTime) {
            return (
                <div className="playernow-adv-close">
                    <button className="playernow-adv-close-button" onClick={(e) => this.handleCloseButton(e)}>
                        <div className="playernow-adv-close-button-text">Close</div>
                    </button>
                </div>
            );
        }
    }

    renderPreSkip()
    {
        if (this.state.player.adSkipRemaining && this.state.player.adSkipRemaining > 0) {
            return (
                <div className="playernow-adv-pre-skip">
                    <div className="playernow-adv-pre-skip-text">
                        {this.props.messages.skipIn} {this.state.player.adSkipRemaining}
                    </div>
                </div>
            );
        }
        return null;
    }

    renderSkip()
    {
        if (this.state.player.adExtensions.skipTime) {
            return this.renderSkipFromExtension();
        }
        if (this.state.player.adSkipActive) {
            return (
                <div className="playernow-adv-skip">
                    <button className="playernow-adv-skip-button" onClick={(e) => this.handleSkipButton(e)}>
                        <div className="playernow-adv-skip-button-text">{this.props.messages.skipAd}</div>
                    </button>
                </div>
            );
        }
        return null;
    }

    renderSkipFromExtension()
    {
        let controls = 1;
        if (this.state.player.adExtensions.controls) {
            controls = parseInt(this.state.player.adExtensions.controls);
        }

        if (!controls) {
            return null;
        }

        let skipTime = this.state.player.adExtensions.skipTime / 1000;

        if (skipTime <= this.state.player.currentTime) {
            return (
                <div className="playernow-adv-skip">
                    <button className="playernow-adv-skip-button" onClick={(e) => this.handleSkipButton(e)}>
                        <div className="playernow-adv-skip-button-text">{this.props.messages.skipAd}</div>
                    </button>
                </div>
            );
        }
    }

    renderNonLinear()
    {
        if (this.state.player.adNonLinearSource) {
            let source = this.state.player.adNonLinearSource;

            if (source.staticResource) {
                return (
                    <div className="playernow-adv-non-linear"
                         style={{width: source.width, height: source.height}}>
                        <img src={source.staticResource}
                             onClick={() => this.handleNonLinearClick()}/>
                    </div>
                );
            }
            if (source.htmlResource) {
                return (
                    <div className="playernow-adv-non-linear"
                         style={{width: source.width, height: source.height}}
                         dangerouslySetInnerHTML={{__html: source.htmlResource}}/>
                );
            }
            if (source.iframeResource) {
                return (
                    <div className="playernow-adv-non-linear"
                         style={{width: source.width, height: source.height}}>
                        <iframe src={source.iframeResource} cellSpacing={0} style={{border: 0}} width="100%"
                                height="100%" frameBorder={0}/>
                    </div>
                );
            }
        }
        return null;
    }

    renderIcons()
    {
        let icons = this.state.player.adIcons;
        if (icons) {
            return Object.keys(icons).map((id) => {
                let icon = icons[id];
                if (!icon.show) {
                    return null;
                }

                if (icon.staticResource) {
                    let styles = {height: icon.height, width: icon.width};
                    if (icon.xPosition === `right`) {
                        styles.right = 0;
                    } else {
                        styles.left = parseInt(icon.xPosition) || 0;
                    }

                    if (icon.yPosition === `bottom`) {
                        styles.bottom = 0;
                    } else {
                        styles.top = parseInt(icon.xPosition) || 0;
                    }

                    return (
                        <a target="_blank" className="playernow-ad-icon" href={icon.clickThrough || `#`}
                           key={id}>
                            <img src={icon.staticResource}
                                 style={styles}
                                 onLoad={() => this.handleIconLoad(id)}
                                 onClick={() => this.handleIconClick(id)}/>
                        </a>
                    );
                }
            });
        }
    }

    render()
    {
        let link                       = this.state.player.adWrapperLink;
        this.state.player.adExtensions = this.state.player.adExtensions || {};

        let isClickable = true;
        if (typeof this.state.player.adExtensions.isClickable !== `undefined` && parseInt(this.state.player.adExtensions.isClickable) === 0) {
            isClickable = false;
        }

        return (
            <div className="playernow-adv-wrapper">
                {link && isClickable ? <a className="playernow-ad-click-through" target='_blank' href={link}
                                          onClick={(e) => this.handleClick(e)}/>
                    : ``}

                {this.renderInvitation()}
                {this.renderClose()}
                {this.renderPreSkip()}
                {this.renderSkip()}
                {this.renderNonLinear()}
                {this.renderIcons()}
            </div>
        );
    }
}

export default AdWrapper;