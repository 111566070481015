export default class VideoTrack
{
    #id;
    #height;
    #label;

    constructor(id, height, label)
    {
        this.#id     = id;
        this.#height = height;
        this.#label  = label;
    }

    get id()
    {
        return this.#id;
    }

    get height()
    {
        return this.#height;
    }

    get label()
    {
        return this.#label;
    }
}