import * as url from '../../../../utils/url';
import axios from 'axios';
import splitString from 'split-string';

export default class Playlist
{
    #url;
    #qualityUrl;
    #videoTracks     = [];
    #audioTracks     = [];
    #events          = [];
    #timeOffset      = 0;
    #duration        = 0;
    #lastSequenceNum = 0;

    constructor(url)
    {
        this.#url        = url;
        this.#qualityUrl = url;
    }

    get videoTracks()
    {
        return this.#videoTracks;
    }

    get audioTracks()
    {
        return this.#audioTracks;
    }

    get events()
    {
        return this.#events;
    }

    get timeOffset()
    {
        return this.#timeOffset;
    }

    get duration()
    {
        return this.#duration;
    }

    parse(url = this.#url)
    {
        return this.#loadPlaylist(url).then(this.parsePlaylist.bind(this));
    }

    #loadPlaylist(url)
    {
        return new Promise((resolve, reject) => {
            axios.request({
                url:     url,
                timeout: 5000,
            }).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    parseEvents(playlist)
    {
        const startDatePattern = /#EXT-X-PROGRAM-DATE-TIME:?(.*)/gmi;
        let dateMatch          = startDatePattern.exec(playlist);
        let currentDate        = null;
        if (dateMatch && !isNaN(Date.parse(dateMatch[1]))) {
            currentDate = Date.parse(dateMatch[1]);
        }

        let match       = playlist.match(/#EXT-X-MEDIA-SEQUENCE:?(.*)/gmi);
        let sequenceNum = 0;
        if (match) {
            sequenceNum = parseInt(match[0].split(`:`)[1]);
        }

        let prevDuration       = this.#duration;
        let seenChunksDuration = 0;

        this.#duration = 0.0;
        const extInf   = playlist.match(/#EXTINF:?(.*)/gmi);
        if (extInf) {
            this.#duration = extInf.reduce((result, el) => {
                let chunkDuration = (parseFloat(el.split(`:`)[1]) || 0);
                if (sequenceNum <= this.#lastSequenceNum) {
                    seenChunksDuration += chunkDuration;
                }
                sequenceNum += 1;
                return result + chunkDuration;
            }, 0.0);
        }

        this.#lastSequenceNum = sequenceNum - 1;
        this.#timeOffset += prevDuration - seenChunksDuration;

        let eventIds = {};
        this.#events.forEach(e => eventIds[e.id] = true);

        // currently parsing only SCTE35 events
        const pattern = /#EXT-X-DATERANGE:?(.*)/gmi;
        match         = pattern.exec(playlist);

        while (match) {
            let attrs  = match[1].trim().split(/,\s|,/);
            let event  = {};
            event.name = `scte35`;
            attrs.forEach(attribute => {
                if (attribute.match(/id=(.+)/i)) {
                    event[`id`] = attribute.match(/id=(.+)/i)[1].replace(/[”"]/g, ``);
                }
                if (attribute.match(/START-DATE=(.+)/i)) {
                    event[`startDate`] = Date.parse(attribute.match(/START-DATE=(.+)/i)[1].replace(/[”"]/g, ``));
                }
                if (attribute.match(/PLANNED-DURATION=(.+)/i)) {
                    event[`plannedDuration`] = attribute.match(/PLANNED-DURATION=(.+)/i)[1].replace(/[”"]/g, ``);
                }
                if (attribute.match(/DURATION=(.+)/i)) {
                    event[`duration`] = attribute.match(/DURATION=(.+)/i)[1].replace(/[”"]/g, ``);
                }
                if (attribute.match(/SCTE35-OUT=(.+)/i)) {
                    event[`SCTE35out`] = attribute.match(/SCTE35-OUT=(.+)/i)[1].replace(/[”"]/g, ``);
                }
                if (attribute.match(/SCTE35-IN=(.+)/i)) {
                    event[`SCTE35in`] = attribute.match(/SCTE35-IN=(.+)/i)[1].replace(/[”"]/g, ``);
                }
            });
            if (!eventIds[event.id] && event[`SCTE35out`]) {
                event.time = this.#timeOffset + (event.startDate - currentDate) / 1000;
                this.#events.push(event);
                eventIds[event.id] = true;
            }
            match = pattern.exec(playlist);
        }
    }

    parsePlaylist(content)
    {
        // Get the input as a string.  Normalize newlines to \n.
        let playlist = content.replace(/\\"/g, '').replace(/\r\n|\r(?=[^\n]|$)/gm, '\n').trim();

        if (!Playlist.#isMasterPlaylist(playlist)) {
            this.parseEvents(playlist);
            return;
        }

        this.#parseVideoTracks(playlist);
        this.#parseAudioTracks(playlist);
    }

    #parseVideoTracks(playlist)
    {
        let pattern      = /^#EXT-X-STREAM-INF:?(.*)[\n]{1,}(.*)$/gmi;
        let match        = pattern.exec(playlist);
        this.#qualityUrl = match[2];

        if (!Playlist.#isAbsoluteUrl(this.#qualityUrl)) {
            this.#qualityUrl = this.#buildAbsoluteUrl(this.#qualityUrl);
        }

        this.#loadPlaylist(this.#qualityUrl).then(this.parseEvents.bind(this));

        let index = 0;
        while (match) {
            let meta = this.#parseAttributes(match[1]);

            meta.line        = match[0];
            meta.originalUrl = match[2];
            const resolution = meta.resolution.split(`x`);
            meta.id          = index++;
            meta.width       = parseInt(resolution[0]);
            meta.height      = parseInt(resolution[1]);
            meta.bandwidth   = parseInt(meta.bandwidth);
            meta.url         = this.#buildAbsoluteUrl(match[2]);

            this.#videoTracks.push(meta);
            match = pattern.exec(playlist);
        }
    }

    #parseAudioTracks(playlist)
    {
        let pattern = /^#EXT-X-MEDIA:TYPE=AUDIO(.*)[\n]?$/gmi;
        let match   = pattern.exec(playlist);

        let index = 0;
        while (match) {
            let meta = this.#parseAttributes(match[1]);

            meta.line        = match[0];
            meta.id          = index++;
            meta.originalUrl = meta.uri;
            meta.url         = this.#buildAbsoluteUrl(meta.uri);

            this.#audioTracks.push(meta);
            match = pattern.exec(playlist);
        }
    }

    #parseAttributes(line)
    {
        let meta    = {};
        let headers = splitString(line, {
            separator: `,`,
            quotes:    [`"`],
        });
        headers.map((h) => {
            let parts = Playlist.splitAttribute(h);
            if (parts.length !== 2) {
                return;
            }

            let key   = parts[0].toLowerCase();
            meta[key] = parts[1].replace(/"/g, '');
        });

        return meta;
    }

    static splitAttribute(line)
    {
        // URI="stream_5.m3u8?md5=8wfRTC5UVClPVLc6QZss9Q&expires=1606330722"
        const index = line.indexOf(`=`);
        if (index === -1) {
            return [line];
        }
        return [line.slice(0, index), line.slice(index + 1)];
    }

    buildMasterPlaylistWithMedia(trackId)
    {
        let quality = this.#videoTracks.find(q => q.id === trackId);
        if (!quality) {
            return '';
        }

        let playlist = '#EXTM3U\n\n';
        this.audioTracks.map(audioTrack => {
            playlist += audioTrack.line.replace(audioTrack.originalUrl, audioTrack.url) + '\n';
        });

        playlist += quality.line.replace(quality.originalUrl, quality.url) + '\n';

        return playlist;
    }

    static #isMasterPlaylist(playlist)
    {
        return /^#EXT-X-STREAM-INF:?(.*)[\n\r]{1,}(.*)$/gmi.test(playlist);
    }

    static #isAbsoluteUrl(url)
    {
        return /^https?:\/\//gmi.test(url);
    }

    #buildAbsoluteUrl(path)
    {
        if (url.isAbsolute(path)) {
            return path;
        }

        return url.join(this.#url, path);
    }

    updateTimes()
    {
        this.#loadPlaylist(this.#qualityUrl)
            .then(this.parseEvents.bind(this));
    }
}
