export const availableTypes = {
    flash: `application/x-shockwave-flash`,
    html5: `application/javascript`,
};

export const getType = (mimeType) => {
    switch (mimeType) {
        case availableTypes.flash:
            return `flash`;
        case availableTypes.html5:
            return `html5`;
        default:
            return null;
    }
};

export const isVPAID = (element) => {
    return element.apiFramework.toLowerCase() === `vpaid`;
};

