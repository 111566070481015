import axios from 'axios';
import * as err from '../../../../utils/err';

const FAIRPLAY_KEY_SYSTEM = `com.apple.fps.1_0`;

function stringToUint16Array(string)
{
    let buffer = new ArrayBuffer(string.length * 2);
    let array  = new Uint16Array(buffer);

    for (let i = 0; i < string.length; i++) {
        array[i] = string.charCodeAt(i);
    }
    return array;
}

function base64DecodeUint8Array(input)
{
    let raw       = window.atob(input);
    let rawLength = raw.length;
    let array     = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }

    return array;
}

function base64EncodeUint8Array(input)
{
    let keyStr = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=`;
    let output = ``;
    let chr1,
        chr2,
        chr3,
        enc1,
        enc2,
        enc3,
        enc4;
    let i      = 0;

    while (i < input.length) {
        chr1 = input[i++];
        chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
        chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;

        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
            keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }
    return output;
}

export default class Fairplay
{
    #video;
    #options;
    #contentId;
    #logger;
    #eventBus;
    #certificate;
    #needKeyEvents        = [];
    #lastNeedKeyEventTime = 0;

    constructor(video, options, contentId, logger, eventBus)
    {
        this.#video     = video;
        this.#options   = options;
        this.#contentId = contentId;
        this.#logger    = logger;
        this.#eventBus  = eventBus;
    }

    init()
    {
        return new Promise((resolve, reject) => {
            let fairplayOptions = this.#options.keySystems[FAIRPLAY_KEY_SYSTEM];
            return this.getCertificate(fairplayOptions)
                .then((cert) => {
                    this.#certificate = cert;
                    this.#video.addEventListener(`webkitneedkey`, this.handleNeedKeyEventWrapper.bind(this), false);
                    resolve();
                })
                .catch((error) => {
                    this.#logger.error('[Fairplay]', error);
                    this.#eventBus.emit(`playback.error`, {type: err.list.failedLoadFairplayCert, url: fairplayOptions.certificateUrl, data: error});
                    reject(error);
                });
        });
    }

    handleNeedKeyEventWrapper(e)
    {
        let timestamp = (new Date().getTime());//msec
        this.#needKeyEvents.push(e);
        if (timestamp - this.#lastNeedKeyEventTime > 200) {
            setTimeout(() => {
                this.handleNeedKeyEvent(this.#needKeyEvents[this.#needKeyEvents.length - 1]);
            }, 200);
        }

        this.#lastNeedKeyEventTime = timestamp;
    }

    handleNeedKeyEvent(e)
    {
        let contentId = this.#extractContentId(e.initData);

        let fairplayOptions = this.#options.keySystems[FAIRPLAY_KEY_SYSTEM];
        this.addKey({
            cert:       this.#certificate,
            initData:   e.initData,
            contentId:  contentId,
            licenseUri: fairplayOptions.serverURL,
        }).catch(error => {
            this.#logger.error('[Fairplay]', error);
        });
    }

    #extractContentId(initData)
    {
        const str8  = String.fromCharCode.apply(null, initData);
        const str16 = String.fromCharCode.apply(null, new Uint16Array(initData.buffer)).substring(2);

        const str = str16.indexOf('skd://') === 0 ? str16 : str8;
        return new URL(str).hostname;
    }

    getCertificate(fairplayOptions)
    {
        this.#logger.debug('[Fairplay] Cert downloading...');

        return axios.get(fairplayOptions.certificateUrl, {
            responseType: `arraybuffer`,
            headers:      {
                'Pragma':        `Cache-Control: no-cache`,
                'Cache-Control': `max-age=0`,
            },
        }).then(res => {
            this.#logger.debug('[Fairplay] Cert downloaded');
            return new Uint8Array(res.data);
        });
    }

    addKey({contentId, initData, cert, licenseUri})
    {
        this.#logger.debug('[Fairplay] Key adding...', {contentId, initData, licenseUri});

        return new Promise((resolve, reject) => {
            if (!this.#video.webkitKeys) {
                this.#video.webkitSetMediaKeys(new window.WebKitMediaKeys(FAIRPLAY_KEY_SYSTEM));
            }

            if (!this.#video.webkitKeys) {
                reject(`Could not create MediaKeys`);
                return;
            }

            let keySession = this.#video.webkitKeys.createSession(
                `video/mp4`,
                this.concatInitDataIdAndCertificate({id: contentId, initData, cert}),
            );

            if (!keySession) {
                reject(`Could not create key session`);
                return;
            }
            keySession.contentId = contentId;

            keySession.addEventListener(`webkitkeymessage`, (e) => {
                this.getLicense(licenseUri, e)
                    .then((license) => {

                        if (!license.data.ckc_message) {
                            this.#logger.error(`invalid fairplay response`, license);
                            reject(`invalid fairplay response`);
                            return;
                        }

                        let keyText = license.data.ckc_message;
                        let key     = base64DecodeUint8Array(keyText);
                        return keySession.update(key);
                    })
                    .catch(reject);
            });

            keySession.addEventListener(`webkitkeyadded`, (e) => {
                this.#logger.debug('[Fairplay] Key added');
                this.#eventBus.emit('drm.webkitkeyadded');
                resolve(e);
            });

            // for testing purposes, adding webkitkeyerror must be the last item in this method
            keySession.addEventListener(`webkitkeyerror`, (e) => {
                this.#eventBus.emit('drm.webkitkeyerror');
                reject(e);
            });
        });
    }

    getLicense(licenseUri, event)
    {
        let session = event.target;
        let message = event.message;
        return axios.post(licenseUri, JSON.stringify({
            content_id:  encodeURIComponent(session.contentId),
            spc_message: base64EncodeUint8Array(message),
        }), {
            responseType: `json`,
            headers:      {'Content-type': `application/json`},
        });
    }

    concatInitDataIdAndCertificate({initData, id, cert})
    {
        if (typeof id === `string`) {
            id = stringToUint16Array(id);
        }

        let offset        = 0;
        let buffer        = new ArrayBuffer(
            initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength);
        let dataView      = new DataView(buffer);
        let initDataArray = new Uint8Array(buffer, offset, initData.byteLength);

        initDataArray.set(initData);
        offset += initData.byteLength;

        dataView.setUint32(offset, id.byteLength, true);
        offset += 4;

        let idArray = new Uint16Array(buffer, offset, id.length);

        idArray.set(id);
        offset += idArray.byteLength;

        dataView.setUint32(offset, cert.byteLength, true);
        offset += 4;

        let certArray = new Uint8Array(buffer, offset, cert.byteLength);

        certArray.set(cert);

        return new Uint8Array(buffer, 0, buffer.byteLength);
    }
}
