import VastResponse from '../vast/VastResponse';
import VastTracker from '../vast/VastTracker';
import * as VastUtils from '../utils/vastUtils';
import VPAIDTech from '../vpaid/VPAIDTech';
import {parsers} from '../vast/parsers';

class VPAIDIntegrator
{
    constructor(eventBus, playerState, playerController, type, logger)
    {
        this.eventBus         = eventBus;
        this.playerState      = playerState;
        this.playerController = playerController;
        this.type             = type.toLowerCase();
        this.logger           = logger;

        this.viewMode = {
            normal:     `normal`,
            fullscreen: `fullscreen`,
            thumbnail:  `thumbnail`,
        };

        this.vpaidElement = this._createVpaidWrap();

        this.defaults = {
            responseTimeout: 5000,
            VPAID_VERSION:   `2.0`,
        };
    }

    _createVpaidWrap()
    {
        const playerRoot = this.playerController.root;
        let vpaidWrap    = document.createElement(`div`);
        vpaidWrap.classList.add(`playernow-vpaid-wrap`);
        let advWrapper = playerRoot.querySelector(`.playernow-adv-wrapper`);
        advWrapper.appendChild(vpaidWrap);

        return vpaidWrap;
    }

    createController()
    {
        this.playerController.adv = this.playerController.adv || {};
        this.playerController.adv = Object.assign(this.playerController.adv, {
            skip:                   () => {
                this.adUnit.skipAd();
                this.eventBus.emit(`vast.adSkip`);
            },
            close:                  () => {
                this.tracker.trackClose();
                this.eventBus.emit(`vast.adClose`);
            },
            closeLinear:            () => {
                this.tracker.trackCloseLinear();
                this.eventBus.emit(`vast.adCloseLinear`);
            },
            acceptInvitation:       (closeAct) => {
                this.handleClickThru();
                this.tracker.trackAcceptInvitation();
                if (closeAct === `pause` || !closeAct) {
                    this.playerController.pause();
                }
                if (closeAct === `yes`) {
                    this.eventBus.emit(`vast.closeAct`);
                }
            },
            acceptInvitationLinear: () => {
                this.tracker.trackAcceptInvitationLinear();
            },
        });

        this.playerController.play = () => {
            this.adUnit.resumeAd();
        };

        this.playerController.pause = () => {
            this.adUnit.pauseAd();
        };
    }

    revertController()
    {
        delete this.playerController.adv;
        delete this.playerController.pause;
        delete this.playerController.play;
    }

    extensionsReducer(arr)
    {
        return arr.reduce((previous, current) => {
            Object.keys(current).forEach(value => {
                if (value !== `type`) {
                    if (typeof current[value] === `string`) {
                        current[value] = current[value].trim();
                    }

                    if (current.type === `startTime` || current.type === `skipTime` || current.type === `skipTime2`) {
                        current[value] = parsers.duration(current[value]);
                    }

                    previous[current.type] = current[value];
                }
            });
            return previous;
        }, {});
    }

    setupExtensions(extensions)
    {
        this.extensions = this.extensionsReducer(extensions);
        if (this.extensions.CustomTracking) {
            this.setupCustomTracking();
        }
        this.playerState.set(`adExtensions`, this.extensions);
    }

    setupCustomTracking()
    {
        if (Array.isArray(this.extensions.CustomTracking)) {
            let onVastLoadArray = [];
            this.extensions.CustomTracking.forEach(elem => {
                if (elem.event && elem.text && elem.event === `onVastLoad`) {
                    onVastLoadArray.push(elem.text);
                }
            });
            VastTracker.trackCustomEvent(onVastLoadArray);
        }
    }

    addAdCountdown(response)
    {
        if (typeof response.linear.duration !== `number`) {
            return;
        }

        this.adDuration = response.linear.duration / 1000;
        this.eventBus.on(`playback.currentTimeChange`, this.updateAdCountdown, this);
        this.eventBus.onceOf([
            `vast.adSkip`,
            `vast.adClose`,
            `vast.adEnd`,
            `vast.adCancel`,
            `vpaid.adEnd`,
        ], this.removeAdCountdown, this);
    }

    updateAdCountdown()
    {
        let adCountdown = Math.ceil(this.adDuration - this.playerState.get(`currentTime`));
        if (adCountdown >= 0) {
            this.playerState.set(`adCountdown`, adCountdown);
        }
    }

    removeAdCountdown()
    {
        this.eventBus.off(`playback.currentTimeChange`, this.updateAdCountdown, this);
        this.playerState.set(`adCountdown`, null);
    }

    start(vastResponse)
    {
        if (!(vastResponse instanceof VastResponse)) {
            this.handleError(`on VASTIntegrator.playAd, missing required VASTResponse`);
        }

        this.vastResponse = vastResponse;
        this.setupExtensions(this.vastResponse.extensions);
        this.addAdCountdown(this.vastResponse);
        this.tech    = new VPAIDTech(this.type, this.vastResponse, this.logger);
        this.tracker = this._createTracker(this.tech);

        return this._loadAdUnit(this.tech)
            .then(adUnit => {
                this.adUnit = adUnit;
                this.createController();
                return this._playAdUnit();
            });
    }

    _createTracker(tech)
    {
        // TODO: NonLinear Ad
        if (tech.type === `linear`) {
            let trackingInfo = {
                src:            tech.mediaFile.src,
                impression:     this.vastResponse.impression,
                errorURLMacros: this.vastResponse.errorURLMacros,
                trackingEvents: this.vastResponse.linear.trackingEvents,
                clickTrackings: this.vastResponse.linear.clickTrackings,
                duration:       this.vastResponse.linear.duration,
            };
            return new VastTracker(trackingInfo, this.logger);
        }
    }

    _loadAdUnit(tech)
    {
        return tech.loadAdUnit(this.vpaidElement, this.playerController.video)
            .catch(e => this.handleError(e));
    }

    _playAdUnit()
    {
        this.logger.log(this.adUnit, this.vastResponse);

        let majorVersion = parseInt(this.adUnit.handshake());

        // check if major version is not supported
        if (majorVersion !== parseInt(this.defaults.VPAID_VERSION)) {
            this.handleError(`[Error] ad VPAID version is not supported`);
            return;
        }

        let styles = window.getComputedStyle(this.playerController.video);

        const mode = this.playerState.get(`fullscreen`)
            ? this.viewMode.fullscreen
            : this.viewMode.normal;

        return this.adUnit.initAd(styles.width, styles.height, mode, -1, {AdParameters: this.vastResponse.linear.adParameters || ``})
            .then(() => {
                this.setEvents();
                return this._startAd();
            });
    }

    handleClickThru(data = [])
    {
        if (this.playerState.get(`adExtensions`).isClickable && this.playerState.get(`adExtensions`).isClickable === `0`) {
            return;
        }

        let url = data[0];
        // let metric = data[1];
        // let playerHandles = data[2];

        let generateClickThroughURL = (clickThroughMacro) => {
            var variables = {
                ASSETURI:        this.adUnit.options.src,
                CONTENTPLAYHEAD: 0, //In VPAID there is no method to know the current time from the adUnit
            };

            return clickThroughMacro ? VastUtils.parseURLMacro(clickThroughMacro, variables) : null;
        };

        let clickThruUrl = isNotEmptyString(url)
            ? url
            : generateClickThroughURL(this.vastResponse.linear.clickThrough);

        function isNotEmptyString(str)
        {
            return typeof str === `string` && str.length !== 0;
        }

        // todo: playerHandles
        if (clickThruUrl) {
            window.open(clickThruUrl, `_blank`);
        }

        if (this.extensions.closeAct === `pause` || !this.extensions.closeAct) {
            this.playerController.pause();
        }
        if (this.extensions.closeAct === `yes`) {
            this.eventBus.emit(`vast.closeAct`);
        }
    }

    setEvents()
    {
        this.logger.log(this.adUnit);
        this.previouslyMuted = this.playerState.get(`isMuted`);

        this.adUnit.on(`AdError`, (err) => {
            this.logger.log(`[AdEvent] AdError`, err);
            this.tracker.trackError(901, err);
        });

        this.playerController.video.volume = this.adUnit.getAdVolume();

        this.adUnit.on(`AdStarted`, () => {
            this.tracker.trackCreativeView();
            this.eventBus.emit(`vast.adStart`);
            this.logger.log(`[AdEvent] AdStarted`);
            // notifyPlayToPlayer();
        });

        this.adUnit.on(`AdStopped`, () => {
            this.logger.log(`[AdEvent] AdStopped`);
            this.eventBus.emit(`vpaid.adEnd`);
        });

        this.adUnit.on(`AdSkipped`, () => {
            this.tracker.trackSkip();
            this.eventBus.emit(`vast.adSkip`);
            this.logger.log(`[AdEvent] AdSkipped`);
        });

        this.adUnit.on(`AdSkippableStateChange`, () => {
            // this.tracker.trackSkip();
            this.logger.log(`[AdEvent] AdSkippableStateChange`);

            this.playerState.set(`adSkipActive`, this.adUnit.getAdSkippableState());
        });

        this.adUnit.on(`AdLoaded`, () => {
            this.logger.log(`[AdEvent] AdLoaded`);
        });

        this.adUnit.on(`AdImpression`, () => {
            this.logger.log(`[AdEvent] AdImpression`);
        });

        this.adUnit.on(`AdVideoStart`, () => {
            this.logger.log(`[AdEvent] AdVideoStart`);
            this.tracker.trackStart();
            this.eventBus.emit(`playback.metadata`, {duration: this.adUnit.getAdDuration()});
        });

        this.adUnit.on(`AdVideoFirstQuartile`, () => {
            this.logger.log(`[AdEvent] AdVideoFirstQuartile`);
            this.tracker.trackEvent(`firstQuartile`, true);
        });

        this.adUnit.on(`AdVideoMidpoint`, () => {
            this.logger.log(`[AdEvent] AdVideoMidpoint`);
            this.tracker.trackEvent(`midpoint`, true);
        });

        this.adUnit.on(`AdVideoThirdQuartile`, () => {
            this.logger.log(`[AdEvent] AdVideoThirdQuartile`);
            this.tracker.trackEvent(`thirdQuartile`, true);
        });

        this.adUnit.on(`AdVideoComplete`, () => {
            this.logger.log(`[AdEvent] AdVideoCompleted`);
            this.tracker.trackComplete();
        });

        this.adUnit.on(`AdPlaying`, () => {
            this.logger.log(`[AdEvent] AdPlaying`);
            this.tracker.trackResume();
        });

        this.adUnit.on(`AdPaused`, () => {
            this.logger.log(`[AdEvent] AdPaused`);
            this.tracker.trackPause();
        });

        this.adUnit.on(`AdSizeChange`, () => {
            this.logger.log(`[AdEvent] AdSizeChange`);
        });

        this.adUnit.on(`AdLog`, (log) => {
            this.logger.log(`[AdEvent] AdLog`, log);
        });

        this.adUnit.on(`AdClickThru`, (...data) => {
            this.handleClickThru(data);
            this.logger.log(`[AdEvent] AdClickThru`, data);
            this.tracker.trackClick();
        });

        this.adUnit.on(`AdInteraction`, (...args) => {
            this.logger.log(`[AdEvent] AdInteraction`, args);
        });

        this.adUnit.on(`AdUserAcceptInvitation`, (...args) => {
            this.logger.log(`[AdEvent] AdUserAcceptInvitation`, args);
            this.tracker.trackAcceptInvitation();

            if (this.adUnit.options.type === `linear`) {
                this.tracker.trackAcceptInvitationLinear();
            }
        });

        this.adUnit.on(`AdUserClose`, (...args) => {
            this.logger.log(`[AdEvent] AdUserClose`, args);
            this.tracker.trackClose();

            if (this.adUnit.options.type === `linear`) {
                this.tracker.trackCloseLinear();
            }
        });

        this.adUnit.on(`AdUserMinimize`, () => {
            this.logger.log(`[AdEvent] AdUserMinimize`);
            this.tracker.trackCollapse();
        });

        this.adUnit.on(`AdExpandedChange`, (...args) => {
            this.logger.log(`[AdEvent] AdExpandedChange`, args);
            this.tracker.trackExpand();
        });

        this.adUnit.on(`AdLinearChange`, (...args) => {
            this.logger.log(`[AdEvent] AdLinearChange`, args);
        });

        this.adUnit.on(`AdDurationChange`, () => {
            this.logger.log(`[AdEvent] AdDurationChange`, this.adUnit.getAdDuration());
            this.eventBus.emit(`playback.metadata`, {duration: this.adUnit.getAdDuration()});
        });

        this.adUnit.on(`AdVolumeChange`, () => {
            this.logger.log(`[AdEvent] AdVolumeChange`, this.adUnit.getAdVolume());
            this.playerController.setMute(this.adUnit.getAdVolume() === 0);
        });

        let updateViewSize          = resizeAd.bind(this);
        let updateViewSizeThrottled = throttle(updateViewSize, 100);

        window.addEventListener(`resize`, updateViewSizeThrottled);
        window.addEventListener(`orientationchange`, updateViewSizeThrottled);

        this.eventBus.onceOf([`vpaid.adEnd`, `vast.adCancel`, `vast.adClose`], () => {
            window.removeEventListener(`resize`, updateViewSizeThrottled);
            window.removeEventListener(`orientationchange`, updateViewSizeThrottled);

            this.eventBus.off(`playback.volumechange`, updateAdUnitVolume, this);
            this.eventBus.off(`playback.pause`, AdUnitPause, this);
            this.eventBus.off(`playback.play`, AdUnitPlay, this);
            this.eventBus.off(`player.fullscreenchange`, fullscreenChanged, this);
        });

        this.eventBus.on(`playback.volumechange`, updateAdUnitVolume, this);
        this.eventBus.on(`playback.pause`, AdUnitPause, this);
        this.eventBus.on(`playback.play`, AdUnitPlay, this);
        this.eventBus.on(`player.fullscreenchange`, fullscreenChanged, this);

        function fullscreenChanged(e)
        {
            updateViewSize();

            if (e.fullscreen) {
                this.tracker.trackFullscreen();
            } else {
                this.tracker.trackExitFullscreen();
            }
        }

        function resizeAd()
        {
            let styles = window.getComputedStyle(this.playerController.video);

            // TODO: thumbnail view mode
            const mode = this.playerState.get(`fullscreen`)
                ? this.viewMode.fullscreen
                : this.viewMode.normal;

            this.adUnit.resizeAd(styles.width, styles.height, mode);
        }

        function updateAdUnitVolume()
        {
            let muted = this.playerState.get(`isMuted`);
            if (muted !== this.previouslyMuted) {
                if (muted) {
                    this.tracker.trackMute();
                } else {
                    this.tracker.trackUnmute();
                }
                this.previouslyMuted = muted;
            }

            let volume = muted ? 0 : this.playerState.get(`volume`);
            if (volume !== this.adUnit.getAdVolume()) {
                this.adUnit.setAdVolume(volume);
            }
        }

        function AdUnitPause()
        {
            this.logger.log(`[playback].pause`);
            this.adUnit.pauseAd();
        }

        function AdUnitPlay()
        {
            this.logger.log(`[playback].play`);
            this.adUnit.resumeAd();
        }

        function throttle(callback, delay)
        {
            let prev = new Date().getTime() - (delay + 1);
            return function() {
                let time = new Date().getTime();
                if ((time - prev) >= delay) {
                    prev = time;
                    callback.apply(this, arguments);
                }
            };
        }
    }

    _startAd()
    {
        this.adUnit.startAd();
        return new Promise(resolve => {
            this.eventBus.onceOf([`vpaid.adEnd`, `vast.adCancel`, `vast.closeAct`], (e) => {
                this.eventBus.emit(`vast.adEnd`);
                this.revertController();
                this.playerState.set(`adExtensions`, null);
                this.playerState.set(`adDuration`, null);
                this.playerState.set(`adSkipActive`, null);

                let res = {};
                if (e.event === `vast.closeAct`) {
                    res.pausePlayback = true;
                }
                resolve(res);
            });
        });
    }

    handleError(err)
    {
        this.logger.error(err);
    }
}

export default VPAIDIntegrator;