export default class AudioTrack
{
    #id;
    #label;
    #language;

    constructor(id, language, label)
    {
        this.#id       = id;
        this.#language = language;
        this.#label    = label;
    }

    get id()
    {
        return this.#id;
    }

    get language()
    {
        return this.#language;
    }

    get label()
    {
        return this.#label;
    }
}