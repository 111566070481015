class Iframe
{

    getTemplate(data)
    {
        return `<!DOCTYPE html>
    <html lang="en">
    <head><meta charset="UTF-8"></head>
    <body style="margin:0;padding:0"><div class="ad-element"></div>
    <script type="text/javascript" src="${data.src}"></script>
    <script type="text/javascript">
      window.parent.postMessage({"id": ${data.id}}, '${data.origin}');
    </script>
    </body>
    </html>`;
    }

    createIframe(data, parent)
    {
        let iframe = document.createElement(`iframe`);
        iframe.src = `about:blank`;
        this.setDefaultStyles(iframe);

        parent.innerHTML = ``;
        parent.appendChild(iframe);

        let iframeDocument = iframe.contentWindow.document;
        iframeDocument.write(this.getTemplate(data));

        return iframe;
    }

    setDefaultStyles(element)
    {
        if (element) {
            element.width          = `100%`;
            element.height         = `100%`;
            element.marginWidth    = `0`;
            element.marginHeight   = `0`;
            element.frameBorder    = `0`;
            element.style.position = `absolute`;
            element.style.left     = `0`;
            element.style.top      = `0`;
            element.style.margin   = `0px`;
            element.style.padding  = `0px`;
            element.style.border   = `none`;
            element.style.width    = `100%`;
            element.style.height   = `100%`;
            element.setAttribute(`scrolling`, `no`);
        }
    }
}

export default Iframe;