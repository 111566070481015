import playerStorage from '../modules/storage/player-storage';
import * as err from '../utils/err';

export default class PlayerModel
{
    constructor(root, video, eventBus, playerState, logger)
    {
        this.root        = root;
        this.video       = video;
        this.eventBus    = eventBus;
        this.playerState = playerState;
        this.logger      = logger;
        this.bindEvents();
    }

    bindEvents()
    {
        this.eventBus.on(`playback.play`, this.handlePlay, this);
        this.eventBus.on(`playback.pause`, this.handlePause, this);
        this.eventBus.on(`playback.volumechange`, this.handleVolumeChange, this);
        this.eventBus.on(`playback.videoTracks`, this.handleVideoTracks, this);
        this.eventBus.on(`playback.audioTracks`, this.handleAudioTracks, this);
        this.eventBus.on(`playback.activeAudioTrackId`, this.handleActiveAudioTrackId, this);
        this.eventBus.on(`playback.qualityChangeRendered`, this.handleQualityChange, this);
        this.eventBus.on(`playback.metadata`, this.handleMetadata, this);
        this.eventBus.on(`playback.currentTimeChange`, this.handleCurrentTimeChange, this);
        this.eventBus.on(`playback.progress`, this.handleProgress, this);
        this.eventBus.on(`playback.ended`, this.handleEnded, this);
        this.eventBus.on(`playback.webkitbeginfullscreen`, this.enableFullscreen, this);
        this.eventBus.on(`playback.webkitendfullscreen`, this.disableFullscreen, this);
        this.eventBus.on(`playback.error`, this.handleError, this);
        this.eventBus.on(`playback.waiting`, this.enableLoading, this);
        this.eventBus.on(`playback.canplay`, this.disableLoading, this);
        this.eventBus.on(`playback.playing`, this.disableLoading, this);

        this.handleFullscreenChange = this.handleFullscreenChange.bind(this);
        document.addEventListener(`webkitfullscreenchange`, this.handleFullscreenChange);
        document.addEventListener(`mozfullscreenchange`, this.handleFullscreenChange);
        document.addEventListener(`MSFullscreenChange`, this.handleFullscreenChange);
        document.addEventListener(`fullscreenchange`, this.handleFullscreenChange);
    }

    unbindEvents()
    {
        this.eventBus.off(`playback.play`, this.handlePlay, this);
        this.eventBus.off(`playback.pause`, this.handlePause, this);
        this.eventBus.off(`playback.volumechange`, this.handleVolumeChange, this);
        this.eventBus.off(`playback.videoTracks`, this.handleVideoTracks, this);
        this.eventBus.off(`playback.audioTracks`, this.handleAudioTracks, this);
        this.eventBus.off(`playback.activeAudioTrackId`, this.handleActiveAudioTrackId, this);
        this.eventBus.off(`playback.qualityChangeRendered`, this.handleQualityChange, this);
        this.eventBus.off(`playback.metadata`, this.handleMetadata, this);
        this.eventBus.off(`playback.currentTimeChange`, this.handleCurrentTimeChange, this);
        this.eventBus.off(`playback.progress`, this.handleProgress, this);
        this.eventBus.off(`playback.webkitbeginfullscreen`, this.enableFullscreen, this);
        this.eventBus.off(`playback.webkitendfullscreen`, this.disableFullscreen, this);
        this.eventBus.off(`playback.error`, this.handleError, this);
        this.eventBus.off(`playback.waiting`, this.enableLoading, this);
        this.eventBus.off(`playback.canplay`, this.disableLoading, this);
        this.eventBus.off(`playback.playing`, this.disableLoading, this);

        document.removeEventListener(`webkitfullscreenchange`, this.handleFullscreenChange);
        document.removeEventListener(`mozfullscreenchange`, this.handleFullscreenChange);
        document.removeEventListener(`MSFullscreenChange`, this.handleFullscreenChange);
        document.removeEventListener(`fullscreenchange`, this.handleFullscreenChange);
    }

    enableLoading()
    {
        this.playerState.set(`loading`, true);
    }

    disableLoading()
    {
        this.playerState.set(`loading`, false);
    }

    handleError(e)
    {
        switch (true) {
            case e.target:
                // setTimeout to check the error was real (error fake triggering in DASH with DRM)
                setTimeout(() => {
                    if (e.target.error && this.video.error && !this.playerState.get(`adLoading`)) {
                        this.playerState.add(`errors`, {type: err.list.videoElementError, code: e.target.error.code});
                        this.logger.error(e);
                    }
                }, 3000);
                break;
            case e.error:
            case e.type && e.type === err.list.videoElementError:
                setTimeout(() => {
                    if (this.video.error && !this.playerState.get(`adLoading`)) {
                        const code = e.code || this.video.error.code;
                        this.playerState.add(`errors`, {type: err.list.videoElementError, code: code});
                        this.logger.error(e);
                    }
                }, 3000);
                break;
            case e.type && e.type === err.list.dashLibraryError:
            case e.type && e.type === err.list.hlsLibraryError:
                this.playerState.add(`errors`, {type: e.type, code: e.data.code});
                this.logger.error(e);
                break;
            case e.type && e.type === err.list.failedLoadFairplayCert:
                this.playerState.add(`errors`, {type: e.type, url: e.url});
                this.logger.error(e);
                break;
            default:
                this.logger.error(e);
        }
    }

    handlePlay()
    {
        this.playerState.set(`playing`, true);
        this.playerState.set(`volume`, this.video.volume);
    }

    handlePause()
    {
        this.playerState.set(`playing`, false);
    }

    handleVolumeChange()
    {
        this.playerState.set(`volume`, this.video.volume);
    }

    setMute(value)
    {
        this.playerState.set(`isMuted`, value);
    }

    handleQualityChange(e)
    {
        this.playerState.set(`bitrate`, e.bitrate);
    }

    handleVideoTracks(e)
    {
        this.playerState.set(`videoTracks`, e.videoTracks);
    }

    handleAudioTracks(e)
    {
        this.playerState.set(`audioTracks`, e.audioTracks);
    }

    handleActiveAudioTrackId(e)
    {
        this.playerState.set(`activeAudioTrackId`, e.activeAudioTrackId);
    }

    handleMetadata(e)
    {
        this.playerState.set(`isStream`, e.duration === Infinity || e.duration > 1e10);
        if (e.duration < 0) {
            this.playerState.set(`duration`, null);
        } else {
            this.playerState.set(`duration`, e.duration);
        }
    }

    handleCurrentTimeChange()
    {
        // if ad is playing, get snapshotCurrentTime
        let timeToStorage = this.playerState.get(`snapshotCurrentTime`) || this.playerState.get(`currentTime`) || 0;

        if (timeToStorage > 0) {
            playerStorage.set(`currentTime`, timeToStorage);
        }
        this.playerState.set(`currentTime`, this.video.currentTime);
    }

    handleProgress()
    {
        const {from, to, duration} = this.getBuffered();
        this.playerState.set(`bufferedFrom`, from);
        this.playerState.set(`bufferedTo`, to);
        this.playerState.set(`bufferedDuration`, duration);

        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
        if (this.video.readyState === 4) {
            this.playerState.set(`loading`, false);
        }

        if (this.playerState.get(`playing`) && to > 0) {
            this.playerState.set(`errors`, []);
        }
    }

    handleEnded()
    {
        playerStorage.set(`currentTime`, 0);
    }

    getBuffered()
    {
        let buf       = this.video.buffered;
        let bufLength = buf.length;

        while (bufLength--) {
            if (this.playerState.get(`currentTime`) >= buf.start(bufLength) && this.playerState.get(`currentTime`) <= buf.end(bufLength)) {
                return {
                    from:     buf.start(bufLength),
                    to:       buf.end(bufLength),
                    duration: buf.end(bufLength) - buf.start(bufLength),
                };
            }
        }

        return {from: 0, to: 0, duration: 0};
    }

    handleFullscreenChange()
    {
        const isFullscreen = !!(document.fullscreenElement || document.msFullscreenElement || document.webkitFullscreenElement || document.webkitIsFullScreen
            || document.webkitDisplayingFullscreen || document.mozFullScreenElement);
        this.setFullscreen(isFullscreen);
        if (isFullscreen) {
            this.eventBus.emit(`playback.beginfullscreen`);
        } else {
            this.eventBus.emit(`playback.endfullscreen`);
        }
    }

    // iOS fullscreenchange handlers
    enableFullscreen()
    {
        this.setFullscreen(true);
    }

    disableFullscreen()
    {
        this.setFullscreen(false);
    }

    setFullscreen(enter)
    {
        if (this.playerState.get(`fullscreen`) === enter) {
            return;
        }

        if (enter) {
            this.#requestFullScreen();
        } else {
            this.#exitFullScreen();
        }

        this.eventBus.emit(`player.fullscreenchange`, {fullscreen: enter});
        this.playerState.set(`fullscreen`, enter);
    }

    #exitFullScreen()
    {
        if (!this.#isFullScreen()) {
            return;
        }

        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (this.video.webkitExitFullscreen) {
            this.video.webkitExitFullscreen();
        }
    }

    #requestFullScreen()
    {
        if (this.root.requestFullscreen) {
            this.root.requestFullscreen();
        } else if (this.root.msRequestFullscreen) {
            this.root.msRequestFullscreen();
        } else if (this.root.mozRequestFullScreen) {
            this.root.mozRequestFullScreen();
        } else if (this.root.webkitRequestFullscreen) {
            this.root.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (this.video.webkitEnterFullscreen) {
            this.video.webkitEnterFullscreen();
        }
    }

    #isFullScreen()
    {
        return document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement;
    }

    setVideoTrack(track)
    {
        this.playerState.set(`activeVideoTrackId`, track.id);
    }

    setAudioTrack(track)
    {
        this.playerState.set(`activeAudioTrackId`, track.id);
    }

    setTextTrack(track)
    {
        this.playerState.set(`activeTextTrackId`, track.id);
    }

    destroy()
    {
        this.unbindEvents();
        this.playerState.reset();
    }
}
