export default class CompanionHandler
{
    constructor(eventBus, playerState, controller)
    {
        this.eventBus    = eventBus;
        this.playerState = playerState;
        this.contoller   = controller;
    }

    start()
    {
        return new Promise((resolve, reject) => {
            setTimeout(resolve, 5000);
        });
    }
}