import React from 'react';

class AudioTrackControl extends React.Component
{
    constructor(props)
    {
        super(props);

        props.playerState.on('change', () => {
            this.setState({player: props.playerState.props()});
        });

        this.state = {
            player:               props.playerState.props(),
            showList:             false,
            selectedAudioTrackId: props.playerState.activeAudioTrackId,
        };

        this.handleClick      = this.handleClick.bind(this);
        this.handleTrackClick = this.handleTrackClick.bind(this);
    }

    handleClick(event)
    {
        this.setState({
            showList:             !this.state.showList,
            selectedAudioTrackId: this.state.player.activeAudioTrackId,
        });
    }

    handleTrackClick(track, event)
    {
        this.props.controller.setAudioTrackId(track.id);
        this.setState({showList: !this.state.showList});
    }

    qualityClassName(track)
    {
        return 'playernow-quality' +
            (track.id === this.state.player.activeAudioTrackId ? ' active' : '') +
            (track.id === this.state.selectedAudioTrackId ? ' selected' : '');
    }

    renderTracks(tracks)
    {
        if (tracks.length === 1) {
            return;
        }

        return <ul className="playernow-quality-list">
            {
                tracks.map((track) => (
                    <li onMouseDown={e => this.handleTrackClick(track, e)} className={this.qualityClassName(track)}
                        key={track.id}>{track.label}</li>
                ))
            }
        </ul>;
    }

    onFocus(event)
    {
        event.preventDefault();
        this.setState({
            showList:             true,
            selectedAudioTrackId: this.state.player.activeAudioTrackId,
        });
        this.props.playerState.set(`focusElement`, `AudioTrackControl`);
    }

    onBlur(event)
    {
        event.preventDefault();
        this.setState({showList: false});
    }

    onKeyDown(e)
    {
        if (!e.keyCode) {
            return;
        }

        if (this.props.playerState.get(`focusElement`) !== 'AudioTrackControl') {
            return;
        }

        if (this.state.player.audioTracks.length <= 1) {
            return;
        }

        let selectedIndex = this.state.player.audioTracks.findIndex((val) => {
            return val.id === this.state.selectedAudioTrackId;
        });

        if (selectedIndex === -1) {
            return;
        }

        switch (e.keyCode) {
            case 38:
            case 39:
                e.preventDefault();

                if (selectedIndex === 0) {
                    return;
                }

                this.setState({selectedAudioTrackId: this.state.player.audioTracks[selectedIndex - 1].id});

                break;
            case 37:
            case 40:
                e.preventDefault();

                if (selectedIndex === this.state.player.videoTracks.length - 1) {
                    return;
                }

                this.setState({selectedAudioTrackId: this.state.player.audioTracks[selectedIndex + 1].id});

                break;
            case 13:
                e.preventDefault();
                this.props.controller.setAudioTrackId(this.state.selectedAudioTrackId);
                break;
        }
    }

    render()
    {
        if (this.state.player.adPlaying === 'linear') {
            return <div/>;
        }

        if (this.state.player.audioTracks.length <= 1) {
            return <div/>;
        }

        const qualityWrapClass = this.state.showList
            ? 'playernow-action-block playernow-audio-wrap active'
            : 'playernow-action-block playernow-audio-wrap';

        return (
            <div className={qualityWrapClass} onKeyDown={(e) => this.onKeyDown(e)}>
                <span
                    tabIndex="0"
                    className="tab-button playernow-audio-icon"
                    onMouseDown={this.handleClick}
                    onFocus={(e) => this.onFocus(e)}
                    onBlur={(e) => this.onBlur(e)}
                />
                {this.renderTracks(this.state.player.audioTracks)}
            </div>
        );
    }
}

export default AudioTrackControl;
