import muxjs from 'mux.js';
import * as err from '../../../../utils/err';

window.muxjs = muxjs;

import shaka from 'shaka-player';
import {isChrome} from '../../../../utils/client';
import libPkg from 'shaka-player/package.json';
import Protocol from '../protocol';
import VideoTrack from '../../track/video-track';
import AudioTrack from '../../track/audio-track';

export default class DashShaka extends Protocol
{
    static type = `DashShaka`;
    #dash;

    constructor(video, options, eventBus, logger)
    {
        super(DashShaka.type, video, options, eventBus, logger);
    }

    static isBrowserSupported()
    {
        return DashShaka.supportsMediaSource() && shaka.Player.isBrowserSupported();
    }

    static supportsMediaSource()
    {
        let hasWebKit      = ('WebKitMediaSource' in window);
        let hasMediaSource = ('MediaSource' in window);

        return (hasWebKit || hasMediaSource);
    }

    static version()
    {
        return libPkg.version;
    }

    #bindEvents()
    {
        this.#dash.addEventListener('error', this.handleError.bind(this));
        this.#dash.addEventListener(`urn:scte:scte35:2013:xml`, this.handleSCTE.bind(this));
        this.#dash.addEventListener(`urn:scte:scte35:2014:xml`, this.handleSCTE.bind(this));
        this.#dash.addEventListener(`urn:scte:scte35:2015:xml`, this.handleSCTE.bind(this));
        this.#dash.addEventListener(`urn:scte:scte35:2016:xml`, this.handleSCTE.bind(this));
    }

    #unbindEvents()
    {
        this.#dash.removeEventListener('error', this.handleError.bind(this));
        this.#dash.removeEventListener(`urn:scte:scte35:2013:xml`, this.handleSCTE.bind(this));
        this.#dash.removeEventListener(`urn:scte:scte35:2014:xml`, this.handleSCTE.bind(this));
        this.#dash.removeEventListener(`urn:scte:scte35:2015:xml`, this.handleSCTE.bind(this));
        this.#dash.removeEventListener(`urn:scte:scte35:2016:xml`, this.handleSCTE.bind(this));
    }

    handleSCTE(e)
    {
        if (!e.event) {
            return;
        }
        this.eventBus.emit(`playback.SCTE35`, {options: e.event});
    }

    handleError(e)
    {
        if (!e) {
            return;
        }

        this.eventBus.emit(`playback.error`, {type: err.list.dashLibraryError, data: e});
    }

    updateVideoTracks()
    {
        const bitrateList = this.#dash.getVariantTracks();

        let uniqueIds             = [];
        const uniqueVariantTracks = bitrateList.filter(level => {

            if (uniqueIds.includes(level.videoId)) {
                return false;
            }

            uniqueIds.push(level.videoId);

            return true;
        });

        let heights = uniqueVariantTracks.map((level) => {
            return level.height;
        });

        let hasDuplicateHeights = uniqueVariantTracks.length !== (new Set(heights)).size;

        const videoTracks = uniqueVariantTracks.map(level => {

            let label = level.height;
            if (hasDuplicateHeights && level.bandwidth) {
                label += ' (' + level.bandwidth + ')';
            }

            return new VideoTrack(level.videoId, level.height, label);
        });

        videoTracks.sort(this.sortVideoTracks);
        videoTracks.push(new VideoTrack(-1, 0, `auto`));
        this.eventBus.emit(`playback.videoTracks`, {videoTracks});
    }

    updateAudioTracks()
    {
        const bitrateList = this.#dash.getVariantTracks();

        let uniqueIds             = [];
        const uniqueVariantTracks = bitrateList.filter(level => {

            if (uniqueIds.includes(level.audioId)) {
                return false;
            }

            uniqueIds.push(level.audioId);

            return true;
        });

        let audioTracks = uniqueVariantTracks.map((level) => {
            let label = level.label ?? level.language;
            return new AudioTrack(level.audioId, level.language, label);
        });

        this.eventBus.emit(`playback.audioTracks`, {audioTracks});
    }

    updateActiveAudioTrack()
    {
        let track = this.#dash.getVariantTracks().find(track => track.active);
        if (track) {
            this.eventBus.emit(`playback.activeAudioTrackId`, {activeAudioTrackId: track.audioId});
        }
    }

    async setVideoTrack(track)
    {
        if (track.id === -1) {
            this.#dash.configure({abr: {enabled: true}});

            return true;
        }

        this.#dash.configure({abr: {enabled: false}});

        const tracks = this.#dash.getVariantTracks();

        let activeVariant = tracks.find(t => t.active);
        let variant       = tracks.find(t => t.videoId === track.id && t.audioId === activeVariant.audioId);

        if (!variant) {
            this.logger.error(`[Playback][Dash] Invalid video trackId: ` + track.id);
            return true;
        }

        this.#dash.selectVariantTrack(variant, true);

        return true;
    }

    async setAudioTrack(track)
    {
        const tracks = this.#dash.getVariantTracks();

        let activeVariant = tracks.find(t => t.active);
        let variant       = tracks.find(t => t.audioId === track.id && t.videoId === activeVariant.videoId);

        if (!variant) {
            this.logger.error(`[Playback][Dash] Invalid audio trackId: ` + track.id);
            return true;
        }

        this.#dash.selectVariantTrack(variant, true);

        return true;
    }

    async initialize(activeVideoTrackId = -1)
    {
        return new Promise(resolve => {
            this.logger.debug(`[Playback][DASH Shaka] Initializing...`);

            this.#dash = new shaka.Player(this.video);
            this.#dash.configure({abr: {enabled: false}});
            this.#dash.addEventListener('trackschanged', this.updateVideoTracks.bind(this));
            this.#dash.addEventListener('trackschanged', this.updateAudioTracks.bind(this));
            this.#dash.addEventListener('adaptation', this.updateActiveAudioTrack.bind(this));
            this.#dash.addEventListener(`error`, this.handleError);

            if (this.options.drm && this.options.drm.keySystems) {
                this.logger.log(`[Playback][DASH] DRM detected`);

                let drm = {servers: {}};

                if (this.options.drm.keySystems[`com.microsoft.playready`]) {
                    drm.servers['com.microsoft.playready'] = this.options.drm.keySystems[`com.microsoft.playready`].serverURL;
                }

                if (this.options.drm.keySystems[`com.widevine.alpha`]) {
                    drm.servers['com.widevine.alpha'] = this.options.drm.keySystems[`com.widevine.alpha`].serverURL;
                }

                if (isChrome()) {
                    drm.advanced = {
                        'com.widevine.alpha': {
                            videoRobustness: `SW_SECURE_CRYPTO`, audioRobustness: `SW_SECURE_CRYPTO`,
                        },
                    };
                }

                this.#dash.configure({drm: drm});
            }
            this.#bindEvents();
            this.#dash.load(this.options.url)
                .then(() => {
                    this.initialized = true;
                    this.logger.debug(`[Playback][DASH Shaka] Initialized`);
                    resolve();
                })
                .catch(this.handleError.bind(this));
        });
    }

    async destroy()
    {
        this.#unbindEvents();
        await this.#dash.unload();
        await this.#dash.destroy();

        return true;
    }
}
