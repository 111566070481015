import TrackingEvent from './TrackingEvent';
import NonLinear from './NonLinear';

function parseNonLinears(nonLinears)
{
    if (nonLinears) {
        nonLinears = Array.isArray(nonLinears) ? nonLinears : [nonLinears];
        return nonLinears.map(t => new NonLinear(t));
    }
    return [];
}

function parseTrackingEvents(trackingEvents)
{
    if (trackingEvents) {
        trackingEvents = Array.isArray(trackingEvents) ? trackingEvents : [trackingEvents];
        return trackingEvents.map(t => new TrackingEvent(t));
    }
    return [];
}

export default class NonLinearAds
{
    constructor(obj)
    {
        this.nonLinears     = parseNonLinears(obj.nonLinear);
        this.trackingEvents = parseTrackingEvents(obj.trackingEvents && obj.trackingEvents.tracking);
    }
}