function isValidEmailFormat(email)
{
    const re = /@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
}

function isValidPasswordLength(password)
{
    return password.length >= 6;
}

function filterUnique(errors)
{
    function onlyUnique(value, index, self)
    {
        return self.indexOf(value) === index;
    }

    return errors.filter(onlyUnique);
}

export {isValidEmailFormat, isValidPasswordLength, filterUnique};
