import EventEmitter from '../event/event-emitter';
import VideoTrack from './playback/track/video-track';

const priv          = new WeakMap();
const defaultValues = {
    authEnabled:       false,
    authAuthenticated: false,
    authEmail:         '',
    authFestivalName:  '',

    isStream:    false,
    playing:     false,
    adLoading:   false,
    loading:     false,
    error:       null,
    fullscreen:  false,
    volume:      1.0,
    isMuted:     false,
    currentTime: 0,
    duration:    0,

    bufferedDuration: 0,
    bufferedFrom:     0,
    bufferedTo:       0,

    videoTracks:        [new VideoTrack(-1, 0, `auto`)],
    activeVideoTrackId: -1,

    audioTracks:        [],
    activeAudioTrackId: -1,

    textTracks:        [],
    activeTextTrackId: -1,

    bitrate:        `N/A`,
    showDebugPanel: false,
    poster:         null,
    focusElement:   null,
    viewersCount:   0,
    showViewersCounter: false
};

export default class PlayerState extends EventEmitter
{
    constructor()
    {
        super();
        priv.set(this, Object.assign({}, defaultValues));
    }

    mset(values)
    {
        let obj = priv.get(this);
        Object.keys(values).forEach((key) => {
            obj[key] = values[key];
        });
    }

    get(key)
    {
        return priv.get(this)[key];
    }

    set(key, value)
    {
        priv.get(this)[key] = value;
        this.emit(`change`);
    }

    add(key, value)
    {
        let existsValue = priv.get(this)[key];
        if (!existsValue) {
            existsValue = [];
        }

        existsValue.push(value);
        priv.get(this)[key] = existsValue;

        this.emit(`change`);
    }

    props()
    {
        return {...priv.get(this)};
    }

    reset()
    {
        priv.set(this, Object.assign({}, defaultValues));
        this.emit(`change`);
    }
}
