import React from 'react';
import * as utils from '../../../utils/auth';

class LoginModal extends React.Component
{
    constructor(props)
    {
        super(props);

        props.playerState.on('change', () => {
            this.setState({player: props.playerState.props()});
        });

        this.state = {
            player:   props.playerState.props(),
            disabled: false,
            email:    '',
            password: '',
            errors:   [],
        };
    }

    handleFormSubmit()
    {
        const email    = this.state.email;
        const password = this.state.password;
        let errors     = [];

        if (!utils.isValidEmailFormat(email)) {
            errors.push('Неверный формат электронной почты');
        }

        if (!utils.isValidPasswordLength(password)) {
            errors.push('Пароль не может быть меньше 6 символов');
        }

        if (errors.length > 0) {
            this.setState({errors: errors});
            return;
        }

        this.setState({disabled: true});
        this.props.controller.login(email, password).then(({success, errors}) => {
            if (success) {
                this.props.onCloseBtnClick();
                this.setState({password: ''});
            }

            let errorMessages = [];
            errors.map(error => {
                if (error === 'User not verified') {
                    errorMessages.push('Ваш аккаунт еще не активирован, проверьте почту');
                } else if (error === 'Invalid email/password') {
                    errorMessages.push('Неверный логин или пароль');
                } else {
                    errorMessages.push('Произошла ошибка. Попробуйте авторизоваться позже');
                }
            });

            this.setState({disabled: false, errors: utils.filterUnique(errorMessages)});
        });
    }

    handleEmailChange(event)
    {
        this.setState({email: event.target.value, errors: []});
    }

    handlePasswordChange(event)
    {
        this.setState({password: event.target.value, errors: []});
    }

    render()
    {
        if (!this.state.player.authEnabled) {
            return '';
        }

        if (!this.props.visible) {
            return '';
        }

        return (
            <div className="modal fade playernow-modal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-card card">
                            <div className="card-header">
                                <h4 className="card-header-title">{this.state.player.authFestivalName}</h4>
                                <button type="button" className="close" onClick={this.props.onCloseBtnClick}>×</button>
                            </div>
                            <div className="card-body">
                                <div className="row d-flex justify-content-center text-center">
                                    <div className="col-12 col-md-8 col-xl-7 my-3">
                                        <h1>Вход</h1>
                                        <p className="text-muted text-center mb-5">Для просмотра видео необходимо авторизоваться</p>

                                        <div className="form-group">
                                            <label>Ваша почта</label>
                                            <input type="email" className="form-control" placeholder="name@address.com" value={this.state.email}
                                                   onChange={e => this.handleEmailChange(e)} disabled={this.state.disabled}/>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col">
                                                    <label>Пароль</label>
                                                </div>
                                                <div className="col-auto">
                                                    <a href="#" onClick={this.props.onResetPasswordBtnClick}>Забыли пароль?</a>
                                                </div>
                                            </div>
                                            <div className="input-group">
                                                <input type="password" className="form-control form-control-appended" placeholder="Введите пароль"
                                                       value={this.state.password} onChange={e => this.handlePasswordChange(e)} disabled={this.state.disabled}/>
                                            </div>
                                        </div>
                                        <button className="btn btn-lg btn-block btn-primary mb-3" onClick={e => this.handleFormSubmit(e)}
                                                disabled={this.state.disabled}>
                                            Войти
                                        </button>
                                        <div className="text-error">
                                            {
                                                this.state.errors.map((error, index) => (
                                                        <div key={index}>{error}</div>
                                                    ),
                                                )
                                            }
                                        </div>
                                        <div className="text-center">
                                            <p className="text-muted text-center">
                                                Нет аккаунта? <a href="#" onClick={this.props.onRegistrationBtnClick}>Зарегистрируйтесь</a>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginModal;
