export default class EventEmitter
{
    #player;
    #listeners = {};

    constructor(player)
    {
        this.#player = player;
    }

    on(event, callback, context = null)
    {
        if (!this.#listeners[event]) {
            this.#listeners[event] = [];
        }
        this.#listeners[event].push({callback, once: false, context});
    }

    off(event, cb, context = null)
    {
        if (!this.#listeners[event]) {
            return;
        }
        this.#listeners[event] = this.#listeners[event].filter((e) => {
            return !(e.callback === cb && e.context === context);
        });
    }

    once(event, callback, context = null)
    {
        if (!this.#listeners[event]) {
            this.#listeners[event] = [];
        }
        this.#listeners[event].push({callback, once: true, context});
    }

    onceOf(events, callback, context = null)
    {
        let listener = (data) => {
            callback.call(context, data);
            events.forEach((e) => {
                this.off(e, listener, this);
            });
        };
        events.forEach((e) => {
            this.on(e, listener, this);
        });
    }

    emit(event, data = {})
    {
        if (!this.#listeners[event] || this.#listeners[event].length === 0) {
            return;
        }

        data.targetPlayer = this.#player;

        this.#listeners[event].forEach((e) => {
            // it could be unsubscribed while emitting
            if (this.#listeners[event].indexOf(e) !== -1) {
                data.event = event;
                e.callback.call(e.context, data);
            }
        });

        this.#listeners[event] = this.#listeners[event].filter((e) => {
            return !e.once;
        });
    }

    destroy()
    {
        this.#listeners = {};
    }
}
