import * as xml from '../utils/xml';
import {parsers} from './parsers';

export default class NonLinear
{
    constructor(obj)
    {
        //Required Elements
        this.creativeType   = xml.attr(obj.staticResource, `creativeType`);
        this.staticResource = xml.value(obj.staticResource);
        this.htmlResource   = xml.value(obj.hTMLResource);
        this.iframeResource = xml.value(obj.iFrameResource);

        this.width  = parseInt(xml.attr(obj, `width`));
        this.height = parseInt(xml.attr(obj, `height`));

        //Optional fields
        this.id                  = xml.attr(obj, `id`);
        this.expandedWidth       = parseInt(xml.attr(obj, `expandedWidth`)) || null;
        this.expandedHeight      = parseInt(xml.attr(obj, `expandedHeight`)) || null;
        this.scalable            = xml.attr(obj, `expandedHeight`);
        this.maintainAspectRatio = xml.attr(obj, `maintainAspectRatio`);
        this.duration            = parsers.duration(xml.attr(obj, `minSuggestedDuration`));
        this.apiFramework        = xml.attr(obj, `apiFramework`);

        this.clickThrough  = xml.value(obj.nonLinearClickThrough);
        this.clickTracking = xml.value(obj.nonLinearClickTracking);

        if (obj.adParameters) {
            this.adParameters = xml.value(obj.adParameters);
            if (xml.attr(obj.adParameters, `xmlEncoded`)) {
                this.adParameters = xml.decode(this.adParameters);
            }
        }
    }
}