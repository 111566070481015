import * as xml from '../utils/xml';

function appendToArray(array, items)
{
    items.forEach(item => array.push(item));
}

function _addClickThrough(obj, clickThrough)
{
    if (clickThrough && clickThrough !== ``) {
        obj.clickThrough = clickThrough;
    }
}

function _addClickTrackings(obj, clickTrackings)
{
    Array.isArray(clickTrackings) && appendToArray(obj.clickTrackings, clickTrackings);
}

function _addCustomClicks(obj, customClicks)
{
    Array.isArray(customClicks) && appendToArray(obj.customClicks, customClicks);
}

function _addDuration(obj, duration)
{
    obj.duration = duration;
}

function _addVideoClicks(obj, videoClicks)
{
    if (!videoClicks) {
        return;
    }
    _addClickThrough(obj, videoClicks.clickThrough);
    _addClickTrackings(obj, videoClicks.clickTrackings);
    _addCustomClicks(obj, videoClicks.customClicks);
}

function _addIcons(obj, icons)
{
    if (!icons) {
        return;
    }
    icons.forEach((i) => {
        i.show               = false;
        obj.icons[i.program] = i;
    });
}

function _addAdParameters(obj, adParameters)
{
    if (adParameters) {
        obj.adParameters = adParameters;
    }
}

function _addTrackingEvents(obj, trackingEvents)
{
    let eventsMap = obj.trackingEvents;

    if (trackingEvents) {
        trackingEvents = Array.isArray(trackingEvents) ? trackingEvents : [trackingEvents];
        trackingEvents.forEach(function(trackingEvent) {
            if (!eventsMap[trackingEvent.name]) {
                eventsMap[trackingEvent.name] = [];
            }
            eventsMap[trackingEvent.name].push(trackingEvent);
        });
    }
}

export default class VastResponse
{
    constructor(vastUrl)
    {
        this._linearAdded    = false;
        this._nonLinearAdded = false;
        this._companionAdded = false;

        // top level attributes
        this.vastUrl        = vastUrl; // primary vast url
        this.ads            = []; // adPods or Wrapper responses
        this.errorURLMacros = []; // inLine + Wrapper ErrorUrls
        this.impressions    = [];
        this.extensions     = [];
        this.adTitle        = ``;

        this.sequence = [];

        // linear attributes
        this.linear = {
            elements:       [],
            clickTrackings: [],
            customClicks:   [],
            trackingEvents: {},
            icons:          {},
            clickThrough:   null,
            skipOffset:     null,
            duration:       null,
        };

        // nonLinear attributes
        this.nonLinear = {
            elements:       [],
            trackingEvents: {},
        };
    }

    addAd(ad)
    {
        this.ads.push(ad);
        if (ad.inLine) {
            this._addInLine(ad.inLine);
        }
        if (ad.wrapper) {
            this._addWrapper(ad.wrapper);
        }
    }

    hasLinear()
    {
        return this._linearAdded;
    }

    hasNonLinear()
    {
        return this._nonLinearAdded;
    }

    hasCompanion()
    {
        return this._companionAdded;
    }

    _addInLine(inLine)
    {
        this._addTitle(inLine.adTitle);
        this._addErrorTrackUrl(inLine.error);
        this._addImpressions(inLine.impressions);
        this._addExtensions(inLine.extensions);

        let sequenceNumber = -1;
        let creatives      = inLine.creatives.sort((a, b) => a.sequence - b.sequence);
        creatives.forEach((creative) => {
            if (creative.sequence !== sequenceNumber) {
                this.sequence.push([]);
                sequenceNumber = creative.sequence;
            }

            if (creative.linear) {
                this._addLinear(creative.linear);
                this.sequence[this.sequence.length - 1].push(`linear`);
            }

            if (creative.nonLinearAds) {
                this._addNonLinearAds(creative.nonLinearAds);
                this.sequence[this.sequence.length - 1].push(`nonLinear`);
            }

            if (creative.companionAds) {
                // todo
            }
        });
    }

    _addWrapper(wrapper)
    {
        this._addErrorTrackUrl(wrapper.error);
        this._addImpressions(wrapper.impressions);
        this._addExtensions(wrapper.extensions);

        wrapper.creatives.forEach((creative) => {
            let linear = creative.linear;
            if (linear) {
                _addVideoClicks(this.linear, linear.videoClicks);
                _addTrackingEvents(this.linear, linear.trackingEvents);
                _addIcons(this.linear, linear.icons);
            }
        });
    }

    _addErrorTrackUrl(error)
    {
        let errorURL = xml.value(error) || error;
        if (errorURL) {
            this.errorURLMacros.push(errorURL);
        }
    }

    _addImpressions(impressions)
    {
        Array.isArray(impressions) && appendToArray(this.impressions, impressions);
    }

    _addExtensions(extensions)
    {
        Array.isArray(extensions) && appendToArray(this.extensions, extensions);
    }

    _addTitle(title)
    {
        this.adTitle = title;
    }

    _addMediaFiles(mediaFiles)
    {
        Array.isArray(mediaFiles) && appendToArray(this.linear.elements, mediaFiles);
    }

    _addSkipOffset(offset)
    {
        if (offset) {
            this.linear.skipOffset = offset;
        }
    }

    _addLinear(linear)
    {
        this._addMediaFiles(linear.mediaFiles);
        this._addSkipOffset(linear.skipoffset);

        _addDuration(this.linear, linear.duration);
        _addVideoClicks(this.linear, linear.videoClicks);
        _addAdParameters(this.linear, linear.adParameters);
        _addTrackingEvents(this.linear, linear.trackingEvents);
        _addIcons(this.linear, linear.icons);
        this._linearAdded = true;
    }

    _addNonLinearAds(nonLinearAds)
    {
        _addTrackingEvents(this.nonLinear, nonLinearAds.trackingEvents);
        this.nonLinear.elements = nonLinearAds.nonLinears;
        this._nonLinearAdded    = true;
    }
}