export default class Translate
{
    static #locale = {
        'ru': {
            'VIDEO_UNAVAILABLE':         `Видео недоступно для просмотра`,
            'VIDEO_UNSUPPORTED':         `Видео не поддерживается вашим браузером`,
            'SEC':                       `сек`,
            'ON_AIR':                    `В эфире`,
            'AD':                        `Реклама`,
            'SKIP_AD':                   `Пропустить`,
            'SKIP_IN':                   `Пропустить через`,
            'SPECIFY_VIDEO_SOURCE':      `Укажите источник видео`,
            'DRM_NOT_SUPPORTED':         `Ваш браузер не поддерживает DRM`,
            'FAILED_LOAD_PLAYLIST':      `Не удалось загрузить плейлист`,
            'FAILED_LOAD_SUBTITLES':     `Не удалось загрузить субтитры`,
            'FAILED_LOAD_POSTER':        `Не удалось загрузить постер`,
            'FAILED_LOAD_FAIRPLAY_CERT': `Не удалось загрузить сертификат FairPlay`,
        },
        'en': {
            'VIDEO_UNAVAILABLE':         `Video not available`,
            'VIDEO_UNSUPPORTED':         `Video is not supported by your browser`,
            'SEC':                       `sec`,
            'ON_AIR':                    `On air`,
            'AD':                        `Ad`,
            'SKIP_AD':                   `Skip`,
            'SKIP_IN':                   `Skip in`,
            'SPECIFY_VIDEO_SOURCE':      `Specify video source`,
            'DRM_NOT_SUPPORTED':         `Your browser doesn't support DRM`,
            'FAILED_LOAD_PLAYLIST':      `Failed to load playlist`,
            'FAILED_LOAD_SUBTITLES':     `Failed to load subtitles`,
            'FAILED_LOAD_POSTER':        `Failed to load poster`,
            'FAILED_LOAD_FAIRPLAY_CERT': `Failed to load FairPlay certificate`,
        },
    };

    #options;

    constructor(options)
    {
        this.#options = options;
    }

    get lang()
    {
        return this.#options.lang || `ru`;
    }

    L(text)
    {
        const lang = this.lang;

        if (!Translate.#locale[lang]) {
            console.error(`no translate for lang: ${lang}`);
            return text;
        }

        if (!Translate.#locale[lang][text]) {
            console.error(`no translate for text: ${text}`);
            return text;
        }

        return Translate.#locale[lang][text];
    }
}
