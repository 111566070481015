const durationRegex = /(\d\d):(\d\d):(\d\d)(\.(\d\d\d))?/;

function parseHoursToMs(hourStr)
{
    return parseInt(hourStr, 10) * 60 * 60 * 1000;
}

function parseMinToMs(minStr)
{
    return parseInt(minStr, 10) * 60 * 1000;
}

function parseSecToMs(secStr)
{
    return parseInt(secStr, 10) * 1000;
}

function isPercentage(offset)
{
    let percentageRegex = /^\d+(\.\d+)?%$/g;
    return percentageRegex.test(offset);
}

function calculatePercentage(percentStr, duration)
{
    if (duration) {
        return calcPercent(duration, parseFloat(percentStr.replace(`%`, ``)));
    }
    return null;
}

function calcPercent(quantity, percent)
{
    return quantity * percent / 100;
}

export const parsers = {
    duration(durationStr)
    {
        let durationInMs;

        if (typeof durationStr === `string`) {
            if (durationStr.length < 8) {
                durationStr = `00:` + durationStr;
            }
            let match = durationStr.match(durationRegex);
            if (match) {
                durationInMs = parseHoursToMs(match[1]) + parseMinToMs(match[2]) + parseSecToMs(match[3]) + parseInt(match[5] || 0);
            }
        }
        return isNaN(durationInMs) ? null : durationInMs;
    },

    offset(offset, duration)
    {
        if (isPercentage(offset)) {
            return calculatePercentage(offset, duration);
        }
        return parsers.duration(offset);
    },
};
