import Iframe from '../../adv/utils/iframe';
import VPAIDAdUnit from '../vpaid/VPAIDAdUnit';

class VPAIDClient
{
    constructor(AdWrapper, videoElement, logger)
    {
        this.AdWrapper    = AdWrapper;
        this.videoElement = videoElement;
        this.logger       = logger;
        this.id           = +new Date();
    }

    injectHTML5(src, adType)
    {
        return new Promise((resolve, reject) => {

            src = src.replace(/(http(s)?:\/\/)/i, `//`);

            this.iframe = new Iframe().createIframe({
                src:    src,
                id:     this.id,
                origin: this.getOrigin(),
            }, this.AdWrapper);

            window.addEventListener(`message`, onLoad.bind(this));

            function onLoad(e)
            {
                let result = e.data;

                if (e.origin !== this.getOrigin()) {
                    return null;
                }
                if (result.id !== this.id) {
                    return null;
                }

                if (!this.iframe.contentWindow) {
                    reject(`VPAID is not loaded or not in the DOM`);
                    return null;
                }

                let vpaidCreative     = this.iframe.contentWindow.getVPAIDAd;
                let adElement         = this.iframe.contentWindow.document.querySelector(`.ad-element`);
                this.videoElement.src = ``;

                resolve(new VPAIDAdUnit(vpaidCreative(), adElement, this.videoElement, this.iframe, src, adType, this.logger));
            }
        });
    }

    getOrigin()
    {
        if (window.location.origin) {
            return window.location.origin;
        } else {
            return window.location.protocol + `//` +
                window.location.hostname +
                (window.location.port ? `:` + window.location.port : ``);
        }
    }
}

export default VPAIDClient;