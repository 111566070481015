import * as Sentry from '@sentry/browser';

export default class Logger
{
    constructor({
        debug:              debug,
        sentryDSN:          sentryDSN,
        sentryIgnoreErrors: sentryIgnoreErrors = [],
        version:            version,
    })
    {
        this.debugLevel = debug || false;
        this.logElement = null;

        if (sentryIgnoreErrors.length) {
            Sentry.init({
                dsn:          sentryDSN,
                ignoreErrors: sentryIgnoreErrors,
            });
        } else {
            Sentry.init({dsn: sentryDSN, release: 'playernow@'.version});
        }
        Sentry.configureScope((scope) => {
            scope.setTag(`version`, version);
        });
    }

    setLogElement(elem)
    {
        this.logElement = elem;
    }

    logToHTMLElement(message)
    {
        if (!this.logElement) {
            return;
        }

        this.logElement.innerHTML = this.logElement.innerHTML + `\n` + message;
    }

    captureException(err)
    {
        Sentry.captureException(err);
    }

    debug(...args)
    {
        this.logToHTMLElement(args.join(` `));

        if (!this.debugLevel) {
            return false;
        }

        console.debug.apply(console, args);
    }

    log(...args)
    {
        this.logToHTMLElement(args.join(` `));

        if (!this.debugLevel) {
            return false;
        }

        console.log.apply(console, args);
    }

    info(...args)
    {
        console.info.apply(console, args);
        this.logToHTMLElement(args.join(` `));
    }

    warn(...args)
    {
        Sentry.captureMessage(JSON.stringify(args));
        console.warn.apply(console, args);
        this.logToHTMLElement(args.join(` `));
    }

    error(...args)
    {
        Sentry.captureMessage(JSON.stringify(args));
        console.error.apply(console, args);
        this.logToHTMLElement(args.join(` `));
    }
}
