export default class Protocol
{
    type;
    initialized;
    #video;
    #options;
    #eventBus;
    #logger;

    constructor(type, video, options, eventBus, logger)
    {
        this.type        = type;
        this.#video      = video;
        this.#options    = options;
        this.#eventBus   = eventBus;
        this.#logger     = logger;
        this.initialized = false;
    }

    get video()
    {
        return this.#video;
    }

    get options()
    {
        return this.#options;
    }

    get eventBus()
    {
        return this.#eventBus;
    }

    get logger()
    {
        return this.#logger;
    }

    static isSupported() { throw new Error(`method isSupported should be overridden`); }

    async initialize() { throw new Error(`method initialize should be overridden`); }

    isInitialized()
    {
        return this.initialized;
    }

    async play()
    {
        let playPromise = this.video.play();

        if (playPromise !== undefined) {
            return playPromise;
        }

        return true;
    }

    async pause()
    {
        let playPromise = this.video.pause();

        if (playPromise !== undefined) {
            return playPromise;
        }

        return true;
    }

    setVolume(value)
    {
        this.video.volume = value;
    }

    setMute(value)
    {
        this.video.muted = value;
    }

    seek(pos)
    {
        this.video.currentTime = pos;
    }

    async setVideoTrack(value) { throw new Error(`method setVideoTrack should be overridden`); }

    async setAudioTrack(track) { throw new Error(`method setAudioTrack should be overridden`); }

    async destroy() { throw new Error(`method destroy should be overridden`); }

    sortVideoTracks(a, b)
    {
        if (a.height === b.height) {
            return 0;
        }

        return a.height < b.height ? 1 : -1;
    }
}