import GA from './ga/ga';
import Heartbeat from './heartbeat/heartbeat';
import playerStat from './playerstat/playerstat';

class Stat
{
    constructor(controller, options = {}, eventBus, playerState, logger)
    {
        this.controller  = controller;
        this.options     = options;
        this.eventBus    = eventBus;
        this.playerState = playerState;
        this.logger      = logger;

        if (this.options.ga) {
            this.ga = new GA(this.options.ga, this.eventBus, this.logger);
        }

        if (this.options.heartbeat) {
            this.heartbeat = new Heartbeat(this.options.heartbeat, this.eventBus, this.playerState, this.logger);
        }

        if (this.options.playerstat) {
            this.playerstat = new playerStat(this.options.playerstat, this.controller, this.logger);
        }
    }

    destroy()
    {
        if (this.ga) {
            this.ga.destroy();
        }
        if (this.heartbeat) {
            this.heartbeat.destroy();
        }
        if (this.playerstat) {
            this.playerstat.destroy();
        }
    }
}

export default Stat;