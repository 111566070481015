const tnsStatic  = `//www.tns-counter.ru/V13a**`;
const protocol   = window.location.protocol || ``;
const DeviceType = `d`;
const DeviceID   = ``;

class Heartbeat
{
    constructor(options, eventBus, playerState, logger)
    {
        this.eventBus    = eventBus;
        this.playerState = playerState;
        this.default     = {
            VcVersion: 0,
            timeout:   30000,
        };

        this.logger = logger;

        this.options = Object.assign(this.default, options);
        if (!options.CatID || !options.VcID || !options.TnsAccount || !options.TnsTmsec) {
            this.logger.error(`Can not init heartbeat.js. One or more required params missed`);
            return;
        }

        this.eventBus.once(`playback.playing`, this.tick.bind(this));
    }

    tick()
    {
        this.tnsTimer = setInterval(() => {
            // default FTS
            let FTS = Math.floor(Date.now() / 1000);

            // if not a stream get currentTime of video
            if (this.playerState.get(`adPlaying`)) {
                FTS = Math.floor(+this.playerState.get(`snapshotCurrentTime`));
            } else {
                FTS = Math.floor(+this.playerState.get(`currentTime`));
            }

            // if isStream get current timestamp
            if (this.playerState.get(`isStream`)) {
                FTS = Math.floor(Date.now() / 1000);
            }

            let VTS    = Math.floor(Date.now() / 1000);
            let tnsUrl = `${protocol}${tnsStatic}${this.options.CatID}:${this.options.VcID}:${this.options.VcVersion}:${FTS}:${VTS}:${DeviceType}:${DeviceID}**${this.options.TnsAccount}/ru/UTF-8/tmsec=${this.options.TnsTmsec}/`;

            // new Image().src = tnsUrl;
        }, this.options.timeout);
    }

    destroy()
    {
        clearInterval(this.tnsTimer);
    }
}

export default Heartbeat;