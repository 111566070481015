import * as vpaidUtils from '../utils/vpaidUtils';
import VPAIDClient from '../vpaid/VPAIDClient';

class VPAIDTech
{
    constructor(type, vastResponse, logger)
    {
        this.type   = type.toLowerCase();
        this.logger = logger;

        // TODO: if flash type
        // TODO: NonLinear ad
        if (this.type === `linear`) {
            this.logger.log(`[VPAIDTech.init] Linear ad found...`);

            // find all VPAID mediaFiles
            let vpaids     = vastResponse.linear.elements.filter((e) => {
                return vpaidUtils.isVPAID(e);
            });
            // prefer HTML5
            this.mediaFile = vpaids.find((e) => {
                return e.type.toLowerCase() === vpaidUtils.availableTypes.html5
                    ? vpaidUtils.availableTypes.html5
                    : null;
            });

            // if no HTML5 here, take FLASH
            if (!this.mediaFile) {
                this.mediaFile = vpaids.find((e) => {
                    return e.type.toLowerCase() === vpaidUtils.availableTypes.flash
                        ? vpaidUtils.availableTypes.flash
                        : null;
                });
            }

            if (this.mediaFile) {
                this.logger.log(`[VPAIDTech.init] Ad type:`, this.mediaFile.type);
            } else {
                throw `[Error][VPAIDTech.init] No supported mediaFile found`;
            }
        }
    }

    loadAdUnit(AdWrapper, typeElement)
    {
        let VPAIDWrapper = new VPAIDClient(AdWrapper, typeElement, this.logger);

        // TODO: NonLinear Ad
        if (this.type === `linear`) {
            return VPAIDWrapper.injectHTML5(this.mediaFile.src, this.type);
        }
    }
}

export default VPAIDTech;