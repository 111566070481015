import {parsers} from './parsers';
import * as xml from '../utils/xml';

export default class TrackingEvent
{
    constructor(obj, duration)
    {
        this.name = obj.event;
        this.uri  = xml.value(obj);

        if (`progress` === this.name) {
            this.originalOffset = obj.offset;
            this.offset         = parsers.offset(obj.offset, duration);
        }
    }
}