import React from 'react';
import * as err from '../../utils/err';

class ErrorWrapper extends React.Component
{
    constructor(props)
    {
        super(props);
        this.error = null;
    }

    hasBuffer()
    {
        let bufferHealth = this.props.playerState.get(`bufferedTo`) - this.props.playerState.get(`currentTime`);
        return bufferHealth > 1;
    }

    clearWarnings()
    {
        this.props.playerState.set(`warnings`, []);
    }

    renderErrors(errors)
    {
        if ((this.props.playerState.get(`playing`) && this.hasBuffer()) || errors.length === 0) {
            return null;
        }

        if (this.props.messages.ignore.length) {
            return <div className="playernow-error-wrapper transparent">
                <div>{this.props.messages.ignore}</div>
            </div>;
        }

        errors.sort();

        return <div className="playernow-error-wrapper">
            <div>
                {
                    errors.map((error, index) => (
                        this.renderRecord(error, index)
                    ))
                }
            </div>
        </div>;
    }

    renderWarnings(warnings)
    {
        if (warnings.length === 0) {
            return null;
        }

        warnings.sort();

        return <div className="playernow-alert-wrapper">
            <div>
                {
                    warnings.map((error, index) => (
                        this.renderRecord(error, index)
                    ))
                }
            </div>
            <button className="playernow-btn-close" onClick={e => this.clearWarnings(e)}></button>
        </div>;
    }

    renderRecord(error, index)
    {
        if (!error.type) {
            return;
        }

        let errorMessage = '';
        let anchor       = 0;

        switch (error.type) {
            case err.list.emptySource:
                errorMessage = this.props.messages.errEmptySource;
                anchor       = 40002;
                break;
            case err.list.protocolError:
                errorMessage = this.props.messages.videoUnsupported;
                anchor       = 40004;
                break;
            case err.list.drmNotSupported:
                errorMessage = this.props.messages.errDrmNotSupported;
                anchor       = 40001;
                break;
            case err.list.hlsLibraryError:
                errorMessage = this.props.messages.videoUnavailable + `. HLS error ` + error.code;
                anchor       = 40003;
                break;
            case err.list.dashLibraryError:
                errorMessage = this.props.messages.videoUnavailable + `. DASH error ` + error.code;
                anchor       = 40003;
                break;
            case err.list.videoElementError:
                errorMessage = this.props.messages.videoUnavailable + (error.code ? ` ` + error.code : '');
                anchor       = 40003;
                break;
            case err.list.failedLoadPlaylist:
                errorMessage = this.props.messages.failedLoadPlaylist + ` ` + error.url + (error.httpStatus ? ` (` + error.httpStatus + `)` : '');
                anchor       = 30001;
                break;
            case err.list.failedLoadSubtitles:
                errorMessage = this.props.messages.failedLoadSubtitles + ` ` + error.url + (error.httpStatus ? ` (` + error.httpStatus + `)` : '');
                anchor       = 30002;
                break;
            case err.list.failedLoadPoster:
                errorMessage = this.props.messages.failedLoadPoster + ` ` + error.url + (error.httpStatus ? ` (` + error.httpStatus + `)` : '');
                anchor       = 30003;
                break;
            case err.list.failedLoadFairplayCert:
                errorMessage = this.props.messages.failedLoadFairplayCert + ` ` + error.url;
                anchor       = 30004;
                break;
        }

        if (!errorMessage) {
            return;
        }

        let link = 'faq/#error-' + anchor;

        return (
            <div key={errorMessage + index}>[<a href={link} target="_blank">{anchor}</a>] {errorMessage}</div>
        );
    }

    hasType(arr, type)
    {
        return arr.some(err => {
            return err.type === type;
        });
    }

    filterByType(arr)
    {
        let unique = {};
        arr.map(err => {
            unique[err.type] = err;
        });

        return Object.values(unique);
    }

    render()
    {
        let errors = this.props.playerState.get(`errors`) ?? [];
        errors     = this.filterByType(errors);

        let warnings = this.props.playerState.get(`warnings`) ?? [];

        if (
            this.hasType(errors, err.list.failedLoadPlaylist) ||
            this.hasType(warnings, err.list.failedLoadSubtitles) ||
            this.hasType(warnings, err.list.failedLoadFairplayCert)
        ) {
            errors = errors.filter(error => {
                return !([err.list.videoElementError, err.list.hlsLibraryError, err.list.dashLibraryError].includes(error.type));
            });
        }

        return (
            <div>
                {this.renderErrors(errors)}
                {this.renderWarnings(warnings)}
            </div>
        );
    }
}

export default ErrorWrapper;
