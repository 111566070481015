export const formatTime = (seconds) => {
    if (!seconds) {
        seconds = 0;
    }

    const date = new Date(seconds * 1000);

    const minutesStr  = date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : date.getUTCMinutes();
    const secondsStr = date.getUTCSeconds() < 10 ? `0${date.getUTCSeconds()}` : date.getUTCSeconds();

    if(date.getUTCHours() > 0) {
        const hoursStr  = date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : date.getUTCHours();
        return `${hoursStr}:${minutesStr}:${secondsStr}`;
    }

    return `${minutesStr}:${secondsStr}`;
};