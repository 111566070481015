export default class TextTrack
{
    #id;
    #src;
    #label;

    constructor(id, src, label)
    {
        this.#id    = id;
        this.#src   = src;
        this.#label = label;
    }

    get id()
    {
        return this.#id;
    }

    get src()
    {
        return this.#src;
    }

    get label()
    {
        return this.#label;
    }
}