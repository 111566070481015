class VPAIDAdUnit
{
    constructor(creative, adElement, videoElement, iframe, src, adType, logger)
    {
        this.creative     = creative;
        this.adElement    = adElement;
        this.videoElement = videoElement;
        this.iframe       = iframe;
        this.options      = {
            src:    src,
            adType: adType,
        };
        this.events       = [];
        this.logger       = logger;
    }

    // getters
    getAdSkippableState()
    {
        return this.creative.getAdSkippableState();
    }

    getAdVolume()
    {
        return Math.min(1, this.creative.getAdVolume());
    }

    getAdDuration()
    {
        let duration = this.creative.getAdDuration();
        return duration;
    }

    getAdRemainingTime()
    {
        return this.creative.getAdRemainingTime();
    }

    // methods
    handshake(version)
    {
        return this.creative.handshakeVersion(version);
    }

    initAd(width, height, viewMode, desiredBitrate, adUnitData, envVars)
    {
        adUnitData = adUnitData || {};
        envVars    = Object.assign({
            slot:                 this.adElement,
            videoSlot:            this.videoElement,
            videoSlotCanAutoPlay: false,
        }, envVars);

        this.logger.log(`INIT`, width, height, viewMode);
        width  = parseInt(width);
        height = parseInt(height);
        return new Promise(resolve => {
            this.on(`AdLoaded`, resolve);
            this.creative.initAd(width, height, viewMode, desiredBitrate, adUnitData, envVars);
        });
    }

    resizeAd(width, height, viewMode)
    {
        width  = parseInt(width);
        height = parseInt(height);
        this.logger.log(`RESIZE`, width, height, viewMode);
        this.creative.resizeAd(width, height, viewMode);
    }

    startAd()
    {
        this.creative.startAd();
    }

    pauseAd()
    {
        this.creative.pauseAd();
    }

    resumeAd()
    {
        this.creative.resumeAd();
    }

    skipAd()
    {
        this.creative.skipAd();
    }

    // setters
    setAdVolume(value)
    {
        this.creative.setAdVolume(value);
    }

    on(event, handler)
    {
        this.creative.subscribe(handler, event);
        this.events.push({handler, event});
    }

    off(event, handler)
    {
        this.creative.unsubscribe(handler, event);
        let index = this.events.indexOf({handler, event});
        this.events.splice(index, 1);
    }

    // unbindAll() {
    //   while (this.events.length) {
    //     this.off(this.events[0].event, this.events[0].handler);
    //   }
    // }
}

export default VPAIDAdUnit;