import * as utils from '../utils/vastUtils';
import {parsers} from './parsers';

export default class VastTracker
{
    constructor({src, errorURLMacros, trackingEvents, clickTrackings, duration, extensions}, logger)
    {
        this.trackingInfo = {src, errorURLMacros, trackingEvents, clickTrackings, duration, extensions};
        this.progress     = 0;
        this.quartiles    = {
            firstQuartile: {tracked: false, time: Math.round(25 * duration) / 100},
            midpoint:      {tracked: false, time: Math.round(50 * duration) / 100},
            thirdQuartile: {tracked: false, time: Math.round(75 * duration) / 100},
        };
        this.logger       = logger;
    }

    static trackGeneralVastError(vastError)
    {
        let urls = vastError.data.errorURLMacros;
        if (Array.isArray(urls) && urls.length > 0) {
            utils.track(urls, {
                ASSETURI:        vastError.data.vastUrl,
                CONTENTPLAYHEAD: 0,
                ERRORCODE:       vastError.code,
            });
        }
    }

    static trackImpressions(response)
    {
        let urls = response.impressions;
        if (Array.isArray(urls) && urls.length > 0) {
            utils.track(urls, {
                ASSETURI:        response.vastUrl,
                CONTENTPLAYHEAD: 0,
            });
        }
    }

    static trackCustomEvent(array)
    {
        if (Array.isArray(array) && array.length > 0) {
            utils.track(array, {
                CONTENTPLAYHEAD: 0,
            });
        }
    }

    trackError(errorCode)
    {
        this.logger.log(`[VastTracker] errorCode:`, errorCode);
        this.trackURLs(this.trackingInfo.errorURLMacros, {ERRORCODE: errorCode});
    }

    trackEvent(eventName, trackOnce)
    {
        let uris           = [];
        let trackingEvents = this.trackingInfo.trackingEvents[eventName];
        if (trackingEvents) {
            trackingEvents.forEach((e) => {
                if (e.uri) {
                    uris.push(e.uri);
                }
            });
        }

        this.trackURLs(uris);
        if (trackOnce) {
            delete this.trackingInfo.trackingEvents[eventName];
        }
    }

    trackComplete()
    {
        if (this.quartiles.thirdQuartile.tracked) {
            this.trackEvent(`complete`, true);
        }
    }

    trackClick()
    {
        // eventBus.emit('vast.adClickThrough');
        this.trackURLs(this.trackingInfo.clickTrackings);
    }

    trackIconClick(icon)
    {
        this.trackURLs(icon.clickTrackings);
    }

    trackIconView(icon)
    {
        this.trackURLs([icon.viewTracking]);
    }

    trackURLs(urls, variables = {})
    {
        if (Array.isArray(urls) && urls.length > 0) {
            variables = Object.assign({
                ASSETURI:        this.trackingInfo.src,
                CONTENTPLAYHEAD: utils.formatProgress(this.progress),
            }, variables);
            utils.track(urls, variables);
        }
    }

    trackProgress(newProgressInMs)
    {
        let that           = this;
        let events         = [];
        let ONCE           = true;
        let ALWAYS         = false;
        let trackingEvents = this.trackingInfo.trackingEvents;

        if (typeof newProgressInMs === `number`) {
            addTrackEvent(`start`, ONCE, newProgressInMs > 0);
            addTrackEvent(`rewind`, ALWAYS, hasRewound(this.progress, newProgressInMs));
            addQuartileEvents(newProgressInMs);
            trackProgressEvents(newProgressInMs);
            trackEvents();
            this.progress = newProgressInMs;
        }

        /*** Local function ***/
        function addTrackEvent(eventName, trackOnce, canBeAdded)
        {
            if (trackingEvents[eventName] && canBeAdded) {
                events.push({
                    name:      eventName,
                    trackOnce: !!trackOnce,
                });
            }
        }

        function hasRewound(currentProgress, newProgress)
        {
            let REWIND_THRESHOLD = 3000; //IOS video clock is very unreliable and we need a 3 seconds threshold to ensure that there was a rewind an that it was on purpose.
            return currentProgress > newProgressInMs && Math.abs(newProgress - currentProgress) > REWIND_THRESHOLD;
        }

        function addQuartileEvents(progress)
        {
            let quartiles     = that.quartiles;
            let firstQuartile = that.quartiles.firstQuartile;
            let midpoint      = that.quartiles.midpoint;
            let thirdQuartile = that.quartiles.thirdQuartile;

            if (!firstQuartile.tracked) {
                trackQuartile(`firstQuartile`, progress);
            } else if (!midpoint.tracked) {
                trackQuartile(`midpoint`, progress);
            } else if (!thirdQuartile.tracked) {
                trackQuartile(`thirdQuartile`, progress);
            }

            /*** Local function ***/
            function trackQuartile(quartileName, progress)
            {
                let quartile = quartiles[quartileName];
                if (canBeTracked(quartile, progress)) {
                    quartile.tracked = true;
                    addTrackEvent(quartileName, ONCE, true);
                }
            }
        }

        function canBeTracked(quartile, progress)
        {
            let quartileTime = quartile.time;
            //We only fire the quartile event if the progress is bigger than the quartile time by 5 seconds at most.
            return progress >= quartileTime && progress <= (quartileTime + 5000);
        }

        function trackProgressEvents(progress)
        {
            if (!Array.isArray(trackingEvents.progress)) {
                return; //Nothing to track
            }

            let pendingProgressEvts = [];
            trackingEvents.progress.forEach((e) => {
                // percent with unknown duration
                if (e.offset === null) {
                    e.offset = parsers.offset(e.originalOffset, that.trackingInfo.duration);
                }

                if (e.offset <= progress) {
                    that.trackURLs([e.uri]);
                } else {
                    pendingProgressEvts.push(e);
                }
            });
            trackingEvents.progress = pendingProgressEvts;
        }

        function trackEvents()
        {
            events.forEach(e => that.trackEvent(e.name, e.trackOnce));
        }
    }

    // ONCE track events
    trackStart()
    {
        this.logger.log(`[VastTracker] start`);
        this.trackEvent(`start`, true);
    }

    trackSkip()
    {
        this.logger.log(`[VastTracker] skip`);
        this.trackEvent(`skip`, true);
    }

    trackClose()
    {
        this.logger.log(`[VastTracker] close`);
        this.trackEvent(`close`, true);
    }

    trackCloseLinear()
    {
        this.logger.log(`[VastTracker] closeLinear`);
        this.trackEvent(`closeLinear`, true);
    }

    // END

    trackCreativeView()
    {
        this.logger.log(`[VastTracker] creativeView`);
        this.trackEvent(`creativeView`);
    }

    trackRewind()
    {
        this.logger.log(`[VastTracker] rewind`);
        this.trackEvent(`rewind`);
    }

    trackFullscreen()
    {
        this.logger.log(`[VastTracker] fullscreen`);
        this.trackEvent(`fullscreen`);
    }

    trackExitFullscreen()
    {
        this.logger.log(`[VastTracker] exitFullscreen`);
        this.trackEvent(`exitFullscreen`);
    }

    trackPause()
    {
        this.logger.log(`[VastTracker] pause`);
        this.trackEvent(`pause`);
    }

    trackResume()
    {
        this.logger.log(`[VastTracker] resume`);
        this.trackEvent(`resume`);
    }

    trackMute()
    {
        this.logger.log(`[VastTracker] mute`);
        this.trackEvent(`mute`);
    }

    trackUnmute()
    {
        this.logger.log(`[VastTracker] unmute`);
        this.trackEvent(`unmute`);
    }

    trackAcceptInvitation()
    {
        this.logger.log(`[VastTracker] acceptInvitation`);
        this.trackEvent(`acceptInvitation`);
    }

    trackAcceptInvitationLinear()
    {
        this.logger.log(`[VastTracker] acceptInvitationLinear`);
        this.trackEvent(`acceptInvitationLinear`);
    }

    trackCollapse()
    {
        this.logger.log(`[VastTracker] collapse`);
        this.trackEvent(`collapse`);
    }

    trackExpand()
    {
        this.logger.log(`[VastTracker] expand`);
        this.trackEvent(`expand`);
    }
}