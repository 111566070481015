const localStorageName = `playernow`;

class PlayerStorage
{
    #storage;

    constructor()
    {
        this.#storage = this.#isLocalStorageSupported() ? JSON.parse(localStorage.getItem(localStorageName)) : false;
        if (!this.#storage) {
            this.#storage = {};
            this.set(`timestamp`, Date.now());
        }
    }

    get(key)
    {
        return this.#storage[key];
    }

    set(key, value)
    {
        this.#storage[key]      = value;
        this.#storage.timestamp = Date.now();
        this.#flush();
    }

    #flush()
    {
        if (this.#isLocalStorageSupported()) {
            localStorage.setItem(localStorageName, JSON.stringify(this.#storage));
        }
    }

    #isLocalStorageSupported()
    {
        let test = 'testlocalstorage';
        try {
            localStorage.setItem(test, test);
            localStorage.getItem(test);
            localStorage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    }
}

const playerStorage = new PlayerStorage();

export default playerStorage;
