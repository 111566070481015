import React from 'react';

class TextTrack extends React.Component
{
    constructor(props)
    {
        super(props);

        props.playerState.on('change', () => {
            this.setState({player: props.playerState.props()});
        });

        this.state = {
            player: props.playerState.props(),
        };
    }

    render()
    {
        if (this.state.player.adPlaying === 'linear') {
            return <div/>;
        }

        if (this.state.player.textTracks.length === 0) {
            return <div/>;
        }

        let textTracks = this.state.player.textTracks.filter(track => track.id !== -1);

        return (
            textTracks.map((s) => (
                <track label={s.label} kind="subtitles" srcLang={s.label} src={s.src} key={s.id}/>
            ))
        );
    }
}

export default TextTrack;
