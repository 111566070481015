export default class ViewersWsClient
{
    #url;
    #videoId;
    #logger;
    #playerState;
    #enabled;

    #connections;
    #connectionsCount = 0;
    #isConnected      = false;

    #pingCount = 0;

    #pingInterval      = 2;
    #lastPingTimestamp = 0;
    #lastPongTimestamp = 0;

    #messages            = [];
    #heartBeatIntervalId = null;

    constructor({url, videoId, pingInterval, logger, playerState, enabled})
    {
        this.#url          = url;
        this.#videoId      = videoId;
        this.#pingInterval = pingInterval;
        this.#logger       = logger;
        this.#playerState  = playerState;
        this.#enabled      = enabled && this.#videoId;
        this.#connections  = [];

        if (this.#enabled && !this.#videoId) {
            this.#logger.info('[WsViewers] Module is enabled, but "viewers.videoId" parameter is not defined');
        }

        if (this.#enabled) {
            this.#startHeartBeat();
        }
    }

    #addMessage(message)
    {
        this.#messages.push({body: message, sent: false});

        while (this.#messages.length > 10) {
            this.#messages.shift();
        }
    }

    #startHeartBeat()
    {
        this.#ping();
        this.#heartBeatIntervalId = setInterval(() => {
            this.#ping();
        }, this.#pingInterval * 1000);
    }

    #connect()
    {
        if (this.#connections[this.#connectionsCount]) {
            this.#connections[this.#connectionsCount].close();
            delete this.#connections[this.#connectionsCount];
        }

        this.#connectionsCount++;

        this.#logger.debug('[WsViewers] Connecting...', {connectionId: this.#connectionsCount});

        this.#connections[this.#connectionsCount]           = new WebSocket(this.#url);
        this.#connections[this.#connectionsCount].onopen    = (event) => this.#onOpen(event);
        this.#connections[this.#connectionsCount].onclose   = (event) => this.#onClose(event);
        this.#connections[this.#connectionsCount].onmessage = (event) => this.#onMessage(event);
        this.#connections[this.#connectionsCount].onerror   = (event) => this.#onError(event);
    }

    #ping()
    {
        this.#pingCount += 1;

        if (
            !this.#isConnected &&
            this.#pingCount === 2
        ) {
            this.#logger.error('[WsViewers] There is no connection to the server', {
                reason:       'Client is not connected and pingCount == 2',
                connectionId: this.#connectionsCount,
            });
        }

        if (
            this.#isConnected &&
            this.#pingCount > 1 &&
            Math.abs(this.#lastPingTimestamp - this.#lastPongTimestamp) >= this.#pingInterval
        ) {
            this.#logger.error('[WsViewers] There is no connection to the server',
                {
                    connectionId:      this.#connectionsCount,
                    reason:            '(lastPingTimestamp - lastPongTimestamp) >= pingInterval',
                    lastPingTimestamp: this.#lastPingTimestamp,
                    lastPongTimestamp: this.#lastPongTimestamp,
                    pingInterval:      this.#pingInterval,
                },
            );
            this.#lastPingTimestamp = 0;
            this.#lastPongTimestamp = 0;
            this.#isConnected       = false;
            this.#setLastMessageWasNotSent();
        }

        if (!this.#isConnected) {
            this.#connect();
            return;
        }

        let msg = JSON.stringify({
            'type':          `ping`,
            'correlationId': ViewersWsClient.generateCorrelationId(),
        });

        this.#lastPingTimestamp = ViewersWsClient.getTimestamp();
        this.#connections[this.#connectionsCount].send(msg);

        this.#sendLastMessage();
    }

    startWatching()
    {
        if (!this.#enabled) {
            return;
        }
        this.#addMessage({type: 'watching.start'});
        this.#sendLastMessage();
    }

    stopWatching()
    {
        if (!this.#enabled) {
            return;
        }
        this.#addMessage({type: 'watching.stop'});
        this.#sendLastMessage();
    }

    destroy()
    {
        if (this.#heartBeatIntervalId) {
            clearInterval(this.#heartBeatIntervalId);
        }

        if (!this.#isConnected) {
            return;
        }

        this.#connections[this.#connectionsCount].close();
    }

    #sendLastMessage()
    {
        if (!this.#isConnected) {
            return;
        }

        if (this.#messages.length === 0) {
            return;
        }

        let message = this.#messages[this.#messages.length - 1];
        if (message.sent) {
            return;
        }

        this.#logger.debug(`[WsViewers] Send message...`, {connectionId: this.#connectionsCount, message: message});
        let msg = JSON.stringify(message.body);

        this.#connections[this.#connectionsCount].send(msg);
        message.sent = true;
    }

    #onOpen()
    {
        this.#logger.info('[WsViewers] Connection established', {connectionId: this.#connectionsCount});

        if (!this.#isConnected) {
            if (this.#pingCount !== 1) {
            }

            this.#isConnected = true;
        }

        this.#logger.debug(`[WsViewers] Send watching.init...`, {connectionId: this.#connectionsCount, videoId: this.#videoId});
        let msg = JSON.stringify({
            'type':      `watching.init`,
            'channelId': this.#videoId,
        });

        this.#connections[this.#connectionsCount].send(msg);

        this.#sendLastMessage();
    }

    #onClose(err)
    {
        this.#logger.warn('[WsViewers] Close', {connectionId: this.#connectionsCount, err: err});
        this.#setLastMessageWasNotSent();
    }

    #onError(err)
    {
        this.#logger.error('[WsViewers] Error', {connectionId: this.#connectionsCount, err: err});
        this.#setLastMessageWasNotSent();
    }

    #setLastMessageWasNotSent()
    {
        if (this.#messages.length > 0) {
            let message  = this.#messages[this.#messages.length - 1];
            message.sent = false;
        }
    }

    #onMessage(event)
    {
        const data = JSON.parse(event.data);

        switch (data.type) {
            case `viewersCount`:
                this.#playerState.set(`viewersCount`, data.count);
                break;

            case `pong`:
                this.#lastPongTimestamp = ViewersWsClient.getTimestamp();
                break;

            default:
                this.#logger.debug('[WsViewers] New undefined message', {connectionId: this.#connectionsCount, data: data});
        }
    }

    static generateCorrelationId()
    {
        return Math.random().toString(36).substring(2, 15);
    }

    static getTimestamp()
    {
        return Math.floor((new Date().getTime()) / 1000);
    }
}
