import * as utils from '../utils/vastUtils';
import {parsers} from '../vast/parsers';
import VastTracker from '../vast/VastTracker';
import Playback from '../../../model/playback/playback';
import DRM from '../../../utils/drm';

const supportedMediaTypes = [
    `video/mp4`,
    `video/webm`,
    `video/x-flv`,
];

export default class LinearHandler
{
    static selectAdSource(mediaFiles, player)
    {
        let sources = mediaFiles.filter((mf) => {
            return utils.isVPAID(mf) || (
                mf.delivery === `progressive` && supportedMediaTypes.indexOf(mf.type) !== -1
            );
        });
        let sorted  = sources.sort((a, b) => {
            let deltaA = Math.abs(player.width() - a.width);
            let deltaB = Math.abs(player.width() - b.width);
            return deltaA - deltaB;
        });
        return sorted.length ? sorted[0] : null;
    }

    constructor(eventBus, playerState, playerController, source, logger)
    {
        this.eventBus         = eventBus;
        this.playerState      = playerState;
        this.playerController = playerController;
        this.source           = source;
        this.logger           = logger;
    }

    start(response)
    {
        this.logger.log(`[LinearHandler] start`);
        try {
            this.playerState.set(`currentTime`, 0);

            this.setupExtensions(response.extensions);
            this.tracker = this.createTracker(response);
            this.createController(response);
            this.addClickThrough(response);
            this.addSkipButton(response);
            this.addAdCountdown(response);
            this.setupEvents();
            this.addIcons(response);

            return this.startAd(response);
        } catch (err) {
            this.handleError(err);
        }
    }

    extensionsReducer(arr)
    {
        return arr.reduce((previous, current) => {
            Object.keys(current).forEach(value => {
                if (value !== `type`) {
                    if (typeof current[value] === `string`) {
                        current[value] = current[value].trim();
                    }

                    if (current.type === `startTime` || current.type === `skipTime` || current.type === `skipTime2`) {
                        current[value] = parsers.duration(current[value]);
                    }

                    previous[current.type] = current[value];
                }
            });
            return previous;
        }, {});
    }

    setupExtensions(extensions)
    {
        this.extensions = this.extensionsReducer(extensions);
        if (this.extensions.CustomTracking) {
            this.setupCustomTracking();
        }
        this.playerState.set(`adExtensions`, this.extensions);
    }

    setupCustomTracking()
    {
        if (Array.isArray(this.extensions.CustomTracking)) {
            let onVastLoadArray = [];
            this.extensions.CustomTracking.forEach(elem => {
                if (elem.event && elem.text && elem.event === `onVastLoad`) {
                    onVastLoadArray.push(elem.text);
                }
            });
            VastTracker.trackCustomEvent(onVastLoadArray);
        }
    }

    createTracker(response)
    {
        let trackingInfo = {
            src:            this.source.src,
            errorURLMacros: response.errorURLMacros,
            trackingEvents: response.linear.trackingEvents,
            clickTrackings: response.linear.clickTrackings,
            duration:       response.linear.duration,
            extensions:     this.extensions,
        };
        return new VastTracker(trackingInfo, this.logger);
    }

    createController(response)
    {
        this.playerController.adv = this.playerController.adv || {};
        this.controllerSnapshot   = this.playerController.adv;
        Object.assign(this.playerController.adv, {
            wrapperClick:           (closeAct) => {
                if (!this.playerState.get(`playing`)) {
                    this.playerController.play();
                    return;
                }
                if (closeAct === `pause` || !closeAct) {
                    this.playerController.pause();
                }
                if (closeAct === `yes`) {
                    this.eventBus.emit(`vast.closeAct`);
                }

                this.tracker.trackClick();
            },
            skip:                   () => {
                this.tracker.trackSkip();
                this.eventBus.emit(`vast.adSkip`);
            },
            close:                  () => {
                this.tracker.trackClose();
                this.eventBus.emit(`vast.adClose`);
            },
            closeLinear:            () => {
                this.tracker.trackCloseLinear();
                this.eventBus.emit(`vast.adCloseLinear`);
            },
            iconClick:              (id) => {
                this.tracker.trackIconClick(response.linear.icons[id]);
            },
            iconLoad:               (id) => {
                this.tracker.trackIconView(response.linear.icons[id]);
            },
            acceptInvitation:       (closeAct) => {
                this.tracker.trackAcceptInvitation();
                if (closeAct === `pause` || !closeAct) {
                    this.playerController.pause();
                }
                if (closeAct === `yes`) {
                    this.eventBus.emit(`vast.closeAct`);
                }
            },
            acceptInvitationLinear: () => {
                this.tracker.trackAcceptInvitationLinear();
            },
        });
    }

    revertController()
    {
        this.playerController.adv = this.controllerSnapshot;
    }

    addClickThrough(response)
    {
        let variables = {
            ASSETURI:        this.source.src,
            CONTENTPLAYHEAD: utils.formatProgress(this.playerState.get(`currentTime`) * 1000),
        };
        let href      = response.linear.clickThrough
            ? utils.parseURLMacro(response.linear.clickThrough, variables)
            : `#`;
        this.playerState.set(`adWrapperLink`, href);
    }

    addAdCountdown(response)
    {
        if (typeof response.linear.duration !== `number`) {
            return;
        }

        this.adDuration = response.linear.duration / 1000;
        this.eventBus.on(`playback.currentTimeChange`, this.updateAdCountdown, this);
        this.eventBus.onceOf([
            `vast.adSkip`,
            `vast.adClose`,
            `vast.adEnd`,
            `vast.adCancel`,
        ], this.removeAdCountdown, this);
    }

    updateAdCountdown()
    {
        let adCountdown = Math.ceil(this.adDuration - this.playerState.get(`currentTime`));
        if (adCountdown >= 0) {
            this.playerState.set(`adCountdown`, adCountdown);
        }
    }

    removeAdCountdown()
    {
        this.eventBus.off(`playback.currentTimeChange`, this.updateAdCountdown, this);
        this.playerState.set(`adCountdown`, null);
    }

    addSkipButton(response)
    {
        if (typeof response.linear.skipOffset !== `number`) {
            return;
        }
        this.skipOffset = response.linear.skipOffset / 1000;

        this.eventBus.on(`playback.currentTimeChange`, this.updateSkipButton, this);
        this.eventBus.onceOf([
            `vast.adSkip`,
            `vast.adClose`,
            `vast.adEnd`,
            `vast.adCancel`,
        ], this.removeSkipButton, this);
    }

    updateSkipButton()
    {
        let remaining = Math.ceil(this.skipOffset - this.playerState.get(`currentTime`));
        if (remaining > 0) {
            this.playerState.set(`adSkipRemaining`, remaining);
        } else {
            this.playerState.set(`adSkipActive`, true);
            this.playerState.set(`adSkipRemaining`, null);
        }
    }

    removeSkipButton()
    {
        this.eventBus.off(`playback.currentTimeChange`, this.updateSkipButton, this);
        this.playerState.set(`adSkipActive`, null);
        this.playerState.set(`adSkipRemaining`, null);
    }

    setupEvents()
    {
        let eventBus        = this.eventBus;
        let playerState     = this.playerState;
        let tracker         = this.tracker;
        let previouslyMuted = playerState.get(`isMuted`);

        this.eventBus.on(`vast.adStart`, trackImpression);
        this.eventBus.on(`player.fullscreenchange`, trackFullscreenChange);
        this.eventBus.on(`playback.play`, trackStart);
        this.eventBus.on(`playback.pause`, trackPause);
        this.eventBus.on(`playback.currentTimeChange`, trackProgress);
        this.eventBus.on(`playback.volumechange`, trackVolumeChange);

        this.eventBus.onceOf([`vast.adEnd`, `vast.adCancel`], (e) => {
            if (e.event === `vast.adEnd`) {
                tracker.trackComplete();
            }
            unbindEvents();
        });

        function trackImpression()
        {
            tracker.trackCreativeView();
        }

        function trackFullscreenChange(e)
        {
            if (e.fullscreen) {
                tracker.trackFullscreen();
            } else {
                tracker.trackExitFullscreen();
            }
        }

        function trackStart()
        {
            tracker.trackStart();
        }

        function trackPause()
        {
            //NOTE: whenever a video ends the video Element triggers a 'pause' event before the 'ended' event.
            //      We should not track this pause event because it makes the VAST tracking confusing again we use a
            //      Threshold of 2 seconds to prevent false positives on IOS.
            if (Math.abs(playerState.get(`duration`) - playerState.get(`currentTime`)) < 2) {
                return;
            }

            tracker.trackPause();
            eventBus.once(`playback.play`, () => {
                tracker.trackResume();
            });
        }

        function trackProgress()
        {
            let currentTimeInMs = playerState.get(`currentTime`) * 1000;
            tracker.trackProgress(currentTimeInMs);
        }

        function trackVolumeChange()
        {
            let muted = playerState.get(`isMuted`);
            if (muted !== previouslyMuted) {
                if (muted) {
                    tracker.trackMute();
                } else {
                    tracker.trackUnmute();
                }
                previouslyMuted = muted;
            }
        }

        function unbindEvents()
        {
            eventBus.off(`player.fullscreenchange`, trackFullscreenChange);
            eventBus.off(`vast.adStart`, trackImpression);
            eventBus.off(`playback.play`, trackStart);
            eventBus.off(`playback.pause`, trackPause);
            eventBus.off(`playback.currentTimeChange`, trackProgress);
            eventBus.off(`playback.volumechange`, trackVolumeChange);
        }
    }

    addIcons(response)
    {
        this.icons = response.linear.icons;
        if (Object.keys(this.icons).length > 0) {
            this.playerState.set(`adIcons`, this.icons);
        }

        this.eventBus.on(`playback.currentTimeChange`, this.updateIcons, this);
        this.eventBus.onceOf([`vast.adSkip`, `vast.adEnd`, `vast.adCancel`], this.removeIcons, this);

    }

    updateIcons()
    {
        let currentTime = this.playerState.get(`currentTime`) * 1000;
        let hasChanged  = false;
        Object.keys(this.icons).forEach((id) => {
            let icon          = this.icons[id];
            let prevShowValue = icon.show;

            icon.show = !icon.offset || icon.offset < currentTime;
            if (icon.duration && icon.duration < (currentTime - (icon.offset || 0))) {
                icon.show = false;
            }

            // render optimize
            if (icon.show !== prevShowValue) {
                hasChanged = true;
            }
        });

        if (hasChanged) {
            this.playerState.set(`adIcons`, this.icons);
        }
    }

    removeIcons()
    {
        this.eventBus.off(`playback.currentTimeChange`, this.updateIcons, this);
        this.playerState.set(`adIcons`, null);
    }

    startAd(response)
    {
        this.logger.log(`[LinearHandler] startAd`);

        return new Promise((resolve) => {
            this.playerController.playback.destroy()
                .then(() => {
                    this.playerState.mset({currentTime: 0, adDuration: response.linear.duration / 1000});

                    this.playerController.playback = new Playback(this.playerController.video, {
                        playback: {mp4: {url: this.source.src}},
                        isAdv:    true,
                    }, this.eventBus, this.playerState, this.logger, new DRM());

                    this.playerController.playback.seek(0);
                    this.playerController.playback.play();
                    this.playerController.playback.setVolume(0.4);

                    this.eventBus.onceOf([
                        `vast.adSkip`,
                        `vast.adClose`,
                        `vast.closeAct`,
                        `playback.ended`,
                        `playback.error`,
                        `vast.adsCancel`,
                    ], (e) => {
                        if (e.event === `playback.error`) {
                            this.tracker.trackError(400);
                        }

                        if (e.event === `playback.ended` && (this.playerState.get(`duration`) - this.playerState.get(`currentTime`)) > 3) {
                            // Ignore ended event if the Ad time was not 'near' the end
                            // avoids issues where IOS controls could skip the Ad
                            return;
                        }

                        this.eventBus.emit(`vast.adEnd`);
                        this.playerState.set(`adDuration`, null);
                        this.playerState.set(`adExtensions`, null);
                        this.playerState.set(`adWrapperLink`, null);
                        this.playerState.set(`adSkipActive`, null);
                        this.playerState.set(`adSkipRemaining`, null);
                        this.playerState.set(`adIcons`, null);
                        this.revertController();
                        this.playerController.playback.destroy()
                            .then(() => {
                                this.logger.log(`[LinearHandler] End Ad.`);

                                let res   = {};
                                res.event = e.event;
                                if (e.event === `vast.closeAct`) {
                                    res.pausePlayback = true;
                                }
                                resolve(res);
                            });
                    });
                });

            this.eventBus.emit(`vast.adStart`);
        });
    }

    handleError(err)
    {
        if (this.tracker) {
            this.tracker.trackGeneralVastError(400);
        }
        this.logger.error(err);
    }
}
