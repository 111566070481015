import {isChrome} from './client';

export default class DRM
{
    #isWidevineSupported  = false;
    #isPlayReadySupported = false;
    #isFairPlaySupported  = false;

    needToInit(options)
    {
        return (options.playback.dash && options.playback.dash.drm) ||
            (options.playback.hls && options.playback.hls.drm);
    }

    async init()
    {
        this.#isWidevineSupported  = await this.supportsEncryptedMediaExtension('com.widevine.alpha');
        this.#isPlayReadySupported = await this.supportsEncryptedMediaExtension('com.microsoft.playready');
        this.#isFairPlaySupported  = await this.supportsEncryptedMediaExtension('com.apple.fps.1_0');
    }

    isWidevineSupported()
    {
        return this.#isWidevineSupported;
    };

    isPlayReadySupported()
    {
        return this.#isPlayReadySupported;
    };

    isFairPlaySupported()
    {
        return this.#isFairPlaySupported;
    };

    async #isDRMSupportedByRMKSA(system)
    {
        if (!navigator) {
            return Promise.resolve(false);
        }

        if (typeof navigator.requestMediaKeySystemAccess !== 'function') {
            return Promise.resolve(false);
        }

        try {

            let config = [
                {
                    'initDataTypes':     ['cenc'],
                    'audioCapabilities': [
                        {
                            'contentType': 'audio/mp4;codecs="mp4a.40.2"',
                            'robustness':  isChrome() ? 'SW_SECURE_CRYPTO' : '',
                        }],
                    'videoCapabilities': [
                        {
                            'contentType': 'video/mp4;codecs="avc1.4d401f"',
                            'robustness':  isChrome() ? 'SW_SECURE_CRYPTO' : '',
                        }],
                }];

            return await navigator.requestMediaKeySystemAccess(system, config).then(() => {
                return true;
            }).catch(() => {
                return false;
            });

        } catch (e) {
            console.error(e);
            return false;
        }
    };

    #isDRMSupportedByMediaKeys(system)
    {
        if (window.MediaKeys && typeof window.MediaKeys.isTypeSupported === 'function') {
            return window.MediaKeys.isTypeSupported(system);
        }

        if (window.WebKitMediaKeys && typeof window.WebKitMediaKeys.isTypeSupported === 'function') {
            return window.WebKitMediaKeys.isTypeSupported(system);
        }

        if (window.MSMediaKeys && typeof window.MSMediaKeys.isTypeSupported === 'function') {
            return window.MSMediaKeys.isTypeSupported(system);
        }

        return false;
    }

    #isDRMSupportedByWGKR(system)
    {
        let testVideoElement = document.createElement('video');

        if (typeof testVideoElement.webkitGenerateKeyRequest !== 'function') {
            return false;
        }

        if (typeof testVideoElement.canPlayType !== 'function') {
            return false;
        }

        return testVideoElement.canPlayType('video/mp4', system) !== '';
    }

    async supportsEncryptedMediaExtension(system)
    {
        let rmksa = await this.#isDRMSupportedByRMKSA(system);
        let mk    = this.#isDRMSupportedByMediaKeys(system);
        let wgkr  = this.#isDRMSupportedByWGKR(system);

        return Promise.resolve(rmksa || mk || wgkr);
    }
}

