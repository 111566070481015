import Creative from './Creative';
import * as xml from '../utils/xml';

function parseImpressions(impressions)
{
    if (impressions) {
        impressions = Array.isArray(impressions) ? impressions : [impressions];
        return impressions.filter(i => xml.value(i))
            .map(i => xml.value(i));
    }
    return [];
}

function parseExtensions(extensions)
{
    if (extensions && extensions[`extension`]) {
        return Array.isArray(extensions[`extension`]) ? extensions[`extension`] : [extensions[`extension`]];
    }
    return [];
}

function parseSurveys(inlineSurveys)
{
    if (inlineSurveys) {
        let surveys = Array.isArray(inlineSurveys) ? inlineSurveys : [inlineSurveys];
        return surveys.filter(s => xml.value(s))
            .map(s => ({uri: xml.value(s), type: s.type}));
    }
    return [];
}

export default class InLine
{
    constructor(obj)
    {
        //Required Fields
        this.adSystem    = xml.value(obj.adSystem);
        this.adTitle     = xml.value(obj.adTitle);
        this.impressions = parseImpressions(obj.impression);
        this.extensions  = parseExtensions(obj.extensions);
        this.creatives   = Creative.parseCreatives(obj.creatives);

        //Optional Fields
        this.description = xml.value(obj.description);
        this.advertiser  = xml.value(obj.advertiser);
        this.surveys     = parseSurveys(obj.survey);
        this.error       = xml.value(obj.error);
        this.pricing     = xml.value(obj.pricing);
    }
}