import React from 'react';

class Viewers extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {player: props.playerState.props()};
        props.playerState.on(`change`, () => {
            this.setState({player: props.playerState.props()});
        });
    }

    render()
    {
        if (!this.state.player.showViewersCounter) {
            return null;
        }

        return (
            <div className="playernow-action-block playernow-view-wrap">
                <span className="tab-button"></span>
                <span className="view-count">{this.state.player.viewersCount}</span>
            </div>
        );
    }
}

export default Viewers;
