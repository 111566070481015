const playbackEvents = [
    `pause`,
    `play`,
    `ended`,
    `muted`,
    `unmuted`,
    `beginfullscreen`,
    `endfullscreen`,
    `playing`,
    `error`,
    `seeked`,
];

const adEvents = [
    `adStart`,
    `adEnd`,
    `adSkip`,
    `adCancel`,
    `adClickThrough`,
];

class GA
{
    constructor(options = {}, eventBus, logger)
    {
        this.logger = logger;
        if (!options.trackingId || typeof options.trackingId !== `string`) {
            this.logger.error(`[Statistics] GA: trackingId is required.`);
            return;
        }

        this.eventBus = eventBus;

        this.TIMEOUT = 3000;

        const defaultOptions = {
            // trackingId: 'UA-99302694-1',
            trackerName:   `t0`, // default trackerName
            eventCategory: `playernow`,
            eventLabel:    ``,
        };

        this.options = Object.assign(defaultOptions, options);

        this.checkIfAnalyticsLoaded()
            .then(result => {
                this.ga = result;

                this.createTracker()
                    .then(result => {
                        this.tracker = result;
                        this.bindEvents();
                    });
            })
            .catch((error) => {
                this.logger.error('GA error handled', error);
            });
    }

    bindEvents()
    {
        playbackEvents.forEach(event => {
            this.eventBus.on(`playback.${event}`, this.handleTrackEvent, this);
        });

        adEvents.forEach(event => {
            this.eventBus.on(`vast.${event}`, this.handleTrackEvent, this);
        });
    }

    unBindEvents()
    {
        playbackEvents.forEach(event => {
            this.eventBus.off(`playback.${event}`, this.handleTrackEvent, this);
        });

        adEvents.forEach(event => {
            this.eventBus.off(`vast.${event}`, this.handleTrackEvent, this);
        });
    }

    handleTrackEvent(event)
    {
        function _parseEvent(customEvent)
        {
            return customEvent.event.replace(`playback.`, ``).replace(`vast.`, ``);
        }

        let eventType = event.type || _parseEvent(event);
        this.sendEvent(eventType);
    }

    sendEvent(eventType)
    {
        this.ga(`${this.options.trackerName}.send`, `event`, {
            'eventCategory': this.options.eventCategory,
            'eventAction':   eventType,
            'eventLabel':    this.options.eventLabel,
        });
    }

    createTracker()
    {
        return new Promise((resolve, reject) => {
            this.ga(`create`, {
                trackingId: this.options.trackingId,
                name:       this.options.trackerName,
            });

            this.ga(() => {
                let currentTracker = this.ga.getAll().filter(tracker => {
                    return tracker.get(`trackingId`) === this.options.trackingId && tracker.get(`name`) === this.options.trackerName;
                });
                if (currentTracker.length < 1) {
                    reject(`[Statistics] GA: Can not create tracker. Something wrong`);
                    return;
                }

                resolve(currentTracker[0]);
            });
        });
    }

    checkIfAnalyticsLoaded()
    {
        return new Promise((resolve, reject) => {
            let timeStart = Date.now();
            const TIMEOUT = this.TIMEOUT;

            let _isLoaded = (loaded) => {
                if (Date.now() - timeStart > TIMEOUT) {
                    // analytics.js is not found. Trying to load new one
                    if (loaded) {
                        // if analytics already loaded by us but global ga() not found, throw error
                        reject(`Can not load Google Analytics`);
                        return;
                    }
                    this.loadAnalytics();
                    timeStart = Date.now();
                    _isLoaded(true);
                    return;
                }
                if (window.ga && ga.create) {
                    resolve(ga);

                } else {
                    setTimeout(_isLoaded, 500);
                }
            };

            _isLoaded();
        });
    }

    loadAnalytics()
    {
        // Google Analytics.js loading
        (function(i, s, o, g, r, a, m) {
            i[`GoogleAnalyticsObject`] = r;
            i[r] = i[r] || function() {
                (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src   = g;
            m.parentNode.insertBefore(a, m);
        })(window, document, `script`, `https://www.google-analytics.com/analytics.js`, `ga`);
    }

    destroy()
    {
        this.unBindEvents();
    }
}

export default GA;