import React from 'react';

class BigPlayButton extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {player: props.playerState.props()};
        props.playerState.on('change', () => {
            this.setState({player: props.playerState.props()});
        });
    }

    isPlaying()
    {
        return this.state.player.playing || this.state.player.loading || (!this.state.player.playing && this.state.player.adLoading);
    }

    render()
    {
        if (!this.props.controller.options.forcePlayButton && (this.state.player.adPlaying && this.state.player.snapshotIsStream)) {
            return null;
        }

        if (this.isPlaying()) {
            return null;
        }

        if (this.state.player.error) {
            return null;
        }

        let errors = this.props.playerState.get(`errors`) ?? [];
        if (errors.length > 0) {
            return null;
        }

        return (
            <div className="playernow-big-play-button-wrap">
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 18'>
                    <path fill='white'
                          d='M147,130.29a1,1,0,0,1,1,.05l12,8a1,1,0,0,1,.45.83,1,1,0,0,1-.45.83l-12,8a1,1,0,0,1-1,.05,1,1,0,0,1-.52-.88v-16A1,1,0,0,1,147,130.29Z'
                          transform='translate(-146.45 -130.17)'/>
                </svg>
            </div>
        );
    }
}

export default BigPlayButton;
