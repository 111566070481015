import React from 'react';

class FullscreenButton extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {player: props.playerState.props()};
        props.playerState.on(`change`, () => {
            this.setState({player: props.playerState.props()});
        });
    }

    onFocus(event)
    {
        event.preventDefault();
        this.props.playerState.set(`focusElement`, `FullscreenButton`);
    }

    onKeyDown(e)
    {
        if (!e.keyCode) {
            return;
        }

        if (this.props.playerState.get(`focusElement`) !== 'FullscreenButton') {
            return;
        }

        switch (e.keyCode) {
            case 38:
            case 39:
            case 37:
            case 40:
                e.preventDefault();
                break;
            case 13:
                e.preventDefault();
                this.props.onToggle();
                break;
        }
    }

    render()
    {
        return (
            <div className="playernow-action-block playernow-fullscreen-toggle-wrap" onKeyDown={(e) => this.onKeyDown(e)}>
                <span
                    tabIndex="0"
                    className={this.state.player.fullscreen ? `tab-button playernow-fullscreen-exit` : `tab-button playernow-fullscreen-enter`}
                    onFocus={(e) => this.onFocus(e)}
                    onClick={this.props.onToggle}
                />
            </div>
        );
    }
}

export default FullscreenButton;
