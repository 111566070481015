import ReactDOM from 'react-dom';
import React from 'react';
import Player from './components/Player';

export default class View
{
    #root;
    #playerController;
    #playerState;
    #messages;
    #translate;

    constructor(root, playerController, playerState, messages, translate)
    {
        this.#root             = root;
        this.#playerController = playerController;
        this.#playerState      = playerState;
        this.#messages         = messages;
        this.#translate        = translate;
        this.render();
    }

    render()
    {
        ReactDOM.unmountComponentAtNode(document.getElementById(this.#root));
        ReactDOM.render(
            <Player playerState={this.#playerState} messages={this.#messages} controller={this.#playerController} translate={this.#translate}/>
            , document.getElementById(this.#root),
        );
    }
}
