import React from 'react';
import ReactDOM from 'react-dom';
import FullscreenButton from './FullscreenButton';
import VolumeControl from './VolumeControl';
import VideoTrackControl from './VideoTrackControl';
import AudioTrackControl from './AudioTrackControl';
import TextTrackControl from './TextTrackControl';
import TextTrack from './TextTrack';
import TimeLine from './TimeLine';
import PlayButton from './PlayButton';
import BigPlayButton from './BigPlayButton';
import Duration from './Duration';
import AdWrapper from './AdWrapper';
import ContextMenu from './ContextMenu';
import ErrorWrapper from './ErrorWrapper';
import LoginModal from './Auth/LoginModal';
import RegistrationModal from './Auth/RegistrationModal';
import ResetPasswordModal from './Auth/ResetPasswordModal';
import * as url from '../../utils/url';
import Viewers from './Viewers';

export default class Player extends React.Component
{
    constructor(props)
    {
        super(props);

        props.playerState.on('change', () => {
            this.setState({player: props.playerState.props()});
        });

        this.state = {
            player:                 props.playerState.props(),
            hover:                  false,
            contextmenu:            false,
            showLoginModal:         false,
            showRegistrationModal:  false,
            showResetPasswordModal: false,
        };

        this.clickTimeout       = null;
        this.triggerContextmenu = this.triggerContextmenu.bind(this);
    }

    handleAuthRegistrationLinkClick(event)
    {
        console.info('handleAuthRegistrationLinkClick');
        this.setState({
            showLoginModal:         false,
            showRegistrationModal:  true,
            showResetPasswordModal: false,
        });
    }

    handleAuthLoginLinkClick(event)
    {
        console.info('handleAuthLoginLinkClick');
        this.setState({
            showLoginModal:         true,
            showRegistrationModal:  false,
            showResetPasswordModal: false,
        });
    }

    handleAuthResetPasswordLinkClick(event)
    {
        console.info('handleAuthResetPasswordLinkClick');
        this.setState({
            showLoginModal:         false,
            showRegistrationModal:  false,
            showResetPasswordModal: true,
        });
    }

    handleAuthCloseBtnClick(event)
    {
        console.info('handleAuthCloseBtnClick');
        this.setState({
            showLoginModal:         false,
            showRegistrationModal:  false,
            showResetPasswordModal: false,
        });
    }

    handleAuthLogoutBtnClick(event)
    {
        this.props.controller.logout();
    }

    onKeyPress(e)
    {
        if (e.charCode === 32 && e.target === ReactDOM.findDOMNode(this)) {
            e.preventDefault();
            this.playToggle();
        }
    }

    changeCurrentTime(value)
    {
        if (this.state.player.adPlaying || typeof value === 'undefined' || this.state.player.isStream) {
            return;
        }

        const currentTime = this.props.playerState.get('currentTime');
        const duration    = this.props.playerState.get('duration');
        const result      = Math.max(0, Math.min(currentTime + value, duration - 0.5));

        this.props.controller.seek(result);
    }

    changeVolume(value)
    {
        if (typeof value === 'undefined') {
            return;
        }

        const currentVolume = this.props.playerState.get('volume');
        const result        = Math.max(0, Math.min(currentVolume + value, 1));

        result ? this.props.controller.setMute(false) : this.props.controller.setMute(true);
        this.props.controller.setVolume(result);
    }

    onFullscreenToggle()
    {
        if (!this.state.player.fullscreen) {
            ReactDOM.findDOMNode(this).focus();
        }
        this.props.controller.setFullscreen(!this.state.player.fullscreen);
    }

    onMouseEnter()
    {
        this.setState({hover: true});
        this.onMouseMove();
    }

    onMouseMove()
    {
        // todo: throttling?
        this.#initAutoHideTimer();
    }

    onMouseLeave()
    {

    }

    onVideoClick(e)
    {
        if (!e) {
            return;
        }

        e.preventDefault();
        let delay = 250;

        if (!this.clickTimeout) {
            this.clickTimeout = setTimeout(() => {
                this.clickTimeout = null;
                this.onSingleClick();
            }, delay);
        } else {
            this.clickTimeout = clearTimeout(this.clickTimeout);
            this.onDoubleClick();
        }
    }

    onClick(e)
    {
        if (!e) {
            return;
        }

        this.triggerContextmenu(false);
    }

    onSingleClick()
    {
        this.playToggle();
    }

    onDoubleClick()
    {
        this.onFullscreenToggle();
    }

    onContextMenu(e)
    {
        if (e.nativeEvent) {
            e.preventDefault();
            if (e.nativeEvent.which === 3 && e.nativeEvent.type === 'contextmenu') {
                this.contextmenuPoints = {
                    clientX: e.nativeEvent.clientX,
                    clientY: e.nativeEvent.clientY,
                };
                this.triggerContextmenu(true);
                return;
            }
        }

        if (this.state.contextmenu) {
            this.triggerContextmenu(false);
        }
    }

    triggerContextmenu(value)
    {
        this.setState({contextmenu: value});
    }

    playToggle()
    {
        if (!this.state.player.playing) {
            this.props.controller.play();
        } else {
            this.props.controller.pause();
        }
    }

    renderAdWrapper()
    {
        if (this.state.player.adPlaying) {
            return <AdWrapper playerState={this.props.playerState}
                              controller={this.props.controller.adv}
                              messages={this.props.messages}
            />;
        }
        return '';
    }

    renderVideo()
    {
        let textTracks = this.state.player.textTracks.filter(track => track.id !== -1);

        let needsTextTracksCors = textTracks.some(track => {
            return !url.isSameSchemaAndHostAndPort(track.src);
        });

        if (needsTextTracksCors) {
            return <video className="playernow-video-el" tabIndex="-1" height="100%" width="100%" crossOrigin="anonymous" playsInline={true}
                          poster={this.state.player.poster}>
                <TextTrack playerState={this.props.playerState} controller={this.props.controller}/>
            </video>;
        }

        return <video className="playernow-video-el" tabIndex="-1" height="100%" width="100%" playsInline={true}
                      poster={this.state.player.poster}>
            <TextTrack playerState={this.props.playerState} controller={this.props.controller}/>
        </video>;
    }

    renderLoading()
    {
        if (!this.state.player.loading && !this.state.player.adLoading) {
            return null;
        }
        return (
            <div className="playernow-loading-wrap">
                <div className="playernow-loading-spinner"/>
            </div>
        );
    }

    renderAuthIcon()
    {
        let additionClasses = '';
        if (!this.state.hover && this.state.player.playing) {
            additionClasses += ` hidden`;
        }

        if (this.state.player.authEnabled && this.state.player.authAuthenticated) {
            return <div className={`playernow-top-bar ${additionClasses}`}>
                <div className="playernow-auth-btn">
                    <span className="playernow-auth-btn-success"/>
                    <div className="playernow-auth-description">
                        <h3>{this.state.player.authFestivalName}</h3>
                        <div>{this.state.player.authEmail} | <a href="#" onClick={e => this.handleAuthLogoutBtnClick(e)}>выход</a></div>
                    </div>
                </div>
            </div>;
        }
        return '';
    }

    renderAuthBlockWrapper()
    {
        if (this.state.player.authEnabled && !this.state.player.authAuthenticated) {
            return <div className="playernow-auth-warning">
                <div className="playernow-auth-description">
                    <a href="#" className="d-flex flex-row justify-content-center text-center" onClick={e => this.handleAuthLoginLinkClick(e)}>
                        <span className="icon"/>
                        Авторизуйтесь для просмотра
                    </a>
                </div>
            </div>;
        }
        return '';
    }

    #initAutoHideTimer()
    {
        this.setState({hover: true});

        clearTimeout(this.autoHideTimer);
        this.autoHideTimer = setTimeout(() => {
            this.setState({hover: false});
        }, 3000);
    }

    onFocus(event)
    {
        this.#initAutoHideTimer();

        if (event.target !== event.currentTarget) {
            return;
        }

        this.props.playerState.set(`focusElement`, `Player`);
    }

    onBlur(event)
    {
        this.props.playerState.set(`focusElement`, ``);
    }

    onKeyDown(e)
    {
        if (!e.keyCode) {
            return;
        }

        this.#initAutoHideTimer();

        if (e.keyCode === 32) {
            e.preventDefault();
            this.playToggle();
            return;
        }

        if (this.props.playerState.get(`focusElement`) !== 'Player') {
            return;
        }

        switch (e.keyCode) {
            case 37:
                e.preventDefault();
                this.changeCurrentTime(-10);
                break;
            case 38:
                e.preventDefault();
                this.changeVolume(0.1);
                break;
            case 39:
                e.preventDefault();
                this.changeCurrentTime(10);
                break;
            case 40:
                e.preventDefault();
                this.changeVolume(-0.1);
                break;
        }
    }

    render()
    {
        let additionClasses = ``;
        if (this.state.player.fullscreen) {
            additionClasses += ` playernow-fullscreen`;
        }
        if (this.state.player.adPlaying === `linear`) {
            additionClasses += ` playernow-ad-playing`;
        }
        if (!this.state.hover && this.state.player.playing) {
            additionClasses += ` playernow-control-bar-hidden`;
        }
        if (this.state.player.playing) {
            additionClasses += ` playernow-playing`;
        } else {
            additionClasses += ` playernow-paused`;
        }
        if (this.state.player.isStream) {
            additionClasses += ` playernow-live`;
        }

        return (
            <div tabIndex="0" className={`playernow-block${additionClasses}`}
                 onMouseMove={(e) => this.onMouseMove(e)}
                 onMouseEnter={(e) => this.onMouseEnter(e)}
                 onMouseLeave={(e) => this.onMouseLeave(e)}
                 onKeyPress={(e) => this.onKeyPress(e)}
                 onKeyDown={(e) => this.onKeyDown(e)}
                 onContextMenu={(e) => this.onContextMenu(e)}
                 onClick={(e) => this.onClick(e)}
                 onFocus={(e) => this.onFocus(e)}
                 onBlur={(e) => this.onBlur(e)}
                 ref={(playerElement) => this.playerElement = playerElement}>
                <ErrorWrapper playerState={this.props.playerState}
                              messages={this.props.messages}/>
                <ContextMenu points={this.contextmenuPoints} playerElement={this.playerElement}
                             trigger={this.triggerContextmenu}
                             visible={this.state.contextmenu}
                             playerState={this.props.playerState}
                             controller={this.props.controller}/>
                <div className="playernow-video"
                     onClick={(e) => this.onVideoClick(e)}>
                    {this.renderVideo()}
                    <BigPlayButton playerState={this.props.playerState}
                                   controller={this.props.controller}/>
                </div>
                {this.renderAdWrapper()}
                <div className="playernow-control-bar">
                    <TimeLine playerState={this.props.playerState} controller={this.props.controller}
                              changeCurrentTime={(time) => this.changeCurrentTime(time)}/>
                    <div className="playernow-controls">
                        <div className="playernow-left-controls">
                            <PlayButton playerState={this.props.playerState}
                                        controller={this.props.controller}/>
                            <VolumeControl playerState={this.props.playerState}
                                           controller={this.props.controller}
                                           changeVolume={(volume) => this.changeVolume(volume)}
                            />
                            <Duration playerState={this.props.playerState}
                                      controller={this.props.controller}
                                      translate={this.props.translate}/>
                            <Viewers playerState={this.props.playerState}/>
                        </div>
                        <div className="playernow-right-controls">
                            <TextTrackControl playerState={this.props.playerState}
                                              controller={this.props.controller}/>
                            <AudioTrackControl playerState={this.props.playerState}
                                               controller={this.props.controller}/>
                            <VideoTrackControl playerState={this.props.playerState}
                                               controller={this.props.controller}/>
                            <FullscreenButton playerState={this.props.playerState}
                                              controller={this.props.controller}
                                              onToggle={(e) => this.onFullscreenToggle(e)}/>
                        </div>
                    </div>
                </div>

                {this.renderAuthIcon()}
                {this.renderAuthBlockWrapper()}
                {this.renderLoading()}

                <LoginModal
                    playerState={this.props.playerState}
                    controller={this.props.controller}
                    visible={this.state.showLoginModal}
                    onLoginBtnClick={e => this.handleAuthLoginLinkClick(e)}
                    onRegistrationBtnClick={e => this.handleAuthRegistrationLinkClick(e)}
                    onResetPasswordBtnClick={e => this.handleAuthResetPasswordLinkClick(e)}
                    onCloseBtnClick={e => this.handleAuthCloseBtnClick(e)}
                />
                <RegistrationModal
                    playerState={this.props.playerState}
                    controller={this.props.controller}
                    visible={this.state.showRegistrationModal}
                    onLoginBtnClick={e => this.handleAuthLoginLinkClick(e)}
                    onRegistrationBtnClick={e => this.handleAuthRegistrationLinkClick(e)}
                    onResetPasswordBtnClick={e => this.handleAuthResetPasswordLinkClick(e)}
                    onCloseBtnClick={e => this.handleAuthCloseBtnClick(e)}
                />
                <ResetPasswordModal
                    playerState={this.props.playerState}
                    controller={this.props.controller}
                    visible={this.state.showResetPasswordModal}
                    onLoginBtnClick={e => this.handleAuthLoginLinkClick(e)}
                    onRegistrationBtnClick={e => this.handleAuthRegistrationLinkClick(e)}
                    onResetPasswordBtnClick={e => this.handleAuthResetPasswordLinkClick(e)}
                    onCloseBtnClick={e => this.handleAuthCloseBtnClick(e)}
                />
            </div>
        );
    }
}
