import * as xml from '../utils/xml';
import {parsers} from './parsers';

function parseClickTrackings(trackingData)
{
    if (trackingData) {
        trackingData = Array.isArray(trackingData) ? trackingData : [trackingData];
        return trackingData.map(t => xml.value(t));
    }
    return [];
}

export default class Icon
{
    constructor(obj)
    {
        this.creativeType   = xml.attr(obj.staticResource, `creativeType`);
        this.staticResource = xml.value(obj.staticResource);
        this.htmlResource   = xml.value(obj.hTMLResource);
        this.iframeResource = xml.value(obj.iFrameResource);
        this.viewTracking   = xml.value(obj.iconViewTracking);

        this.clickThrough   = xml.value(obj.iconClicks && obj.iconClicks.iconClickThrough);
        this.clickTrackings = parseClickTrackings(obj.iconClicks && obj.iconClicks.iconClickTracking);

        // required attributes
        this.program   = xml.attr(obj, `program`);
        this.height    = parseInt(xml.attr(obj, `height`));
        this.width     = parseInt(xml.attr(obj, `width`));
        this.xPosition = xml.attr(obj, `xPosition`); // 'left','right' or number of pixels
        this.yPosition = xml.attr(obj, `yPosition`); // 'top','bottom' or number of pixels

        // optional attributes
        this.duration = parsers.duration(obj.duration);
        this.offset   = parsers.offset(obj.offset, this.duration);
    }
}