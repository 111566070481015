import * as xml from '../utils/xml';
import TrackingEvent from './TrackingEvent';

function parseTrackingEvents(trackingEvents)
{
    if (trackingEvents) {
        trackingEvents = Array.isArray(trackingEvents) ? trackingEvents : [trackingEvents];
        return trackingEvents.map(t => new TrackingEvent(t));
    }
    return [];
}

export default class Companion
{
    constructor(obj)
    {
        //Required Elements
        this.creativeType   = xml.attr(`creativeType`, obj.staticResource);
        this.staticResource = xml.value(obj.staticResource);
        this.htmlResource   = xml.value(obj.hTMLResource);
        this.iframeResource = xml.value(obj.iFrameResource);
        this.width          = xml.attr(obj, `width`);
        this.height         = xml.attr(obj, `height`);

        //Optional fields
        this.id             = xml.attr(obj, `id`);
        this.assetWidth     = xml.attr(obj, `assetWidth`);
        this.assetHeight    = xml.attr(obj, `assetHeight`);
        this.expandedWidth  = xml.attr(obj, `expandedWidth`);
        this.expandedHeight = xml.attr(obj, `expandedHeight`);
        this.apiFramework   = xml.attr(obj, `apiFramework`);
        this.adSlotId       = xml.attr(obj, `adSlotId`);
        this.required       = xml.attr(obj, `required`);

        this.companionClickThrough  = xml.value(obj.companionClickThrough);
        this.companionClickTracking = xml.value(obj.companionClickTracking);
        this.altText                = xml.value(obj.altText);
        this.trackingEvents         = parseTrackingEvents(obj.trackingEvents && obj.trackingEvents.tracking);

        if (obj.adParameters) {
            this.adParameters = xml.value(obj.adParameters);
            if (xml.attr(obj.adParameters, `xmlEncoded`)) {
                this.adParameters = xml.decode(this.adParameters);
            }
        }
    }
}